.subBmi{
    width: 100%;
    /* height: 680px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.paddingdivs{
    padding: 25px 0;
}
.BMIGraph {
    text-align: center;
    padding: 0 14px;
  }
.BMIGraph h6 {
    color: #cd0000;
    background-color: #fbc5c5;
    padding: 15px 0px;
    width: 99%;
    font-family: 'Poppins';
    border-radius: 10px;
    margin: 20px auto;
  }
.BMIbox{
    width: 90%;
    height: 739px;
    border: 2px dotted gray;
    margin: 0 auto;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.calculatebmi{
    width: 60%;
    height: 610px;

    padding: 15px 15px;
}
.resultdiv{
    width: 38%;
    height: 610px;
    background-color: rgb(50, 221, 16);
}
.genderspan {
    padding: 8px 27px;
    background-color: #0166B3;
    border: 1px solid #0166B3;
    border-radius: 10px;
    margin: 0 10px;
    color: rgb(255, 255, 255);
    font-family: 'Poppins';
  }
.deactivategender{
    padding: 8px 27px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #0166B3;
    border-radius: 10px;
    margin: 0 10px;
    color: #0166B3;
    font-family: 'Poppins';
}
.bmigenderheader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
}

.bmigenderheader {
    display: flex;
    justify-content: strat;
    align-items: center;
    cursor: pointer;
    height: 70px;
    padding: 0 0px;
    width: 90%;
    margin: auto;
  }

.bgmcalcultedivs{
   display: flex;
   justify-content: space-around;
    width: 100%;
    height: 450px;
}
.bmiunderinput{
    width: 50%;
    text-align: center;
}
.bmiunderimg{
    width: 50%;
}
.bmiunderimg img{
    width: 200px;
}
.bmiunderinput input{
    outline: none;
}
.bmiunderinput button{
    padding: 5px 10px;
    background-color: #0166B3;
    color: white;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
}
.resultbmi {
    width: 80%;
    position: relative;
    top: 15px;
    text-align: center;
}
.calculatebutton{
    text-align: center;
    background-color: #0166B3;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
}
.bmidata {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
  }
  .bmidata button {
    width: 100%;
    height: 50px;
    color: white;
    font-family: 'Poppins';
    background-color: #0166B3;
    border-radius: 10px;
    border: none;
  }
@media screen and (max-width: 420px) {
    .bmidata{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
    }
    .resultbmi {
        position: relative;
        top: 15px;
        text-align: center;
    }
    .calculatebutton {
        text-align: center;
        background-color: #0166B3;
        color: #ffffff;
        border: none;
        padding: 15px 30px;
        margin: 10px 0;
        border-radius: 5px;
        font-size: 14pt;
    }
}

  