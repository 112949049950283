.inbulitmargine{
    margin-top: 70px;
}
.claimsSettlementTable {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
  }
  
  .claimsSettlementTable-header {
    background: #f2f2f2;
  }
  .mainnargin-insurance{
    padding-top: 65px;
  }
  .claimsSettlementTable-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
    font-family: 'Poppins';
    font-size: 13pt;
}
  
  .claimsSettlementTable-row {
    background: white; /* Optional: to maintain clarity */
  }
  
  .claimsSettlementTable-cell {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    color: rgb(122 119 119);
    font-size: 13pt;
    font-family: 'Poppins';
}
  
section.what-c-insurance {
    margin-bottom: 100px;
}
section.type-insurance {
    margin-bottom: 10px;
    margin-top: 146px;
}
section.insurance-plan {
    margin-bottom: 107px;
}
.side-image{
    width: 50%;
}
.side-image img{
    width: 80%;
}
.headingline{
    color: #2959b8;
    font-weight: 600;
    font-family: 'Poppins';
}
.theliclass {
    font-size: 13pt;
    font-family: 'Poppins';
    color: rgb(122 119 119);
}
.hero-scroll{
    display: flex;
    justify-content: center;
    align-items: center;
}
.fleximg{
text-align: center;
}
.floating-mouse {
    margin: 15px 0;
    animation: float 3s ease-in-out infinite;
    width: 32px;
}
.fleximg span{
    color: gray;
}


.according{
    background-color: #007df3d8;
}






.liststyle {
    font-size: 13pt;
    font-family: "Poppins";
    color: rgb(122 119 119);
    margin: 10px 0px;
    list-style: none;
}
.liststyl2 {
    font-size: 13pt;
    font-family: "Poppins";
    color: #2959B8;
    font-weight: 500;
    margin: 16px 0px;
    list-style: none;
}
.textformat {
    font-size: 13pt !important;
    font-family: "Poppins" !important;
    color: rgb(122 119 119) !important;
    font-weight: 400 !important;
}

.AnwsarSpan{
    font-family: 'Poppins';
    color: rgb(122 119 119);
    font-size: 13pt;
}


.qustionTag{
    font-size: 13pt;
    color: #2959B8;
    font-family: 'Poppins';
    font-weight: 600;
}


@keyframes float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px); /* Adjust the value as needed */
    }
    100% {
        transform: translateY(0);
    }
}

.boxstepper {
    width: 100%;
    padding: 10px 5px;
    border-radius: 13px;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    margin: 16px 0px;
    /* border-bottom: 2px solid #0055a557; */
}
.numberStepper {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 18%);
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-family: 'Poppins';
    margin: 0px 15px;
    background-color: #F96766;
}

.descriptionContainer {
    font-family: 'Poppins';
    font-weight: 500;
    color: #0166b3;
    font-size: 13pt;
}




.Readmorecopayment {
    height: 336px;
    overflow: hidden;
    /* box-shadow: 10px 4px 5px 0px; */
}



.whatisIns{
    color: gray;
    font-family: 'Poppins';
    font-size: 11pt;
}



.healthbenifits {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row; /* Main axis: horizontal */
    align-items: start; /* Align items to the start of the cross axis */
    justify-content: start; /* Justify content to the start of the main axis */
}

.policy-upper-flexarea{
    display: flex;
}


section.adv-dis-of-insurance {
    padding-top: 66px;
    width: 100vw;
    margin-left: calc(-50vw + 49%);
    overflow: hidden;
    background-color: #2959b80f;
    padding-bottom: -23px;
}

section.home-nine-section.home-section.inner-page-company-logo {
    margin-top: 72px;
}

section.compare-plan-popup {
    width: 100%;
    position: fixed;
    top: auto;
    left: 0%;
    bottom: 0;
    right: 0;
    transform: none;
    background: #fff;
    z-index: 99;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-shadow: 0px 3px 20px #0000001a;
    padding: 0px 20px;
}
.healthdiscription{
    margin-bottom: 20px;
}
.healthdiscription h1 {
    font-size: 22pt;
    color: #2959b8;
    margin-bottom: 15px;
}
.healthdiscription span {
    font-size: 0.9rem;
    font-family: 'Poppins';
    color: #3a3a3a;
    line-height: 28px;
    color: rgb(122 119 119) !important;
    font-family: 'Poppins' !important;
    font-size: 13pt !important;
}
.healthHeading {
    font-family: 'Poppins' !important;
    color: #2959b8 !important;
    font-size: 21pt !important;
    font-weight: 500;
    margin-bottom: 12px;
}

.redbtn {
    padding: 19px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.Readmore {
    height: 152px;
    overflow: hidden;
    /* box-shadow: 10px 4px 5px 0px; */
}
.Readmorecritit {
    height: 118px;
    overflow: hidden;
    /* box-shadow: 10px 4px 5px 0px; */
}
.Readmoretopup {
    height: 167px;
    overflow: hidden;
    /* box-shadow: 10px 4px 5px 0px; */
}
.Readmoreindivisualhealth {
    height: 222px;
    overflow: hidden;
    /* box-shadow: 10px 4px 5px 0px; */
}

.Readmorebtn {
    background-color: white;
    color: #0166b3;
    padding: 6px 11px;
    border-radius: .45rem;
    border: 2px solid #0166b3;
    transition: 0.3s;
}
.flexdivplane{
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerdiv {
    margin: auto;
}
.backbuttontwo {
    background-color: transparent;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 auto;
    padding: 0px 22px;
    border: none;
    font-size: 13pt;
    position: absolute;
    color: #0055a5;
    font-weight: 600;
}




.Readmorebtn:hover {
    background-color: #0166b3;
    color: white;
    padding: 6px 11px;
    border: 2px solid #0166b3;
}
.Readmoreclmst {
    height: 158px;
    overflow: hidden;
    /* box-shadow: 10px 4px 5px 0px; */
}
.paddingbox {
    border: 1px solid #80808036;
    border-radius: 5px;
    padding: 15px;
}
.accordingback{
    background-color: #3091dc1e!important;
    margin: 3px 0px !important;
    border: none !important;
}
.cashless-claim-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
  }
  
  .table-header {
    padding: 8px;
    text-align: left;
    background-color: #2959B8;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
  }
  
  .table-data {
    padding: 10px;
    border: 1px solid #ddd;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
    color: rgb(122, 119, 119);
  }
  
.readmoreindividual{
    height: 284px;
    overflow: hidden;

}
.activetabse {
    border-radius: 5px;
    padding: 10px 10px;
    font-weight: 600;
    font-family: 'Poppins';
    cursor: pointer;
    text-align: center;
    font-size: 13pt;
    background-color: #f967665e;
    color: #0055a5;
    width: 389px;
}
.activetabsecons {
    width: 389px;
    background-color: #ffc10747;
    color: #373333;
    border-radius: 5px;
    padding: 10px 10px;
    font-weight: 600;
    font-family: 'Poppins';
    cursor: pointer;
    text-align: center;
    font-size: 13pt;
}
.deactivetabse {
    /* background-color: #80808017; */
    color: #0055a5;
    border-radius: 5px;
    padding: 10px 10px;
    font-family: 'Poppins';
    cursor: pointer;
    text-align: center;
    font-size: 13pt;
    font-weight: 600;
    width: 389px;
}
.scrollhiddentab {
    overflow: auto;
    margin-bottom: 22px;
}
.flexdivproscornshealth{
    display: flex;
    margin-bottom: 15px;

}
.prosconstabular {
    border: 1px solid #8080805e;
    border-radius: 10px;
    padding: 15px 10px;
}


.separatespan{
    color: #0055a5;
    font-size: 13pt;
    font-family: 'Poppins';
    font-weight: 600;
}
.boxseparte {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    height: 289px;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
}
.healthtagtoptup {
    background-color: #ff00006b;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 13pt;
    color: white;
    font-weight: 600;
}
.healthtagtoptup1 {
    background-color: #ffc1078f;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 13pt;
    color: white;
    font-weight: 600;
}
.healthtagtoptup2 {
    background-color: #20c99780;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 13pt;
    color: white;
    font-weight: 600;
}
.healthtagtoptup3 {
    background-color: #07bc0c63;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 13pt;
    color: white;
    font-weight: 600;
}
.healthtagtoptup4 {
    background-color: #3498dbb3;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 13pt;
    color: white;
    font-weight: 600;
}

.healthtagtoptup5 {
    background-color: #6c757da1;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 13pt;
    color: white;
    font-weight: 600;
}

.bulletpoint {
    color: #0055a5;
    font-weight: 600;
    font-family: 'Poppins';
    margin-right: 6px;
}

.Readless{
    animation: slideDown 0.5s ease-out;
}

.documents-table-container {
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
  }
  
  .table-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .table-section {
    margin-bottom: 30px;
  }
  
  .documents-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Poppins';
  }
  
  .documents-table th, .documents-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-family: 'Poppins';
    color: rgb(122 119 119);
    font-size: 13pt;
}
  .documents-table th {
    background-color: #0055a5;
    color: white;
    font-family: 'Poppins';
}
  
  .documents-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .checklistimage {
    width: 474px;
    margin: auto;
}
@keyframes slideDown {
    0% {
      transform: translateY(-100%); /* Start off-screen at the top */
      opacity: 0; /* Start invisible */
    }
    100% {
      transform: translateY(0); /* End at its normal position */
      opacity: 1; /* End fully visible */
    }
  }

  .criticalboxstepper {
    width: 470px;
    height: 58px;
    background-color: rgb(242, 243, 247);
    display: flex;
    align-items: center;
    border-radius: 28px;
    margin: 10px 0px;
}
.numberboxcritical {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: orange;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin: 0px 10px;
}
.criticaltext{
    font-family: 'Poppins';
    font-size: 13pt;
    color: #0055a5;
}



.Readmorecritical {
    height: 118px;
    overflow: hidden;
    /* box-shadow: 10px 4px 5px 0px; */
}



.taxBenefitTable {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
  }
  
  .taxBenefitTable-header {
    background: #f2f2f2;
  }
  
  .taxBenefitTable-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
    font-size: 13pt;
  }
  
  .taxBenefitTable-row {
    background: white; /* Optional: to maintain clarity */
  }
  
  .taxBenefitTable-cell {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    color: rgb(122, 119, 119);
    font-size: 13pt;
  }
  .coPaymentTable {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
  }
  
  .coPaymentTable-header {
    background: #f2f2f2;
  }
  
  .coPaymentTable-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
  }
  .normaltext {
    font-family: 'Poppins';
    color: rgb(122 119 119);
    font-size: 13pt;
}


  
  .coPaymentTable-row {
    background: white; /* Optional: to maintain clarity */
  }
  
  .coPaymentTable-cell {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-size: 13pt;
    font-family: 'Poppins', sans-serif;
    color: rgb(122, 119, 119);
  }
    
  .familyEligibilityCriteria-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .familyEligibilityCriteria-header {
    background-color: #f2f2f2;
  }
  
  .familyEligibilityCriteria-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
  }
  
  .familyEligibilityCriteria-row {
    background: white; /* Optional: can be adjusted as needed */
  }
  
  .familyEligibilityCriteria-cell {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 13pt;
    color: rgb(122, 119, 119);
    font-family: 'Poppins', sans-serif;
  }
  
  .criticalIllnessVsFamilyHealth-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .criticalIllnessVsFamilyHealth-header {
    background-color: #f2f2f2;
  }
  
  .criticalIllnessVsFamilyHealth-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
  }
  
  .criticalIllnessVsFamilyHealth-row {
    background: white; /* Optional: can be adjusted as needed */
  }
  
  .criticalIllnessVsFamilyHealth-cell {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
    color: rgb(122, 119, 119);
  }

  
  .seniorsCitizenInsu-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
  }
  
  .seniorsCitizenInsu-header {
    background: #f2f2f2;
  }
  
  .seniorsCitizenInsu-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
    font-family: 'Poppins', sans-serif;
  }
  
  .seniorsCitizenInsu-row {
    background: white; /* Optional */
  }
  
  .seniorsCitizenInsu-cell {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
    color: rgb(122, 119, 119);
  }
  .individualTable {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
  }
  
  .individualTable-header {
    background: #f2f2f2;
  }
  
  .individualTable-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
  }
  
  .individualTable-row {
    background: white; /* Optional */
  }
  
  .individualTable-cell {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
    color: rgb(122, 119, 119);
  }
    

  .custom-stepper-container {
    padding: 20px;
    background-color: #ffffff; /* White background for cleanliness */
    border-radius: 8px; /* Slightly rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  }
  
  .custom-stepper-list {
    list-style-type: none;
    padding: 0;
    position: relative; /* For positioning the connector line */
  }
  
  .custom-step {
    display: flex;
    align-items: center;
    position: relative; /* For positioning the connector line */
    margin: 20px 0; /* Space between steps */
    padding-left: 30px; /* Space for the step number */
  }
  
  .custom-step-number {
    background-color: rgb(1, 102, 179); /* Blue color for the step number */
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16pt;
    position: absolute; /* Positioning the number */
    left: 0; /* Align to the left */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust vertical position */
    z-index: 1; /* Ensure it is above the line */
  }
  
  .custom-step-content {
    margin-left: 60px; /* Space between number and content */
    flex-grow: 1; /* Take up remaining space */
  }
  
  .custom-step:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 20px;
    top: 20px;
    height: calc(100% - 20px);
    width: 2px;
    background-color: rgb(1, 102, 179);
    z-index: 0;
    height: 126px;
}
  .custom-step.active .custom-step-number {
    background-color: rgb(40, 167, 69); /* Green for active step */
  }
  .custom-normal-text {
    font-family: 'Poppins', sans-serif;
    color: #0055a5;
    font-size: 12pt;
    line-height: 1.5;
}
  
.infographic-cards {

  }
  .infographic-cards li.color-1 {
    border: 10px solid var(--color-1);
  }
  .infographic-cards li.color-2 {
    border: 10px solid var(--color-2);
  }
  .infographic-cards li.color-3 {
    border: 10px solid var(--color-3);
  }
  .infographic-cards li {
    list-style: none;
    width: 95%;
    height: 210px;
    padding: 25px 33px;
    background: #ffffff;
    background-image: none;
    position: relative;
    box-shadow: 0 4px 16px rgb(52 105 203 / 49%);
    text-align: center;
    display: inline-block;
    margin-bottom: 25px;
    border-radius: 20px;
    align-content: center;
}
.number-box {
    position: relative;
    top: 15px;
    padding: 5px 5px;
    background-color: aliceblue;
    border-radius: 19px;
}
.infographic-cards h5 {
    color: #FCA84C;
    font-size: 15pt;
    padding: 10px 0;
    width: 100%;
    font-weight: 600;
}
.infographic-cards h6{
    color: #0055a5;
}


.advantagheader{
    font-size: 15pt !important;
    color: #0a0b0b !important;
}

  .health-coverage-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  .table-header {
    background-color: rgb(1, 102, 179);
    padding: 10px;
    /* text-align: center; */
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 13pt;
}
  
  .table-cell {
    border: 1px solid #ddd;
    padding: 8px;
    font-family: 'Poppins', sans-serif;
    color: rgb(122, 119, 119);
    font-size: 13pt;
}
  





.specialprice {
    color: #2959b8;
    margin: 0 5px;
    font-weight: 600;
    font-size: 13pt;
}



.theheadingcolor {
    color: #2959B8;
    margin: 10px 0;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 22pt;
}
.fixwidthDivtext {
    margin-bottom: 58px;
}
.custom-table {
    color: rgb(122, 119, 119);
    font-size: 13pt;
  }
  .steponeparents {
    width: 454px;
    height: 80px;
    border-left: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    color: #0055a5;
    font-size: 14pt;
    font-weight: 600;
    border-radius: 0px 35px 1px 24px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.hide{
    display: none;
}
.nonhide{
    display: block;
}
.linktag{
    color: green;
}

.bordercolorbox {
    background-color: #20c997a3;
    color: white;
    border-radius: 10px;
    padding: 12px 12px;
    margin: 10px 0px;
    font-weight: 600;
    width: 100%;
    list-style: none;
    height: 65px;
    text-align: center;
    align-content: center;
}
.bordercolorbox1 {
    background-color: var(--toastify-icon-color-warning);
    color: white;
    border-radius: 10px;
    padding: 12px 12px;
    margin: 10px 0px;
    font-weight: 600;
    width: 100%;
    list-style: none;
    height: 65px;
    text-align: center;
    align-content: center;
}
.bordercolorbox2 {
    background-color: #fca84c;
    color: white;
    border-radius: 10px;
    padding: 12px 12px;
    margin: 10px 0px;
    font-weight: 600;
    width: 100%;
    list-style: none;
    height: 65px;
    text-align: center;
    align-content: center;
}


.bordercolorbox3 {
    background-color: #61adff;
    color: white;
    border-radius: 10px;
    padding: 12px 12px;
    margin: 10px 0px;
    font-weight: 600;
    width: 100%;
    list-style: none;
    height: 65px;
    text-align: center;
    align-content: center;
}
.bordercolorbox4 {
    background-color: #0055a5;
    color: white;
    border-radius: 10px;
    padding: 12px 12px;
    margin: 10px 0px;
    font-weight: 600;
    width: 100%;
    list-style: none;
    height: 65px;
    text-align: center;
    align-content: center;
}

.steptwoparents {
    width: 454px;
    height: 80px;
    border-right: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0055a5;
    font-size: 14pt;
    font-weight: 600;
    border-radius: 10px 1px 10px 0px;
}

.redmoreclass{
    height: 250px;
    overflow: hidden;
}

.prosconsdiv {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid #80808070;
    margin-bottom: 14px;
}

.tabsproscons {
    width: 48%;
    height: 50px;
    border-radius: 5px;
    background-color: #f967665e;
    color: #0055a5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    /* margin-right: 11px; */
    font-size: 13pt;
}

.anclass {
    font-size: 20pt;
    font-family: 'Poppins';
    color: #0055a5;
    font-weight: 600;
}
.tabsprosconsdeactive {
    width: 48%;
    height: 50px;
    border-radius: 5px;
    color: #0055a5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    font-size: 13pt;
}
.haddingdiv{
    display: block;
}
.hidediv{
    display: none;
}
.stepper-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .step-item {
    display: flex;
    align-items: center;
}
.step-number {
    width: 40px;
    height: 40px;
    background-color: #fca84c;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* font-weight: bold; */
    /* margin-right: 10px; */
    font-size: 14pt;
}
  
.step-description {
    flex-grow: 1;
    background-color: rgb(242, 243, 247);
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 12px;
}

.boxstepseniour {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 211px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #ffff0017;
}

.boxstepseniour1 {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 211px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #ed696936;
}
.boxstepseniour2 {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 211px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #007bff1f;
}

.boxstepseniour3 {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 211px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #00800012;
}


.boxstepsenioursecond {
    box-shadow: 0 6px 16px rgb(52 105 203 / 25%);
    width: 100%;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    margin: 10px 0px;
    height: 288px;
}




.cashless-claim-process-table,
.reimbursement-claim-process-table {
    width: 100%;
    border-collapse: collapse;
}


.cashless-step-header, .reimbursement-step-header {
    border: 1px solid #ddd;
    padding: 8px;
    background-color: #0055a5;
    text-align: left;
    font-size: 13pt;
    color: white;
    font-family: 'Poppins';
    text-align: center;
}

.cashless-step, .reimbursement-step {
    border: 1px solid #a9a7a7;
    padding: 8px;
    text-align: left;
    font-size: 13pt;
    color: rgb(122 119 119);
    font-family: 'Poppins';
    padding: 12px 5px;
}






.issue-header, .tick-header {
    background-color: #f2f2f2;
    text-align: center;
    padding: 10px 15px;
    border: 1px solid #fff;
    background-color: #0166b3;
    color: white;
    font-family: 'Poppins';
}
.issue-item {
    padding: 11px 21px;
    border: 1px solid rgb(149, 156, 166);
    text-align: unset;
    color: #0166b3;
    font-weight: 600;
    font-family: 'Poppins';
}
.tabildivs{
    position: relative;
}

.tick-item {
    padding: 8px;
    border: 1px solid rgb(149, 156, 166);
}
.hero-stepper{
    position: relative;
}
.stepperimage {
    width: 100%;
}
.stepperone {
    position: absolute;
    color: #1E4982;
    font-weight: 700;
    top: 386px;
    left: 319px;
}
.steppertwo{
    position: absolute;
    left: 10px;
}

.steppertwo {
    position: absolute;
    left: 232px;
    bottom: 167px;
    color: #E45A3D;
    font-weight: 600;
    width: 235px;
}
.graybox {
    border: 1px solid #8080808a;
    border-radius: 7px;
    padding: 17px;
}



.stepperthree{
    position: absolute;
    color: #EBB349;
    font-weight: 700;
    right: 276px;
    top: 261px;
    width: 187px;
}
.stepperfour {
    position: absolute;
    color: #8EBA44;
    font-weight: 700;
    bottom: 281px;
    left: 471px;
}
.Treestepperdiv{
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
}
.Treestepperdiv img {
    width: 929px;
}
.onetree {
    position: absolute;
    left: 618px;
    top: 353px;
    font-weight: 600;
    color: #668E35;
    font-size: 17pt;
}
.twotree {
    position: absolute;
    left: 44px;
    top: 308px;
    font-weight: 600;
    color: #668E35;
    font-size: 17pt;
}
.threetree {
    position: absolute;
    left: 608px;
    top: 227px;
    font-weight: 600;
    color: #668E35;
    font-size: 17pt;
}
.fourtree {
    position: absolute;
    left: 67px;
    top: 188px;
    font-weight: 600;
    color: #668E35;
    font-size: 17pt;
}
.fivetree {
    position: absolute;
    left: 199px;
    top: 87px;
    font-weight: 600;
    color: #668E35;
    font-size: 17pt;
}
.sixtree {
    position: absolute;
    left: 560px;
    top: 122px;
    font-weight: 600;
    color: #668E35;
    font-size: 17pt;
    width: 347px;
}



.flexfeture{
    display: flex;
}










    .tabilcss th{
       font-size: 12pt;
       background-color: #2959b8;
       color: white;
       border: 1px solid white;
    }
    .tabilcss td{
        font-size: 12pt;
     } 

     .reminderbutton {
        color: #2959b8;
        border: 2px solid #2959b8;
        background-color: white;
        border-radius: .40rem;
        padding: 9px 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Poppins';
        font-weight: 600;
    }

    .comany-logo-in-all-page p {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        line-height: 30px;
        color: #68768f;
        width: 100%;
        margin: 0 auto;
    }
    .small{
        height: 80px;
        overflow: hidden;
        margin-bottom: 25px;
    }
    .big{
        height: 110px;
        overflow: hidden;
        margin-bottom: 25px;
    }

    .halfform{
        height: 60px;
        overflow: hidden;
        margin-bottom: 25px;
        background-color: red;
    }
    .fullform{
        height: 120px;
        overflow: hidden;
        margin-bottom: 25px;
        background-color: red;
    }

    .underoverfollowdiv{
        height: 198px;
        width: 100%;
        overflow: hidden;
    }
    .scrollerindividualhealth {
        width: 1135px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        padding-bottom: 15px;
    }

    .listcol {
        color: #0055a5;
        font-weight: 500;
        font-family: 'Poppins';
        list-style: none;
        font-size: 13pt;
      }
  

@media screen and (max-width: 1024px){

    .healthtagtoptup {
        background-color: #ff00006b;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 10pt;
        color: white;
        font-weight: 600;
    }
    .healthtagtoptup1 {
        background-color: #ffc1078f;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }
    .healthtagtoptup2 {
        background-color: #20c99780;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }

.healthtagtoptup3 {
    background-color: #07bc0c63;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 11pt;
    color: white;
    font-weight: 600;
}
.healthtagtoptup4 {
    background-color: #3498dbb3;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 11pt;
    color: white;
    font-weight: 600;
}
.healthtagtoptup5 {
    background-color: #6c757da1;
    border-radius: 27px;
    margin: 10px 0px;
    height: 72px;
    align-content: center;
    padding: 0px 10px;
    font-size: 11pt;
    color: white;
    font-weight: 600;
}
.boxstepseniour {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 272px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #ffff0017;
}
.boxstepseniour1 {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 272px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #ed696936;
}
.boxstepseniour2 {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 272px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0px 25px;
    background-color: #007bff1f;
}
.boxstepseniour3 {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 272px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #00800012;
}
.boxstepsenioursecond {
    box-shadow: 0 6px 16px rgb(52 105 203 / 25%);
    width: 100%;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    margin: 10px 0px;
    height: 428px;
}
.boxseparte {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    height: 460px;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
}
.cardone {
    height: 276px;
    background-color: #0055a5;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0px 0px;
}
.cardtwo {
    height: 276px;
    background-color: #F96766;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0px 0px;
}



    .backdivse {
        height: 49px;
    }
    .healthbenifits {
        width: 100%;
        padding: 0px 20px;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;
    }

.steponeparents {
    width: 100%;
    height: 80px;
    border-left: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    color: #0055a5;
    font-size: 14pt;
    font-weight: 600;
    border-radius: 0px 35px 1px 24px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.steptwoparents {
    width: 100%;
    height: 80px;
    border-right: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0055a5;
    font-size: 14pt;
    font-weight: 600;
    border-radius: 10px 1px 10px 0px;
}
.steponeparents {
    width: 100%;
    height: 80px;
    border-left: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    color: #0055a5;
    font-size: 10pt;
    font-weight: 600;
    border-radius: 0px 35px 1px 24px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.steptwoparents {
    width: 100%;
    height: 80px;
    border-right: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0055a5;
    font-size: 10pt;
    font-weight: 600;
    border-radius: 10px 1px 10px 0px;
}
}
@media only screen and (max-width: 991px){
    section.car-insurance {
        padding-top: 99px;
    }
    .flexfeture{
        display: block;
    }
    

    .mainnargin-insurance {
        padding-top: 112px !important;
    }
    .type-i-row-tow {
        align-items: center;
        justify-content: center;
    }
}


@media screen and (max-width: 875px) {
    .policy-upper-flexarea{
        display: block;
        width: 100%;
    }
    .side-image {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .side-image img{
        width: 80%;
    }
    .healthbenifits {
        width: 95%;
        margin: auto;

    }
}

@media only screen and (max-width: 768px){
    .col-sm-12.col-md-4.col-lg-4.side-image {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form-main form#insurance_form_get_plan {
        margin: 10px auto;
}
.normaltext {
    font-family: 'Poppins';
    color: rgb(122 119 119);
    font-size: 13pt;
}
.criticalboxstepper {
    width: 100%;
    height: 58px;
    background-color: rgb(242, 243, 247);
    display: flex;
    align-items: center;
    border-radius: 28px;
    margin: 10px 0px;
}
}

@media screen  and (max-width: 700px){
    .tabilcss{
        overflow: auto;
    }
    .tabilcss th{
       font-size: 7pt;
    }
    .tabilcss td{
        font-size: 7pt;
     }
}



@media screen and (max-width: 600px){
    .seniorsCitizenInsu-cell {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        font-size: 11pt;
        color: rgb(122, 119, 119);
    }
    
    .healthtagtoptup {
        background-color: #ff00006b;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }
    .healthtagtoptup1 {
        background-color: #ffc1078f;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }
    .healthtagtoptup2 {
        background-color: #20c99780;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }
    .healthtagtoptup3 {
        background-color: #07bc0c63;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }
    .healthtagtoptup4 {
        background-color: #3498dbb3;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }
    .healthtagtoptup5 {
        background-color: #6c757da1;
        border-radius: 27px;
        margin: 10px 0px;
        height: 72px;
        align-content: center;
        padding: 0px 10px;
        font-size: 11pt;
        color: white;
        font-weight: 600;
    }


.specialprice {
    color: #2959b8;
    margin: 0 5px;
    font-weight: 600;
    font-size: 11pt;
}
    .familyEligibilityCriteria-cell {
        padding: 15px;
        border: 1px solid #ddd;
        text-align: left;
        font-size: 11pt;
        color: rgb(122, 119, 119);
        font-family: 'Poppins', sans-serif;
    }
    .familyEligibilityCriteria-headerCell {
        border: 1px solid white;
        padding: 8px;
        text-align: left;
        background-color: rgb(41, 89, 184);
        color: white;
        font-size: 10pt;
    }
    .cashless-step, .reimbursement-step {
        border: 1px solid #a9a7a7;
        padding: 8px;
        text-align: left;
        font-size: 11pt;
        color: rgb(122 119 119);
        font-family: 'Poppins';
        padding: 12px 5px;
    }
    .cashless-step-header, .reimbursement-step-header {
        border: 1px solid #ddd;
        padding: 8px;
        background-color: #0055a5;
        text-align: left;
        font-size: 11pt;
        color: white;
        font-family: 'Poppins';
        text-align: center;
    }
    .cashless-claim-process-table, .reimbursement-claim-process-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 10px;
    }
    .criticalIllnessVsFamilyHealth-cell {
        padding: 15px;
        border: 1px solid #ddd;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        font-size: 11pt;
        color: rgb(122, 119, 119);
    }

.criticalIllnessVsFamilyHealth-headerCell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 11pt;
}
    .bordercolorbox {
        background-color: #20c997a3;
        color: white;
        border-radius: 10px;
        padding: 12px 12px;
        margin: 10px 0px;
        font-weight: 600;
        width: 100%;
        list-style: none;
    }
    .bordercolorbox1 {
        background-color: var(--toastify-icon-color-warning);
        color: white;
        border-radius: 10px;
        padding: 12px 12px;
        margin: 10px 0px;
        font-weight: 600;
        width: 100%;
        list-style: none;
    }
    .bordercolorbox3 {
        background-color: #61adff;
        color: white;
        border-radius: 10px;
        padding: 12px 12px;
        margin: 10px 0px;
        font-weight: 600;
        width: 100%;
        list-style: none;
    }
    .bordercolorbox4 {
        background-color: #0055a5;
        color: white;
        border-radius: 10px;
        padding: 12px 12px;
        margin: 10px 0px;
        font-weight: 600;
        width: 100%;
        list-style: none;
    }
    .bordercolorbox2 {
        background-color: #fca84c;
        color: white;
        border-radius: 10px;
        padding: 12px 12px;
        margin: 10px 0px;
        font-weight: 600;
        width: 100%;
        list-style: none;
    }
    .claimsSettlementTable-headerCell {
        border: 1px solid white;
        padding: 8px;
        text-align: left;
        background-color: rgb(41, 89, 184);
        color: white;
        font-family: 'Poppins';
        font-size: 11pt;
    }
    .claimsSettlementTable-cell {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
        color: rgb(122 119 119);
        font-size: 11pt;
        font-family: 'Poppins';
    }
    .liststyle {
        font-size: 11pt;
        font-family: "Poppins";
        color: rgb(122 119 119);
        margin: 10px 0px;
        list-style: none;
    }
    .custom-table {
        color: rgb(122, 119, 119);
        font-size: 11pt;
      }
    .infographic-cards li {
        list-style: none;
        width: 100%;
        height: 210px;
        padding: 25px 22px;
        background: #ffffff;
        background-image: none;
        position: relative;
        box-shadow: 0 4px 16px rgb(52 105 203 / 49%);
        text-align: center;
        display: inline-block;
        margin-bottom: 25px;
        border-radius: 20px;
        align-content: center;
    }
    .commonone {
        margin: 12px 0px;
    }
    .listcol {
        color: #0055a5;
        font-weight: 500;
        font-family: 'Poppins';
        list-style: none;
        font-size: 11pt;
    }
    .taxBenefitTable-cell {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
        color: rgb(122, 119, 119);
        font-size: 11pt;
    }
    .taxBenefitTable-headerCell {
        border: 1px solid white;
        padding: 8px;
        text-align: left;
        background-color: rgb(41, 89, 184);
        color: white;
        font-size: 11pt;
    }
    .documents-table th, .documents-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        font-family: 'Poppins';
        font-size: 11pt;
    }
    .individualTable-cell {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        font-size: 11pt;
        color: rgb(122, 119, 119);
    }
    .individualTable-headerCell {
        border: 1px solid white;
        padding: 8px;
        text-align: left;
        background-color: rgb(41, 89, 184);
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 11pt;
    }
    .boxstepsenioursecond {
        box-shadow: 0 6px 16px rgb(52 105 203 / 25%);
        width: 100%;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 25px;
        margin: 10px 0px;
        height: 327px;
    }
    .boxseparte {
        box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
        height: 346px;
        padding: 10px 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
    }

    .backdivse {
        height: 49px;
    }

     
.health-coverage-table {
    width: 898px;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

.numberStepper {
    width: 44px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 18%);
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-family: 'Poppins';
    margin: 0px 8px;
    background-color: #F96766;
}
.checklistimage {
    width: 100%;
    margin: auto;
}

.fmailimagegifs {
    width: 100%;
    margin: auto;
    padding-top: 32px;
}





.tableoverflow{
    width: 100%;
    overflow: scroll;
}

.scrollerindividualhealth {
    width: 830px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-bottom: 15px;
}


.activetabse {

    border-radius: 10px;
    padding: 13px 14px;
    font-family: 'Poppins';
    cursor: pointer;
    text-align: center;
    font-size: 10pt;
    width: 274px;
    margin: 0px 5px;
}


.deactivetabse {
    background-color: #80808017;
    color: #0055a5;
    border-radius: 10px;
    padding: 13px 14px;
    font-family: 'Poppins';
    cursor: pointer;
    text-align: center;
    font-size: 10pt;
    width: 274px;
    margin: 0px 5px;
}


.criticalIllnessVsFamilyHealth-table {
    border-collapse: collapse;
    width: 1172px;
}

.boxstepseniour2 {
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    width: 100%;
    height: 211px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    background-color: #007bff1f;
    margin: 13px 0px;
}


.taxBenefitTable {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
    width: 780px;
}
.theheadingcolor {
    color: #2959B8;
    margin: 10px 0;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 13pt;
}

section.type-insurance {
    margin-bottom: 10px;
    margin-top: 3px;
}


.documents-table {
    width: 143%;
    border-collapse: collapse;
    font-family: 'Poppins';
}

.fmailimagegifsstepper {
    width: 100%;
    margin: 58px auto;
}





    .healthHeading {
        font-family: 'Poppins' !important;
        color: #2959b8 !important;
        font-size: 16pt !important;
        font-weight: 500;
        margin-bottom: 12px;
    }  
    .textformat {
        font-size: 11pt !important;
        font-family: "Poppins" !important;
        color: rgb(122 119 119) !important;
        font-weight: 400 !important;
    }
    .liststyl2 {
        font-size: 12pt;
        font-family: "Poppins";
        color: #2959B8;
        font-weight: 500;
        margin: 16px 0px;
    }
   
   
    .normaltext {
        font-family: 'Poppins';
        color: rgb(122 119 119);
        font-size: 11pt;
    }
    .healthdiscription span {
        font-size: 0.9rem;
        font-family: 'Poppins';
        color: #3a3a3a;
        line-height: 18px;
        color: rgb(122 119 119) !important;
        font-family: 'Poppins' !important;
        font-size: 11pt !important;
    }
    form#insurance_form_get_plan .form-input h6 {
        font-size: 14px;
        line-height: 24px;
    }
    .table-cell {
        border: 1px solid #ddd;
        padding: 8px;
        font-family: 'Poppins', sans-serif;
        color: rgb(122, 119, 119);
        font-size: 11pt;
    }
    .table-header {
        background-color: rgb(1, 102, 179);
        padding: 10px;
        text-align: center;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 11pt;
    }

.AnwsarSpan {
    font-family: 'Poppins';
    color: rgb(122 119 119);
    font-size: 11pt;
}
.descriptionContainer {
    font-family: 'Poppins';
    font-weight: 500;
    color: #0166b3;
    font-size: 11pt;
}
.fledxnumbercontainer{
    width: 20%;
}
.steponeparents {
    width: 100%;
    height: 89px;
    border-left: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    color: #0055a5;
    font-size: 10pt;
    font-weight: 600;
    border-radius: 0px 35px 1px 24px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.steptwoparents {
    width: 100%;
    height: 89px;
    border-right: 3px dashed #fca84c;
    border-bottom: 3px dashed #fca84c;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0055a5;
    font-size: 10pt;
    font-weight: 600;
    border-radius: 10px 1px 10px 0px;
}

}


@media only screen and (max-width: 480px){

    .insurance-btn-set {
        flex-wrap: wrap;
    }
    section.what-c-insurance {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .type-i-row-tow {
        padding-top: 30px;
    }
    .reia-process-row-two .col-sm-12 {
        flex: 50%;
        max-width: 50%;
    }
}

  