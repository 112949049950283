
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Poppins", sans-serif !important;

   
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #f0f0f0;
  border-top: none;
  position: absolute;
  background-color:#f0f0f0 ;
  max-height: 130px;
  overflow-y: scroll;
  z-index: 10;
  width: 96%;
}

.custom-dropdown li {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
}

.d-none {
  display: none;
}
