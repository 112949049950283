.request-otp-header{
    width: 90%;
    margin: 0 auto;
}
.otpinputfild{
    border: 1px solid gray;
}
.user-number{
    color: #FD9202;
    font-size: 1.1rem;
    position: relative;
    /* top: 5px; */
}
.otpbox {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin: 0 5px;
    text-align: center;
}
.otpdivboxs {
    margin: 24px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}