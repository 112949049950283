.insurance-claim-process {
    width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .process-title {
    text-align: center;
    margin-bottom: 21px;
    font-size: 26px;
    color: #4A90E2;
    font-weight: 600;
  }
  
  .process-title-hr{
    
  }


  .steps {
    display: flex;
    flex-direction: column;
  }
  
  .step {
    display: flex;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    background-color: #fff;
    overflow: hidden; /* Ensures no overflow issues when stepping up */
  }
  
  .step:hover {
    border-color: #4A90E2;
    background-color: ivory; /* Light blue */
    transform: translateY(-8px); /* Move the step up on hover */
  }
  
  .step-number {
    font-size: 44px;
    font-weight: bold;
    margin-right: 20px;
    color: #4A90E2;
    background-color: antiquewhite;
    padding: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .step-content {
    flex: 1;
    padding: 15px;
    padding-left: 0px;
  }
  
  .step-title {
    font-size: 17px;
    margin-bottom: 10px;
    color: #0055a5;
  }
  
  .step-description {
    font-size: 10pt;
    color: #666;
  }
  
  .step-title-hr{
    margin-top: 0px;
    margin-bottom: 10px;
    width: 50%;
    margin-left: 0px;
    border: 1px solid #f78809;
  }