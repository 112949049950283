.whybeema {
    width: 69%;
    margin: 0 auto;
}
.whybeema span {
    background: #fbaa24;
    width: 300px;
    height: 4px;
    margin: 20px 0 0;
    display: block;
    margin-bottom: 11px;
}

.textheading {
    width: 100%;
}

.whybeema h3 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #ed3543;
    letter-spacing: 7.4px;
    background: #ed35431a 0% 0% no-repeat padding-box;
    border-radius: 0.45rem;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin: 11px 0px;
}
.advantages {
    width: 100%;
    display: grid;
    margin: 27px 0px;
    grid-auto-flow: column;
}
.advantagecard {
    width: 87%;
    border: 1px solid #2959B8;
    text-align: center;
    border-radius: 6px;
    padding: -18px 0px;
    transition: 0.3s;
    background-color: white;
}

.knowmore {
   cursor: pointer;
    border-radius: 6px;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 10px 0px;
    color: #2959B8;
}
.buttonunderduvs{
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px 0px;
}

.advantagecard img{
    width: 63%;
}
.advantagecard p {
    font-family: 'Poppins';
    font-size: 10pt;
    color: #2959B8;
}
.advantagecard h6 {
    font-family: 'Poppins';
    font-size: 13pt;
    background: linear-gradient(to right, #0166b3, #0166b3, rgba(232, 20, 20, 0.518));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}
.headingadvantage{
    color:#2959B8;
}
.underimg {
    width: 93%;
}
.qustions {
    color: tomato;
    font-size: 12pt;
}

.extrapadding{
    padding: 15px 15px;
}
.whybeema a{
    color: #2959b8;
    font-family: "Poppins";
}
@media screen and (max-width: 1025px) {
    .textheading {
        width: 100%;
    }
}
@media screen and (max-width: 892px) {
    .advantages {
        width: 100%;
        display: block;

    }
    .advantagecard {
        width: 84%;
        border: 1px solid gray;
        text-align: center;
        border-radius: 6px;
        padding: -18px 0px;
        transition: 0.3s;
        background-color: white;
        margin: 15px auto;
    }
}