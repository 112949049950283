.hamslider-body {
  position: fixed;
  top: 0;
  right: -250px;
  height: 100%;
  width: 320px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.9s ease-out;
  z-index: 1000;
  box-shadow: 0 77px 0px 913px rgb(0 0 0 / 33%);
}

.menu-open {
  right: 0; /* Move the menu back to its original position when open */
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  0% {
    right: -250px;
  }
  100% {
    right: 0;
  }
}


.childsubmenu{
  width: 100%;
  position: relative;
  animation: topToBottom 1s ease forwards;

}

.childsubmenu .childli {
  width: 100%;
  margin-left: -42px;
}
.childsubmenu span{
  margin-left: 60px;
  margin-bottom: 10px;
}

.childsubmenu .childli a {
  color: #3C85F0;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11pt;
}



.hamslider-body.menu-closed {
  animation: slideOut 0.5s forwards;
}

@keyframes slideOut {
  0% {
    right: 0;
  }
  100% {
    right: -250px;
  }
}

.nav-ham {
  cursor: pointer;
  top: 20px;
  right: 20px;
  font-size: 24px;
  margin-top: -40px;
}

.menu-content-ham-icon {
  font-size: 15pt;
}

.hambody-icon-inside {
  background-color: rgb(9, 73, 168);
  padding: 10px;
  color: white;
  padding-left: 20px;
}

.hambody-inside-content ul {
  list-style: none;
  padding-left: 22px;
}

.hambody-inside-content li {
  margin: 10px;
  margin-left: 0px;
  font-family: sans-serif;
  font-size: 1.0rem;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

@media screen and (min-width: 940px) {
  .hamslider-body {
    display: none !important;
  }
}

.openmnuse {
  height: 128px;
  background: #87ceeb4f;
}

.closedivsehead {
  padding: 12px 15px;
  height: 57px;
}

.loggindivsep {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 60px;
  width: 85%;
  margin: auto;
}
.logginpreffer {
  display: block;
  text-align: center;
}

.loggindivsep img {
  width: 40%;
}

.loggindivsep button {
  color: white;
  background-color: rgb(35, 137, 253);
  border: none;
  border-radius: 5px;
  padding: 6px 13px;
  font-family: 'Poppins';
}
.loggindivsep p {
  color: #413f3f;
  margin-bottom: 0rem;
}


.mobileul {
  width: 90%;
  margin: 14px auto;
}

.mobileul li{
  list-style: none;
  margin-bottom: 26px;
  width: 93%;
  margin: 23px auto;
}

.mobileul li span {
  font-size: 15pt;
 position: absolute;
  color: #5b5757;
  right: 30px;
}

.mobileul li a {
  color: #575353;
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 12pt;
}


.submenu {
  list-style: none;
  padding-left: 0px;
  border-left: 1px solid rgb(170, 169, 169);
  animation: topToBottom 1s ease forwards;
}

.submenu li {
  margin-bottom: 10px;
  margin-left: 19px;
}



@keyframes topToBottom {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.logoutbtn {
  color: white;
  background-color: #0166B3;
  padding: 13px 43px;
  width: 98%;
  border: none;
  margin-top: 33px;
  margin-left: 5px;
  font-weight: 600;
  border-radius: 7px;
}