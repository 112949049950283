.skallitonform {
    height: 625px !important;
    bottom: 127px;
    border-radius: 15px !important;
}



.skallitonform00 {
    height: 60px !important;
    position: relative !important;
    top: 82px;
    left: 16px;
    width: 95%;
}

/* Diffrence */
.propodsal_formsklition {
    height: 388px;
    border-radius: 10px;
    background-color: #f4f4f4;
}
.headersklition_propsal{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 100px;
}
.skallitonform1 {
    height: 65px !important;
    border-radius: 7px;
    width: 95%;

}
.propsal_frobody{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100px;
}
.skallitonform2 {
    height: 65px !important;
    border-radius: 7px;
    width: 30%;

}
.skallitonform3 {
    height: 65px !important;
    border-radius: 7px;
    width: 30%;
}
.skallitonform4 {
    height: 65px !important;
    border-radius: 7px;
    width: 30%;
}