.aboutpage{
    width: 100%;
    padding: 25px 169px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.undertext p{
    text-align: justify;
}
.modeldiv{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
}
.abouttext{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}
.undertext{
    width: 90%;
    margin: 0 auto;
    /*line-height: 35px;*/
}
.undertext h5{
 color: #0166B3;
 font-size: 3rem;
}

.modeldiv img{
    width: 80%;
}
.fetures{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}
.fetures .onefeturetop{
    display: flex;
    flex-direction: column; /* Change the direction to column */
    justify-content: center;
    align-items: center;
    text-align: center;
}
.fetures .onefeture{
    width: auto;
     height: 85px;
     width: 85px;
    border-radius: 50%;
    padding: 10px 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.onefeturetop .publication {
    text-align: center;
    position: relative;
    top: 7px;
    /* font-weight: bold; */
    color: #0055a5;
}
@media screen and (max-width: 1024px){
    .imagestag{
        margin-bottom: 15px;
    }
}
/* Our Mission Start Hear */
.ourmission {
    width: 100%;
    padding: 40px 146px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 31px auto;
}
.ourmission .imagestag{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ourmission .imagestag img{
    width: 70%;
}
.ourmission .texttages{
    width: 60%;
}
.texttages .textunder{
    width: 90%;
    margin: 0 auto;
}
.textunder h5{
    color: #0166B3;
 font-size: 2rem;
}
/* Our Vision Start Hear */
.ourVision {
    width: 100%;
    padding: 40px 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 31px auto;
}
.ourVision .texttages{
    width: 60%;
}
.texttages .textunder{
    width: 90%;
    margin: 0 auto;
}
.ourVision .imagestag{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* How We Function Start Hear */
.Function {
    width: 100%;
    padding: 40px 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
}
.Function .imagedivs{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.Function .imagedivs img{
    width: 60%;
    border-radius: 13px
}
.Function .textdiv{
    width: 60%;
}
.Function .textdiv .textunder{
    width: 90%;
}
.textunder p{
    text-align: justify;
}
@media screen and (max-width: 899px) {
    .aboutpage{
            width: 100%;
            padding: 0px 0px;
            display: block;
    }
    .abouttext{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: red; */
    }
    .modeldiv {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .modeldiv img{
        width: 60%;
    }
    .onefeturetop .publication {
        text-align: center;
        position: relative;
        top: 5px;
        font-weight: bold;
        color: rgb(68, 66, 66);
        font-size: 9pt;
        margin-bottom: 33px;
      }
      .ourmission {
        width: 100%;
        padding: 0px 0px;
        display: block;
      }
      .ourmission .imagestag{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    .ourmission .imagestag img{
        width: 96%;
    }
    .ourmission .texttages{
        width: 100%;
    }
    .texttages .textunder{
        width: 90%;
        margin: 0 auto;
    }
    .ourVision {
        width: 100%;
        padding: 0px 0px;
        display: block;
        flex-direction: row; /* Reverse the order of the columns */
      }
      .ourVision .texttages {
        width: 100%;
      }
      .ourVision .imagestag {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ourVision .imagestag img{
      width: 96%;
    }
      .Function {
        width: 100%;
        padding: 0px 0px;
        display: block;
      }
      .Function .imagedivs {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-top: 32px;
      }
      .Function .imagedivs img{
        width: 96%;
        border-radius: 13px
    }
    .Function .textdiv {
        width: 100%;
      }
      .Function .textdiv .textunder {
        width: 90%;
        margin: 0 auto;
      }
}










.ourvision {
    position: relative; /* Ensure the container is positioned relative */
    width: 100%;
    height: 537px;
    background-color: #0055a5;
    background-image: url('https://ik.imagekit.io/teofx8bxs/Website%20Img/IMG-20240628-WA0020.jpg?updatedAt=1728307691450');
    background-size: cover;  /* Ensure the image covers the entire area */
    background-repeat: no-repeat;  /* Prevent the image from repeating */
    background-position: center;  /* Center the image */
}

.ourvision:before {
    content: "";
    opacity: 0.7;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    filter: blur(-4px);
    /* z-index: 1; */
    background: linear-gradient(180deg, #253858 43.44%, rgba(37, 56, 88, 0) 161.37%);
}

.headingbeemaabout{
    color: gray;
    font-size: 'Poppins';
    font-size: 14pt;
    text-align: center;
    padding: 14px;
}
.undercontainertextabout h2 {
    font-size: 24pt;
    padding: 10px;
    border-radius: 14px;
    background-color: antiquewhite;
    color: #0055a5;
}

.ourvision-content {
    position: relative; /* Position content above the blur */
    z-index: 2; /* Ensure content is above the blur */
    color: white; /* Adjust text color for better visibility */
}
.ourvision-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 412px;
}
@supports (-webkit-text-stroke:1px #fff) {
    .age {
        -webkit-text-stroke: 1px #fff;
        -webkit-text-fill-color: transparent;
        font-size: 150px;
        text-align: center;
        /* padding: 60px 0 30px; */
        position: relative;
        font-weight: 700;
        z-index: 1;
        line-height: 175px;
        font-family: Roboto, sans-serif;
    }
}
.tabulerabout {
    background-color: white;
    padding: 2px 10px;
    border-radius: 52px;
    position: relative;
    top: 61px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tababout {
    background-color: #0055a5;
    color: white;
    padding: 10px 10px;
    border-radius: 25px;
    margin: 7px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

}
.tababoutdeactive{
    color:#0055a5;
    padding: 10px 10px;
    border-radius: 25px;
    margin: 7px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}
.visionabout h2 {
    color: #0055a5;
    font-weight: 400;
    font-size: 28pt;
}

.containimage img {
    width: 80%;
    border-radius: 34px 36px 5px 120px;
    border: 10px solid var(--toastify-color-warning);
}


@media screen and (max-width:600px){
    .containimage img {
        width: 100%;
        border-radius: 34px 36px 5px 120px;
        border: 10px solid var(--toastify-color-warning);
    }
    .tababout {
        background-color: #0055a5;
        color: white;
        padding: 10px 10px;
        border-radius: 25px;
        margin: 7px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        font-size: 8pt;
    
    }
    .tababoutdeactive{
        color:#0055a5;
        padding: 10px 10px;
        border-radius: 25px;
        margin: 7px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        font-size: 8pt;
    }
    .undercontainertextabout h2 {
        font-size: 17pt;
        padding: 10px;
        border-radius: 14px;
        background-color: antiquewhite;
        color: #0055a5;
    }
}

















