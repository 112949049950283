.dataspolicy {
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;

    padding: 15px 0;
}
.sildebar{
    width: 20%;

    padding: 15px 0;
}
.contentdives{
    width: 70%;

}
.carddiv {
    width: 96%;
    position: sticky;
    top: 47px;
    margin: 0 auto;
    padding: 15px 0px;
    background-color: whitesmoke;
    border-radius: 10px;
  
}

.undercard{
    display: block;
    text-align: center;
    padding: 8px 0px;
}
.undercard a{
    font-size: 0.9rem;
    font-family: "Poppins";
}
.undercard span {
    color: #2959b8;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12pt;
    line-height: 27px;
}
.undercard img{
    width: 30%;
    margin-bottom: 12px;

}
.contentsse {
    width: 83%;
    margin: 14px auto;
    background-color: #f5f5f5;
    border-radius: 15px;
    padding: 21px 0px;
}
.buttonsdivs{
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsdivs a {
    color: white;
    width: 40%;
    height: 40px;
    border: none;
    background-color: #407bff;
    border-radius: 5px;
    margin: 0 5px;
    font-family: "Poppins";
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsdivs button:nth-last-child(1) {
    color: #407bff;
    width: 40%;
    height: 40px;
    border: 1px solid #407bff;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 0 5px;
    font-family: "Poppins";
    font-size: 0.8rem;
    font-weight: 500;
}
.optionsslinding {
    width: 96%;
    margin: 15px auto;
    padding: 8px 0px;
    border-top: 1px solid #8080803d;
}
.optionsslinding ul{
    margin-left: -38px;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}
.optionsslinding ul li{
 margin: 15px 0;
 list-style: none;
}
.policystatus{
    width: 95%;
    margin: auto;  
    position: relative;
     top: 20px;
}
.policystatus span{
    color: #407bff;;
    font-family: "Poppins";
    font-weight: 500;
    margin: 0 20px;
}
.policystatus img{
    width: 90px;
}
.contentdiscription{
    position: relative;
    top: 25px;
    padding: 10px 30px;
}

.contentdiscription p {
    color: rgb(90, 89, 89);
    font-family: "Poppins";
    font-size: 0.9rem;
}

.warningdivs {
    width: 83%;
    height: 44px;
    background-color: #407bff;
    border-radius: 12px;
    margin: 25px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.warningdivs p{
    color: white;
    font-family: "Poppins";
    margin-bottom: 0;
}



.gridoverview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1100px; 
    height: auto; 
    /* background-color: red; */
    margin: auto;
    padding: 20px; 
}
.griditems a{
    color: #2959b8;
    font-family: "Poppins";
    font-size: 0.9rem;
    transition: 0.3s;
}
.gridoverview a:hover{
    color: green;
    font-family: "Poppins";
    font-size: 0.9rem;
}

.griditems {
    width: 425px;
    padding: 15px 15px;
    background-color: #f5f5f5;
    border-radius: 15px;
    margin: 15px 20px;
    transition: transform 0.2s ease; 

}
.griditems h6 {
    font-family: "Poppins";
    font-size: 1.1rem;
    color: #407bff;

}
.griditems p {
    font-family: "Poppins";
    font-size: 0.8rem;
    color: #676262;
}
.griditems:hover{
    transform: scale(1.05); 
}
.hidedisplay{
    display: none;
}

@media screen and (max-width: 768px) {
    .griditems {
        width: calc(50% - 20px); 
    }
}

@media screen and (max-width: 480px) {
    .griditems {
        width: calc(100% - 20px); 
    }
}

.faqdivs {
    width: 83%;
    margin: 24px auto;
}
.accordion-button{
    font-family: "Poppins";
    font-weight: 500;
    color: #2959b8;
    display: flex;
    justify-content: space-evenly;
}
.accordion-body{
    padding: 20px 0;
}

.accordion-body span{
    font-family: "Poppins";
    color: rgb(94, 92, 92);
}

.relatedpolicy {
    width: 83%;
    margin: 10px auto;
    display: grid; /* Changed from flex to grid */
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr)); /* Added grid-template-columns */
    grid-gap: 10px; /* Added grid-gap for spacing between grid items */
    padding: 15px 15px;
}

.policycardrelated {
    width: 98%;
    height: 146px;
    padding: 25px 22px;
    border-radius: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.9);
    margin: 5px 0;
    overflow: hidden;
}

.policycardrelated img{
    width: 100px;
}
.policycardrelated span{
   color: #2959b8;
   font-family: "Poppins";
   font-weight: 500;
}
.realtedpolicyh3{
    color: gray;
    font-family: "Poppins";
    margin-left: 103px;
    font-size: 14pt;
    margin-top: 19px;
    margin-bottom: 15px;
}
.whatsok{
    width: 83%;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 15px;
    padding: 32px 28px;


}
.whatsok h3{
    color: #407bff;
    font-family: "Poppins";
    font-size: 16pt;
}
.whatsok span{
    color: gray;
    font-family: "Poppins";
    font-size: 10pt;
}
.modelcontentpopup{
    padding: 15px 15px;
}
.popuptext{
    font-family: "Poppins";
    line-height: 27px;
}
.modal-title{
    font-family: "poppins";
    color: #2959b8;
}
.modal-headerone{
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}


@media screen and (max-width: 1089px) {
    .dataspolicy {
        width: 100%;
        margin: 10px auto;
        display: block;

    }
    .sildebar {
        width: 100%;
        padding: 13px 0;
    }
    .contentdives {
        width: 100%;
    }
}
@media screen and (max-width: 967px) {
    .griditems {
        width: 95%;
        padding: 15px 15px;
        background-color: #f5f5f5;
        border-radius: 15px;
        margin: 15px 20px;
        transition: transform 0.2s ease;
    }
    .contentsse {
        width: 95%;
        margin: 14px auto;
        background-color: #f5f5f5;
        border-radius: 15px;
        padding: 21px 0px;
    }
    .warningdivs {
        width: 90%;
        height: 44px;
        background-color: #407bff;
        border-radius: 12px;
        margin: 0px auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .whatsok {
        width: 95%;
        margin: 0 auto;
        background-color: #f5f5f5;
        border-radius: 15px;
        padding: 32px 28px;
    }
    .realtedpolicyh3 {
        color: gray;
        font-family: "Poppins";
        margin-left: 29px;
        font-size: 14pt;
        margin-top: 19px;
        margin-bottom: 15px;
    }

.faqdivs {
    width: 95%;
    margin: 24px auto;
}
.relatedpolicy {
    width: 95%;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-gap: 10px;
    padding: 15px 15px;
}

.policystatus {
    width: 95%;
    margin: auto;
    position: relative;
    display: flex;
    top: 20px;
    justify-content: space-evenly;
}
.warningdivs p {
    color: white;
    font-family: "Poppins";
    margin-bottom: 0;
    font-size: 10pt;
}
.gridoverview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1100px;
    height: auto;
    /* background-color: red; */
    margin: auto;
    padding: 0px;
}
.optionsslinding ul {
    margin-left: 0px;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Hide vertical scrollbar */
    white-space: nowrap; /* Prevent line breaks */
}

.optionsslinding ul::-webkit-scrollbar {
    height: 2px; /* Height of the scrollbar */
}
.optionsslinding ul::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

.optionsslinding ul::-webkit-scrollbar-thumb {
width: 0px;
height: 0px;
}

.optionsslinding ul::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker background on hover */
}
.optionsslinding ul li {
    margin: 15px 15px;
    list-style: none;
    width: 133px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
}