.sucesspagess{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sucessdiv img{
    width: 80%;
}
.sucesspagess img{
  width: 300px;
}
.textssucess{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0px;
    position: relative;
    top: 25px;
    
}
.textssucess h1{
    color: #2859B7;
    font-size: 3rem;
}
.tnkp{
    color: #F96766;
    font-size: 1.5rem;
    font-weight: 600;
}
.blw{
    color: #1e7ad8c4;
}
.gdwish{
    color: #cc8915;
}
.sucessdiv{
    display: flex;
    justify-content: center;
    align-items: center;
padding: 55px 0;
margin: 15px 0;
}
.policynum{
    color: #f96766;
    font-weight: 600;
    font-size: 1.3rem;
}
.policyy{
    color: #2859b7;
}
.textssucess button{
    background-color: #2859B7;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid  #2859B7;
}