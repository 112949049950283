/* .hidden {
  display: none;
}

.Coverinsure {
display: block;
padding: 10px;
margin: 5px;
background-color: transparent;
border: none;
cursor: pointer;
border: 1px solid black;
border-radius: 10px;

}
.coverinput{
margin: 0 8px;
height: 20px;
width: 20px;
}

.ActiveButtons{
display: flex;
width: 100%;
justify-content: space-around;
align-items: center;
margin-top: 5px;
}
.ActiveButtons button{
color: white;
background-color: rgb(0 85 165);
border: 1px solid rgb(0 85 165);
border-radius: 5px;
width: 48%;
padding: 10px 0;
}

.defaulttanuer{
display: flex;
justify-content: center;
align-items: center;
border: 1px solid rgb(24, 23, 23);
background-color: transparent;
padding: 10px 0;
margin: 10px 0;
color: rgb(22, 22, 22);
border-radius: 8px;
}
.activetanure{
display: flex;
justify-content: center;
align-items: center;
border: 1px solid rgb(0 85 165);
background-color: rgb(0 85 165);
padding: 10px 0;
margin: 10px 0;
color: rgb(252, 252, 252);
border-radius: 8px;
}
 */


 @keyframes slideDown {
  from {
    top: -10px; /* Start from above the viewport */
  }
  to {
    top: -50px; /* Slide down to the top of the viewport */
  }
}
 .filterdies {
  width: 96%;
  padding: 14px 0px;
  background-color: white;
  height: 91px;
  border-radius: 12px;
  margin: 17px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.selectdives {
  width: 149px;
  height: 37px;
  border: 1px solid #a5a7a8;
  margin: 0px 15px;
  padding: 8px;
  border-radius: 6px;
  color: #5c6369;
  font-weight: 500;
  font-family: 'Poppins';
  cursor: pointer;
  position: relative;
  font-size: 10pt;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.underfilterdiv span {
  color: #0166B3;
  margin-left: 17px;
  position: relative;
  bottom: 5px;
  font-weight: 500;
  font-size: 10pt;
}
@keyframes slideInTop {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.menubar {
  border: 1px solid white;
  width: 220px;
  height: auto; /* Adjust height according to content */
  max-height: 351px; /* Optional: max height to add a scrollbar */
  position: absolute;
  background-color: white;
  z-index: 9999;
  border-radius: 7px;
  left: 0px;
  top: 49px;
  padding: 4px 0px;
  box-shadow: 0px 0px 3px -1px black;
  animation: slideInTop 0.5s ease-in-out;
  overflow-y: auto; /* Add scrollbar if content exceeds max height */
}

.listborder {
  border-bottom: 1px solid #d5d5d5;
  padding: 8px 0px;
  list-style: none;
  display: flex;
 color: #0166B3;
 
}
.listborder span{
  margin: 0 10px;
}
.listborderdeactivate {
  border-bottom: 1px solid #d5d5d5;
  padding: 8px 0px;
  list-style: none;
  display: flex;
  color: gray;
  justify-content: strat;

}
.listborderdeactivate span{
  margin: 0 10px;
}
.listborderdeactivate input {
  margin: 0 5px;
  width: 19px;
  height: 18px;
}
.listborder input{
  margin: 0 5px;
  width: 19px;
  height: 18px;
}
.navbarmenulabel {
  color: #0166B3;
  margin-bottom: -10px;
}
.menubtnflexdisplay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 23px;
  left: 0;
  right: 0;
  padding: 0 10px;
}
.menubtnflexdisplay button{
  width: 45%;
  color: white;
  background-color: #0166B3;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
}
.roundcireclactive {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #0166B3;
  margin: 0px 10px;
}
.roundcirecldeactive {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #d5d5d5;
  margin: 0px 10px;
}
.overflowmenubar {
  overflow-y: scroll;
  max-height: 263px;
  scrollbar-width: none;
}
.buttondivs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
}

.Applybtn {
  width: 45%;
  background-color: #0166B3;
  color: white;
  border: none;
  padding: 6px;
  border-radius: 6px;
  font-weight: 500;
}
.disables{
  width: 45%;
  background-color: #5298cd;
  color: white;
  border: none;
  padding: 6px;
  border-radius: 6px;
  font-weight: 500;
}

.cancelld{
  width: 45%;
  background-color: white;
  color: #0166B3;
  border: none;
  padding: 6px;
  border-radius: 6px;
  font-weight: 500;
}
.Companylist {
  display: flex;
  padding: 10px 10px;
  border: 1px solid gray;
  margin: 5px 0px;
  border-radius: 9px;
}

.Companylist li{
  list-style: none;
  color: #0166B3;

}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  margin: auto;
  z-index: 999;
  top: -2px !important;
  animation: slideDown 0.2s forwards;
}
.Companylist li {
  list-style: none;
  color: #0166B3;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.Companylist li span{
  color: #0166B3;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 0 5px;
}
.filterbtn {

  display: none;
}


@media screen  and (max-width: 600px){
.selectdives {
  border: 1px solid #0166B3;
  margin: 0px 14px;
  padding: 7px;
  border-radius: 6px;
  color: #0166B3;
  font-weight: 500;
  font-family: 'Poppins';
  cursor: pointer;
  position: relative;
  font-size: 8pt;
}
.underfilterdiv span {
display: none;
}
.filterbtn {
  border-radius: 8px;
  color: #0166b3;
  border: none;
  padding: 11px 13px;
  font-weight: 600;
  font-size: 12pt;

  display: block;
  font-family: 'Poppins';
}

.filterdies {
  width: 100%;
  background-color: white;
  height: 50px;
  border-radius: 12px;
  margin: 17px auto;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 33px;
}
.fixed {
  position: fixed;
  width: 100%;
  margin: auto;
  z-index: 999;
  top: -2px !important;
  animation: slideDown 0.2s forwards;
  right: 0;
  padding: 10px 0px;
  height: 91px;
}
}

@media screen and (max-width:745px){
  .selectdives {
 display: none;
  }
}


.dbuttonfilter {
  width: 45%;
  background-color: #0166b3bf;
  color: white;
  border: none;
  padding: 6px;
  border-radius: 6px;
  font-weight: 500;
}
.bottomactionfilter {
  width: 96%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  gap: 1px;
  padding: 30px 0px;
  position: absolute;
  bottom: 0;
}


.Applybtnfilter {
  background-color: #0166b3;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  width: 48%;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.Applybtnfilter:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

.Applybtnfilter:active {
  transform: scale(0.98); /* Slightly shrink button on click */
}

/* Style for Cancel button */
.candelldbtnfilter {
  background-color: #f8f9fa; /* Light gray background */
  color: #343a40; /* Darker text color */
  border: 1px solid #ced4da; /* Gray border */
  border-radius: 5px;
  width: 48%;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.candelldbtnfilter:hover {
  background-color: #e2e6ea; /* Slightly darker gray on hover */
}

.candelldbtnfilter:active {
  transform: scale(0.98); /* Slightly shrink button on click */
}
