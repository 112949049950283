.textamount{
    font-family: "Poppins";
    font-size: 10pt;
    color: #2959b8;
}
.textbond {
    font-family: "Poppins";
    font-size: 11pt;
    color: #5f5d5d;
}
.ytmtext {
    font-family: "Poppins";
    font-size: 12pt;
    color: #5f5d5d;
}
.textblues{
    color: #2959b8;
}
.centerfib{
    margin: auto;
}
.rowdisplay{
    width: 1585px;
    margin: auto;
}
.underbonddivs {
    padding: 8px 0;
}
@media screen and (max-width: 628px) {
    .underbonddivs {
        height: 2098px;
    }
  .undergraphdivest{
margin-bottom: 25px;
  }
}

.resultdesk {
    padding: 10px 65px;
}