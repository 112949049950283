.maincontainer{
display: flex;
overflow: auto;  
height: 598px;
}
.underdashbordcontainer{
    border: 1px dotted #d8d8d8;
    width: 96%;
    text-align: center;
    border-radius: 15px;
   display: block;
   margin: 0 auto;
}
.sidebarse{
    width: 15%;

    /* background-color: red; */
    position: sticky;
    display: flex;
    justify-content: end;
    padding: 5px 0;
}
.subsidebar ul .activeDashbord{
    list-style: none;
    background-color: rgb(41, 87, 182);
    color: white;
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 5px;
    
}
.subsidebar ul .activeDashbord a{
    cursor: pointer
}
.policy-issued{
    color: green;
    font-size: 15px;
    font-weight: 600;
}
.subsidebar ul .deactiveDashbord{
    list-style: none;
    background-color: #fff4e9;
    color: rgb(99, 96, 96);
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 5px;
}
.subsidebar ul .deactiveDashbord a{
    cursor: pointer;
}


.dashbordhamburger{
display: none;
}



.dashbordcontainer{
    width: 85%;
    height: 280px;
    display: flex;
    justify-content: center;
    padding: 15px 0;
}
.underdashbord {
    width: 101%;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px auto;
}
.dashbordcard {
    width: 316px;
    height: 219px;
    background-color: #fca84c1f;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 0px rgb(134 134 134 / 90%);
    transition: 0.3s;
}
.dashbordicon{
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: end;
    align-items: center;
 
    padding: 0px 20px;
}


.numberdashbord{
    width: 100%;
    height: 95px;
    /* background-color: red; */
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 25px;
}
.numberdashbord h1{
 font-size: 3rem;
}
.dashbordicon img{
    width: 50px;
    padding: 7px 9px;
}

.underpolicy {
    width: 96%;
    height: 560px;
    border: 1px dotted #cecfd1;
    border-radius: 15px;
    overflow: auto;
    padding: 0 2px;
}

.textpolicy {
    width: 100%;
    padding: 9px 10px;
}
.textpolicy input{
    padding: 15px 14px
}
.textpolicy span{
    color: gray;
    font-size: 14pt;
    font-weight: 600;
}
.menuoption{
    width: 97%;
    margin: 0 auto;
    background-color: whitesmoke;
    border-radius: 5px;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
 
    
}
.menuoption p{
    font-weight: 500;
    color: gray;
    font-size: 10pt;
}
.menuoptionse{
    width: 97%;
    margin: 0 auto;
    background-color: whitesmoke;
    border-radius: 5px;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.menuoptionse p{
 font-size: 9pt;
}

.underpolicy::-webkit-scrollbar {
    display: none;
}

.table-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
  }
  
  .policy-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .policy-table th, .policy-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .policy-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .policy-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }


.beemadownload {
    color: #2957B6;
    /* background-color: #2957B6; */
    padding: 6px 4px;
}
  
  .policy-table tbody tr:hover {
    background-color: #ddd;
  }
  .renewbutton button {
    color: white;
    background-color: #2957B6;
    border-radius: 5px;
    border: none;
    padding: 9px 6px;
}
  .policy-table td{
    color: rgb(49, 47, 47);
    font-size: 10pt;
    text-align: start;
    text-align: center;
  }
  .policy-table th {
    font-size: 10pt;
    font-weight: 500;
    border: 1px solid white;
    text-align: center;
    background-color: #2957B6;
    color: white;
}
  .padenationdiv{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
 
  }
  .dashbordmargin{
    padding-top: 95px;
  }
  @media screen and (max-width: 600px){
    .underpolicy {
        width: 96%;
        height: 573px;
        border: 1px dotted #cecfd1;
        border-radius: 15px;
        overflow: auto;
        padding: 0 2px;
    }
  }

  .resultdivs{
    width: 92%;
    background-color: white;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0px 1px 3px 0px rgb(0 127 255 / 39%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    padding: 5px 0;
}
.resultdivs .propnumber{
    color: #2957B6;
    font-weight: 500;
}
.resultdivs button{
    color: white;
    background-color: #2957B6;
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
}
.resultdivs .namese{
    color: gray;
    font-weight: 600;
}
.editcheckbox{
    width: 40%;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    border: 1px solid rgb(168, 160, 160);
    align-items: center;
    border-radius: 5px;
}
.pagenation{
    padding: 15px 0;

display: flex;
justify-content: center;
align-items: center;
}

.resped span{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 11pt;
}

.result-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.result-table-header {
    background-color: #2959B8;
    color: white;
    padding: 10px;
    text-align: center;
}

.result-table-row:nth-child(even) {
    background-color: #f2f2f2;
}

.result-table-data {
    padding: 10px;
}

.result-member {
    color: gray;
}

.result-resume-button {
    background-color: #2959B8;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.result-resume-button:hover {
    background-color: #1e3a7e;
}
.renecntsearch {
    margin: 10px 0;
    text-align: left;
    padding: 0 15px;
    position: relative;
    top: 24px;
}
.supportdiv{
    padding: 15px 0;
}

.buttonnavbar{
    display: none;
}

.policytype_tab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 334px;
    margin-top: 15px;
    height: 43px;
    border-radius: 18px;
}
.hero_policy_tab{
    width: 220px;
    height: 43px;
    background-color: #2957B6;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    cursor: pointer;

}
.hero_policy_deactive_tab{
    width: 220px;
    height: 43px;
    background-color: transparent;
    color: #2957B6;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .maincontainer {
        justify-content: center;
        margin: 0 auto;
        display: flex;
    }
    .underdashbordcontainer {
        border: 1px dotted #d8d8d8;
        width: 100%;
        height: 814px;
        text-align: center;
        border-radius: 15px;
        display: block;
        margin: 15px auto;
    }
    .sidebarse{
        display: none;
    }

    .underdashbord {
        width: 100%;
        border-radius: 15px;
        display: block;
        margin: 23px auto;
    }
.dashbordcard {
    width: 97%;
    height: 173px;
    background-color: #fca84c1f;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 0px rgb(134 134 134 / 90%);
    transition: 0.3s;
    margin: 10px auto;
}
.supportdiv {
    overflow: auto; /* Allow scrolling */
    padding: 15px 0;
    position: relative; /* Needed for pseudo-elements */
}

.supportdiv::-webkit-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

.buttonnavbar {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 22px;
}
.dashbordhamburger{
    color: rgb(46, 46, 46);
    background-color: rgb(231, 228, 228);
    border: none;
    border-radius: 35px;
    font-size: 12pt;
    padding: 10px 13px;
    font-weight: bold;
    display: flex;
}
.result-table-data {
    padding: 10px;
    font-size: 10pt;
    font-family: 'Poppins';
}
.result-table-header {
    background-color: #2959B8;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 10pt;
    font-family: 'Poppins';
}
@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}
.subsidebar {
    width: 100%;
    margin: 16px 25px;
    padding: 15px 45px;
    position: relative;
    left: 13px;
    animation: slideFromLeft 0.5s forwards;
}
}
@media screen and (max-width: 800px){
    .underullist {
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: relative;
        top: 11px;
    }
    .crossdiv {
        display: flex;
        justify-content: end;
        align-items: center;
        width: 90%;
        margin: auto;
        /* height: 15px; */
        padding: 7px 0px;
    }
    .crossdiv button{
        background-color: transparent;
        border: none;
        font-size: 18pt;
    }
      
      .underullist li {
        padding: 10px; /* Add some padding for clickable area */
        display: flex; /* Flex layout to align items */
        align-items: center; /* Vertically center items */
        cursor: pointer; /* Change cursor to pointer on hover */
      }
      
      .underullist li a {
        text-decoration: none; /* Remove underline from links */
        color: inherit; /* Inherit color from parent */
        display: flex; /* Flex layout for icons and text */
        align-items: center; /* Vertically center items */
      }
      
      .underullist li.activeDashbord {
        margin: 6px 1px;
        font-weight: 400;
        padding: 19px 10px;
    }
      
      .underullist li.deactiveDashbord {
     margin: 6px 9px;
     font-weight: 400;
      }
      
      .underullist li:hover {
        background-color: #e0e0e0; 
      }
      
}
.tabs {
    width: 360px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 45px;
}

/* Keyframes for color appearance animation */
/* Keyframes for color spread animation */

  
  /* Style for active tab */
  .tab {
    width: 45%;
    height: 47px;
    background-color: #2959B8;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 transition: 0.3s;
  }
  
  /* Style for inactive tab */
  .deactivetab {
    width: 45%;
    height: 47px;
    background-color: #ddd;
    color: black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .table-button {
    background-color: #fd9202;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .table-button:hover {
    background-color: #e50318;
  }
  
  .table-button:active {
    background-color: #0055a5;
  }
  