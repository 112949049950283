.custom-dot-list-style {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
}

.custom-dot-list-style li {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); /* Example of a stronger box shadow */
}

.custom-dot-list-style li:hover {
    background-color: #999;
}


.tesyemonialtext{
    text-align: center;
}
.backgroundcarasoul {
    background-color: transparent;
    padding: 15px 0;
}
.tantemonial{
    width: 100%;
    height: 340px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes bottomToTop {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-20px);
    }
}

.tastemonialcard {
    width: 279px;
    height: 299px;
    background-color: #f5f5f5c9;
    margin: 5px auto;
    overflow-y: auto;
    /* box-shadow: 0px 3px 30px #0000000f; */
    border-radius: 35px 0px 65px 13px;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
    padding: 32px 0;
}

.tastemonialcard:hover {
    animation: bottomToTop 0.6s forwards;

}

.tastemonialcard:hover::after {
    animation: bottomToTopReverse 0.6s forwards;
}

@keyframes bottomToTopReverse {
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0);
    }
}



.nameandstar {

    width: 100%;
    height: 59px;
    display: flex;
    padding: 0 24px;
    align-items: center;
}
.undertextstar{
    text-align: start;
}
.undertextstar{
    color: #2959B8;
    font-family: 'Poppins';
    font-size: 10pt;
}
.discriptiontestomonial{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin: auto;



}
.underreview{
   margin: auto;
   overflow: hidden;
}
.discriptiontestomonial span{
    font-size: 10pt;
    font-family: 'Poppins';
    color: gray;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7; /* Show only 5 lines */
    overflow: hidden;
    text-overflow: ellipsis;

}
@media screen and (max-width: 912px) {
    .tastemonialcard {
        width: 279px;
        height: 299px;
        background-color: #f5f5f5c9;
        margin: 5px auto;
        overflow-y: auto;
        /* box-shadow: 0px 3px 30px #0000000f; */
        border-radius: 35px 0px 65px 13px;
        transform: translateY(0);
        transition: transform 0.6s ease-in-out;
        padding: 32px 0;
    }
}
@media screen and (max-width: 820px) {
    .tastemonialcard {
        width: 279px;
        height: 299px;
        background-color: #f5f5f5c9;
        margin: 5px auto;
        overflow-y: auto;
        /* box-shadow: 0px 3px 30px #0000000f; */
        border-radius: 35px 0px 65px 13px;
        transform: translateY(0);
        transition: transform 0.6s ease-in-out;
        padding: 32px 0;
    }
}
@media screen and (max-width: 630px) {
    .tastemonialcard {
        width: 96%;
        height: 299px;
        background-color: #f5f5f5c9;
        margin: 5px auto;
        overflow-y: auto;
        /* box-shadow: 0px 3px 30px #0000000f; */
        border-radius: 35px 0px 65px 13px;
        transform: translateY(0);
        transition: transform 0.6s ease-in-out;
        padding: 32px 0;
    }
}