
.addoncode{
  color: #2959b8;
  font-family: 'Poppins';
  font-size: 13px;
  text-align: left;
  font-weight: 500;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 
}

.addon-price-data{
  margin-right: 20px;
  color: green;

}
.addon-price-data-negative{
  margin-right: 20px;
  color:red;
  
}
.flexdisplaysummery {
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 16px 0px;
}
.flexdisplaysummery p {
  margin-bottom: 0rem;
  font-size: 8pt;
  margin-top: 9px;
}
.Summerypage{
    color: white;
    color: white;
    font-size: 1.3rem;
}
.tanuresuminsurediv{
  padding: 10px 15px;
  line-height: 15px;
}
.primiumbox {
  background-color: #f3f3f3;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0px;
}
.showhidebutton{
  color: #343435;
  background-color: transparent;
  border: none;
}
@keyframes topToBottom {
  from {
    transform: translateY(-100%); /* Start off-screen above */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* End at the original position */
    opacity: 1;
  }
}

.animatonTopToBottom {
  animation: topToBottom 0.5s ease-in-out;
}


.rotate-up {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.rotate-down {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
/* Apply the animation to a class */



.Primiumamount {
  color: #2959b8;
  font-size: 11pt;
  font-weight: 500;
  font-family: 'Poppins';
}
.premiumtext {
  color: #7f7c7c;
  font-size: 10pt;
  font-weight: 500;
  font-family: 'Poppins';
}
.suminsuredata {
  color: #605d5d;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 11pt !important;
}
.tanuredata{
  color: #605d5d;
  font-family: "Poppins";
  font-size: 11pt !important;
  font-weight: 400;
}
.boldtext {
  color: #2959b8;
  font-weight: 600;
  font-size: 11pt;
  font-family: 'Poppins';
}
.Primium {
  color: green;
  font-size: 15px;
  font-weight: 500;
  text-align: end;
}
  .bestoffer {
    width: 100%;
    /* height: 50px; */
    background-color: #9fbdf957;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 4px;
    padding: 7px;
} 


.portpolicy{
  width: 99%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 3px;
  margin-bottom: 8px;
}
.portpolicy span {
  font-family: "Poppins";
  margin: 0 5px;
  font-size: 9pt;
  color: #555252;
}
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
    background-size: 200% 200%;
    animation: loading 2s infinite;
  }

  .BannerOffer,
  a {
    opacity: 5;
    color: green;
  }
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    25% {
      background-position: 150% 0;
    }
    50% {
      background-position: 100% 0;
    }
    75% {
      background-position: 50% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  

  .BannerOffer {
    font-size: 0.9rem;
    color: rgb(53, 51, 51);
    margin: 0 5px;
    font-size: 10pt;
    font-weight: 400;
    font-family: 'Poppins';
}
.popupbutton{
  font-family: 'Poppins';
}
.amountcolor{
    color: #0166b3;
    font-weight: 600;
}
.popupbutton{
    color: #0166b3;
    background-color: transparent;
    border: transparent;
    font-size: 0.9rem;
    z-index: 999;
}
.offerdivs {
    position: fixed;
    width: 694px;
    height: 327px;
    border-radius: 21px;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgb(255, 255, 255);
    box-shadow: 317px 385px 241px 945px rgb(0 ,0 ,0 ,0.72)
  }
  /* .offerdivs .haderdivs{
    width: 95%;
    margin: 0 auto;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: red;
  } */
  /* .offerdivs .haderdivs button{
    background-color: #2959b8;
    border: none;
    font-size: 19px;
    color: white;
    border-radius: 3px;
  } */
  .summeryfoot {
    background-color: #5297cc21;
    margin: 10px 0px;
    border-radius: 3px;
    padding: 5px 1px;
    border: 1px dotted;
}
.insure{
  font-size: 0.9rem;
  color: #0166b3;
}
.gst{
  font-size: 0.9rem;
  color: #0166b3;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: none; /* Hide by default */
  z-index: 999; /* Ensure it’s above other content */
}
@keyframes slideDown {
  from {
    top: -100%; /* Start above the viewport */
    opacity: 0; /* Start invisible */
  }
  to {
    top: 50%; /* End at the center */
    opacity: 1; /* Fully visible */
  }
}
.summerypopup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  width: 504px;
  height: 340px;
  background-color: white;
  border-radius: 22px;
  z-index: 1000;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  animation: slideDown 0.5s ease forwards;
}
.no-scroll {
  overflow: hidden; /* Prevent scrolling */
  height: 100%; /* Ensure it takes full height */
}

.backgroundblock {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(23, 43, 77, .9);
  width: 100%;
  height: 100vh;
  z-index: 99999;
}

.headerpopup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 19px;
  background-color: #2959B8;
  border-radius: 15px 18px 0px 1px;
}
.headerpopup button {
  color: white;
  /* padding: 10px; */
  background-color: transparent;
  border-radius: 15px;
  border: none;
  font-size: 15pt;
}

.headerpopup span {
  color: white;
  font-family: 'Poppins';
  font-weight: 500;
}
.popupbodysummery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  border-bottom: 1px solid #80808042;
}
.popupbodysummery select {
  background-color: transparent;
  color: #0166b3;
  border-bottom: 1px dashed;
  margin-left: 8px;
  padding: 0px 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
}
.summerydesxription {
  color: #595252;
  font-size: 12pt;
  font-weight: 400;
}
.summerymoneyspan{
  color: #0166b3;
  font-weight: 500;
}
.h4summerypopup {
  color: #473e3e;
  font-size: 13pt;
  font-weight: 500;
  margin-bottom: 0;
}
.summerymoneyspanspecial {
  color: var(--success);
  font-size: 13pt;
  font-weight: 600;
}
.termsandconditionspansummery {
  color: gray;
  font-size: 9pt;
  padding: 8px 14px;
  position: absolute;
  bottom: 11px;
}

@media screen and (max-width:600px){
  .summerypopup {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);
    width: 90%;
    height: 340px;
    background-color: white;
    border-radius: 22px;
    z-index: 1000;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    animation: slideDown 0.5s ease forwards;
}
.summerydesxription {
  color: #595252;
  font-size: 10pt;
  font-weight: 400;
}
.h4summerypopup {
  color: #473e3e;
  font-size: 12pt;
  font-weight: 500;
  margin-bottom: 0;
}
}