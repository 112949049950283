.medicalsklition_formsklition{
    width: 100%;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 10px 0px;
}
.medicalfullwidth{
    display: flex;
    justify-content: center;
    align-items: center;
}
.medicalfullwidthQustions{
    width: 90%;
    height: 80px !important;
    border-radius: 10px;
}