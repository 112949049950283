.clamemanagementform {
    width: 96%;
    border: 1px dotted rgba(128, 128, 128, 0.404);
    border-radius: 15px;
    margin: 0 auto;
    height: 571px;
    padding: 0 2px;
}
.addclamemanagement {
    width: 100%;
    padding: 0 15px;
    height: 60px;
    display: flex;
    justify-content: end;
    /* background-color: red; */
    align-items: center;
}

.addclamemanagement button{
    border: none;
    background-color: #FCA84C;
    color: white;
    padding: 5px 5px;
    border-radius: 5px;
}
.clameformunder{
    padding: 15px 35px;
}

.extrawidth{
    width: 700px;
    margin: 0 auto;
    position: relative;
    right: 95px;
}
.clameform label{
   font-size: 10pt;
   position: relative;
   top: 5px;
   font-family: sans-serif;
}
.clameform input {
    min-height: 48px;
    height: 100%;
    font-family: "Proxima Nova Lt";
    font-size: 14px;
    font-weight: 600;
    color: #68768f;
    line-height: 17px;
    outline: none;
  }
.clameform select{
    min-height: 48px;
    height: 100%;
    font-family: "Proxima Nova Lt";
    font-size: 14px;
    font-weight: 600;
    color: #68768f;
    line-height: 17px;
    outline: none;
}



  .upload-cv-file {
    width: 100%;
    background-color: #2959B80F;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 18px 0px;
    position: relative;
    text-align: center;
    border: 0.5px dashed #2959B8;
    border-radius: 24px;
}
.submitdiv{
    width: 100;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: 30px;
  
}
.submitdiv button{
    padding: 12px 85px;
    border-radius: 20px;
    background-color: rgb(41, 89, 184);
    border: 1px solid rgb(41, 89, 184);
}
.motalbody{
    position: absolute;
    width: 163px;
    right: -125px;
    top: 135px;
    z-index: 1050;
}

@media screen  and (max-width: 1000px){
    .motalbody{
   display: none;
    }
    .extrawidth {

        margin: 0 auto;
        position: relative;
        right: 0px;
    }
}


.FileChange{
    z-index: 9999;
}


@media screen and (max-width: 786px) {
    .addclamemanagement {
        width: 100%;
        padding: 0 15px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        /* background-color: red; */
        align-items: center;
    }
}