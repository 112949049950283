/* .noteadvice{
width: 1154px;
margin: 10px auto;
padding: 15px 15px;
}
.advicetext h3 {
    color: aliceblue;
    background-color: #F96766;
    padding: 15px 15px;
    text-align: center;
    margin: 10px 0px;
    width: 637px;
}
.advicetext p {
    color: gray;
    font-family: 'Poppins';
    font-size: 11pt;
    position: relative;
    top: 7px;
}
.mainnote p{
    color: #407BFF;
    font-family: 'Poppins';
    font-size: 16pt;
    font-weight: 500;
}
.mainnote span{
    color: rgb(70, 69, 69);
    font-family: 'Poppins';
    font-size: 11pt;
}
.mainnote ul li{

    font-family: "Poppins";
    font-size: 12pt;
    color: rgb(70, 69, 69);
} */


.mainsection {
    margin: auto;
    margin-top: 15px;
    width: 90%;
    margin-bottom: 55px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 25px 5px;
    flex-direction: column;
    max-width: 1200px!important;
    /* background-color: antiquewhite; */
   
    /* background-image: url("https://ik.imagekit.io/teofx8bxs/Website%20Img/Backgroundimage.jpeg?updatedAt=1718874747653"); */

}

/* .mainsection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 48%);
    z-index: 1;
} */

.mainsection > * {
    position: relative;
    z-index: 2;
    color: white; /* Ensure your text is readable */
}

.textcontents {
    /* width:  90%; */
}
.textcontents h3 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #ed3543;
    letter-spacing: 7.4px;
    background: #ed35431a 0% 0% no-repeat padding-box;
    border-radius: 0.45rem;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin: 11px 0px;
}

@media screen and (max-width:1200px) {

    .textcontents h3{
      font-size: 30px!important;
    }
  
  }
  
  @media screen and (max-width:760px) {
  
    .textcontents h3{
      font-size: 27px!important;
    }
  
  }
.textcontents p {
    width: 91%;
    color: rgb(122 119 119);
    font-family: 'Poppins';
    font-size: 12pt;
}

.advicesess {
    /* width: 92%; */
    /* background-color: aliceblue; */
    position: relative;
    /* left: -30px; */
    right: 0;

    border-radius: 19px;
    /* box-shadow: -6px 5px 0px 2px rgb(64 123 255); */
    padding: 16px 18px;
    padding-left: 0px;
}

.noteble .mainlist{
    color: rgb(122 119 119);
    font-family: 'Poppins';
    font-size: 12pt;
    text-align: justify;
    list-style: none;
    
}
.superlist{
    color: rgb(122 119 119);
    font-family: 'Poppins';
    font-size: 12pt;
    text-align: justify;
    list-style-type: auto;
} 

.noteble h4 {
    color: #4b4b4b;
    font-family: 'Poppins';
    font-size: 14pt;
    background-color: #ffe4c478;
    padding: 11px;
    color: #595050;
    width: fit-content;
    border-radius: 20px;
    color: #4b4b4b;
    font-family: 'Poppins';
    font-size: 14pt;
    background-color: #f9d1a04d;
    padding: 11px;
    color: #e5a212eb;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 6px;
    margin: 10px 0px;
}


.note-num{
    background-color: #f96766;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

/* @media screen and (max-width: 820px){
    .mainsection {
        margin-top: 15px;
        flex-direction: column-reverse; 
        margin-bottom: 55px;
        position: relative;
        display: block;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 25px 5px;
        background-image: url(../NoteWarthyAdvice/stock-photo-general-practitioner-pointing-insurance-claim-form-patient-clinic.jpeg);
    
    }

.advicesess {
    width: 96%;
    background-color: rgb(234, 141, 34);
    position: relative;
    left: 0px;
    right: 0;
    top: 0px!important;
    border-radius: 19px;
    box-shadow: -6px 5px 0px 2px rgb(64 123 255);
    padding: 16px 18px;
    margin: auto;
}
.textcontents {
    width: 91%;
    margin: auto;
    margin-top: 30px;
    
}

} */
