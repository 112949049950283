
.userdetailsdivs {
  width: 75%;
  background-color: #ffffff;
  box-shadow: -1px 0px 8px -3px rgb(0 0 0 / 30%);
  margin: 15px auto;
  border-radius: 11px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 21px 17px;
}
.subunderheading{
    width: 100%;
}
.termtdit{
    width: 30%;
    margin: auto;
    margin-bottom: 20px;
}
.filterterminsurence{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.filterterm{
  display: none;
}

.lablep {
  margin-bottom: 0rem;
  color: #0166B3;
  font-weight: 500;
  font-size: 9pt;
  font-family: 'Poppins';
}
.underdivsterm{
  margin-bottom: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.underdivsterm p {
    color: #0166B3;
    font-family: 'Poppins';
    font-size: 14pt;
    font-weight: 500;
    margin: 0 10px;
}
.termdetailsdivs{
    display: flex;
    align-items: center;
}
.termdetailsdivs p {
  margin: 0 10px;
  color: #797575;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13pt;
}
.sidebarArrow{
  color: rgb(182, 176, 176);
}
.termdetailsdivs a {
    color: #0166B3;
    text-decoration: none;
    margin: 0 14px;
}
.userdetailsdivs hr{
    color: gray;
    width: 100%;
}
.underplanes {
    width: 70%;
}
.termplanes {
  width: 92%;
  padding: 14px 0px;
  background-color: white;
  margin: 13px auto;
  border-radius: 15px;
  display: flex;
  justify-content: start;
  box-shadow: 0px 0px 4px 0px #00000029;
  opacity: 0;
  transform: scale(0.5);
  animation: slideFromTop 0.5s ease forwards;
}
  
@keyframes slideFromTop {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
  
  .imageterm img {
    width: 120px;
    aspect-ratio: 3 / 3;
    object-fit: contain;
}
.imageterm {
  display: block;
  padding: -6px 20px;
  width: 20%;
  text-align: center;
}
.underdetails{
    width: 60%;
}
.details {
  display: flex;
  align-items: start;
  justify-content: space-between;

  padding: 0px 11px;
}

.termdescription{
  color: gray;
  font-size: 5pt;
}


.clamsterm{
    margin: 0 15px;
}

.clamsterm span {
  color: #0166b3;
  font-size: 9pt;
}
.clamsterm h3 {
  color: rgb(80, 85, 92);
  font-size: 9pt;
}



.filtermenubar{
  display: none;
}

.undercovermanagemnet {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
}
.planspage {
    background-color: #FAFCFF;
    padding: 10px 0px;
}

.buttontermdiv button {
  color: white;
  background-color: #0166B3;
  padding: 16px 28px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  width: 155px;
  font-size: 10pt;
}
.buttontermdiv {
  padding: 2px 13px;
}
.addonsdiv {
    color: green;
    background-color: rgba(58, 218, 58, 0.15);
    width: 187px;
    border-radius: 29px;
    padding: 11px 9px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 10px 0px;
}
.addonsdiv p{
    margin-bottom: 0rem;
}

.addon {
  background-color: transparent;
  border: 1px solid #c3bcbc;
  border-radius: 10px;
  padding: 7px 3px;
  font-family: 'Poppins';
  color: gray;
  font-weight: 500;
  cursor: pointer;
  width: 85%;
  transition: all 0.3s ease;
  animation: expand 0.3s ease forwards;
}


.nodatafounddiv {
  background-color: #ffffff;

  border-radius: 15px;
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 0 36px;
  text-align: center;
}

.nodatafounddiv h2 {
  color: #0166B3;
  font-size: 25pt;
}

  @keyframes expand {
    0% {
      transform: scale(0); 
      opacity: 0; 
    }
    100% {
      transform: scale(1); 
      opacity: 1; 
    }
  }

  .OpenAddon {
    border: 1px solid #ebebeb;
    border-radius: 10px;
    display: block;
    justify-content: center;
    align-content: center;
    padding: 11px 10px;
    cursor: pointer;
}


.OpenAddon span {
    margin-bottom: 0rem;
    color: #6c6a6a;
    font-size: 9pt;
}
.termhr
  {
    margin-top: 8px;
    margin-bottom: 13px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);

}
.closedivs {
    width: 98%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.addon ul li {
  color: #645e5e;
  font-size: 8pt;
  margin: 4px 0px;
  list-style: none;
}
.addon ul {
    margin-bottom: 0rem;
    margin-right: 0rem;
    padding: 0px 0px 0px 12px;
}
.cuseaddon {
    color: green;
    margin-left: 5px;
}
.Typeterm {
    color: #0166B3;
    font-weight: 600;
    margin: 0px 10px;
}
  .addondivsbuttonclose {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }
  .addondivsbuttonclose button {
    border: none;
    color: white;
    background-color: #0166B3;
    border-radius: 5px;
    width: 50%;
    margin: 10px 0px;
  }
  .detailspolicy {
    margin-top: 2px;
    margin-left: -2px;
}

  .detailspolicy .planename {
    font-size: 12pt;
    margin-bottom: 0px;
    color: #0166b3;
    font-weight: 500;
}
.detailspolicy .planespan {
  font-size: 9pt;
  color: gray;
}
.uderpolicy{
  margin-bottom: 10px;
}
.detailspolicy a {
  color: #14853a;
  background-color: #01b31414;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 10pt;
}
  .addtocompair {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
}
  .addtocompair p {
    margin-bottom: 0rem;
    margin-left: 8px;
    font-family: 'Poppins';
    color: gray;
    font-size: 9pt;
}

.addondivs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
}

.underplanepageterm {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: start;
}

.carasoulterm {
  width: 25%;
  padding: 0px 0px;
}
.carsouldivterm {
    width: 285px;
    background-color: #fdfdfd;
    margin: 13px auto;
    border-radius: 11px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 15px 5px;
}




.buttontermdiv button span{
    font-size: 9pt;
}

.filterbutton{
  display: none;
}


.carousel-container {
    /* Optional: Add any additional styling for the carousel container */
  }
  
  .custom-button-group {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px; /* Adjust padding as needed */
    box-sizing: border-box;
  }
  
  .custom-button {
    cursor: pointer;
    outline: none;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-button:hover {
    background-color: #ccc; /* Optional: Add hover background color */
  }
  @keyframes slideDown {
    from {
      top: -50px; /* Start position */
    }
    to {
      top: -16px; /* End position */
    }
  }
  .sticky{
    width: 100%;
    background-color: #ffffff;
    box-shadow: -1px 0px 8px -3px rgba(0, 0, 0, 0.75);
    margin: 15px auto;
    border-radius: 11px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px 49px;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 9999;
    top: -16px;
    animation: slideDown 0.3s ease forwards; 
  }

  .headingdivs {
    width: 80%;
    border-radius: 10px;
    justify-content: start;
    padding: 18px 23px;
    margin: auto;
    box-shadow: 0px 0px 7px -2px rgb(0 0 0 / 39%);
    display: flex;
    align-items: center;
}

.headingdivs p {
    font-family: 'Poppins';
    color: #0166B3;
    font-size: 12pt;
    font-weight: 500;
    margin-bottom: 0px;
}
.termplanes:hover {
    transform: translateY(-5px);
}
.lifecoverdiv {
  display: block;
  padding: 9px 8px;
  border: 1px solid #dfdddd;
  border-radius: 5px;
  /* margin: 0 7px; */
  position: relative;
  cursor: pointer;
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.popupdivsfilter {
  background-color: #ffffff;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 57px;
  right: 0;
  box-shadow: 0 0 48px 0 rgba(0, 0, 0, .12);
  padding: 5px 0px;
  border-radius: 14px;
  min-width: 236px;
  animation: slideDown 0.3s ease forwards;
  opacity: 1;
  transform: translateY(0);
  overflow: auto;
}
.buttondivs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
}

.termbuttondiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0px;
  padding: 10px 0px;
}
.termbuttondiv .applybtn {
  color: white;
  background-color: #0166B3;
  width: 48%;
  padding: 6px;
  border-radius: 6px;
}
.nonedisplay{
  display: none;
}
.inputdivs {
  margin: auto;
  width: 95%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #f5eeee;
}
.inputRound {
  border-radius: 50%;
  width: 20px;

  border: 4px solid #d9d9d9;
  display: block;
  margin-right: 5px;
}
.inputRoundactive{
  border-radius: 50%;
  width: 20px;

  border: 6px solid #0757c7;
  display: block;
  margin-right: 5px;
}
.lifecoverdiv .headerlabel {
  margin-bottom: 0rem;
  color: #606162;
  font-family: 'Poppins';
  font-size: 8pt;
}
.lifecoverdiv select {
    margin-top: -12px;
    margin-bottom: -4px;
    background-color: transparent;
    border: transparent;
    font-family: 'Poppins';
    font-size: 10pt;
    outline: none;
    font-weight: 500;
}

.lifecoverdiv select option {
    background-color: white;
    color: #333;
    padding: 10px 10px;
  }

  @media screen and (max-width: 996px){
    .filterterm {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 10px 10px;
      border-radius: 22px;
      margin-left: 32px;
  }
    .addondivs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
      margin: 10px auto;
  }
  .OpenAddon {
    border: 1px solid #bab2b2;
    border-radius: 10px;
    display: block;
    justify-content: center;
    align-content: center;
    padding: 11px 10px;
    cursor: pointer;
    width: 90%;
  }
  .detailspolicy a {
    color: #14853a;
    background-color: #01b3140d;
    border-radius: 5px;
    padding: 6px 12px;
    font-size: 10pt;
  }
  .buttontermdiv {
    padding: 2px 8px;
    /* border-left: 1px solid gray; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  }
  
  
  

  @media screen and (max-width: 931px) {
  .termdetailsdivs {
    display: flex;
    align-items: center;
    padding: 8px 0px;
}
.sticky hr{
  display: none;
}
  .filterterminsurence {
    display: none;
}
.sidebarArrow {
  color: rgb(182, 176, 176);
}

.Lifecover{
  width: 95%;
  margin: 10px auto;

}
.sticky {
  width: 95%;
  background-color: #ffffff;
  box-shadow: -1px 0px 8px -3px rgba(0, 0, 0, 0.75);
  margin: 15px auto;
  border-radius: 11px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px 11px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 9999;
  top: -9px;
  animation: slideDown 0.3s ease forwards;
}
  .underdetails {
    width: 95%;
    margin: auto;
}
.imageterm {
  display: flex;
  justify-content: space-around;
  padding: 3px 0px;
  width: 100%;
  text-align: center;
}
.details {
  display: block;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
}
.buttontermdiv {
  padding: 16px 13px;
  width: 90%;
  margin: auto;
}
.buttontermdiv button {
  color: white;
  background-color: #0166B3;
  padding: 16px 28px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
}
.detailspolicy {
  width: 90%;
  margin: auto;
}
.addon {
  background-color: transparent;
  border: 1px solid #c3bcbc;
  border-radius: 10px;
  padding: 7px 3px;
  font-family: 'Poppins';
  color: gray;
  font-weight: 500;
  cursor: pointer;
  width: 93%;
  margin: auto;
  transition: all 0.3s ease;
  animation: expand 0.3s ease forwards;
}

.userdetailsdivs hr {
  display: none;
}
.details {
  display: block;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
}
.undercovermanagemnet {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 18px 0px;
}
.addondivs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 31px;
}
.editpage {
  width: 80%;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 9999;
  box-shadow: 0px 2px 0px 7px rgba(0, 0.0 .0 .9);
  box-shadow: 0px -7px 0px 10555px rgb(34 32 32 / 35%);
  padding: 15px 0px;
  animation: slideInRight 0.3s forwards;
}
.rediogroups {
  width: 143px;

  border: 1px solid gray;
  border-radius: 5px;
}
.rediogroupsactive {
  width: 145px;

  border: 1px solid #2959B8;
  border-radius: 5px;
}

.filtermenubar {
  display: block;
  background-color: white;
  float: left;
  width: 80%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 9999;
  left: 20;
  right: 0;
  box-shadow: 0px -69px 408px 90px rgba(0, 0, 0, 0.9);
}
  }
  @media screen and (max-width: 901px){
    .imageterm {
      display: block;
      padding: 3px 0px;
      width: 100%;
      text-align: center;
    }
    .details {
      display: block;
      padding: 0 10px;
  }
    .termplanes {
      width: 95%;
      background-color: white;
      border-radius: 15px;
      display: block;
      box-shadow: 0px 0px 4px 0px #00000029;
      opacity: 0;
      transform: scale(0.5);
      animation: slideFromTop 0.5s ease forwards;
  }
  .OpenAddon {
    border: 1px solid #bab2b2;
    border-radius: 10px;
    display: block;
    justify-content: center;
    align-content: center;
    padding: 11px 10px;
    cursor: pointer;
    width: 99%;
  }
  .uderpolicy {
    margin-bottom: 10px;
    text-align: center;
  }
  .detailspolicy {
    width: 90%;
    margin: auto;
    text-align: center;
  }
  
  
  .undercovermanagemnet {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 18px 0px;
  }
  .carasoulterm {
  display: none;
  }
  .underplanes {
    width: 100%;
  }
  }


  @media screen and (max-width: 718px) {
    .details {
      display: block;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
  }
  .userdetailsdivs {
    width: 95%;
    background-color: #ffffff;
    box-shadow: -1px 0px 8px -3px rgb(0 0 0 / 30%);
    margin: 15px auto;
    border-radius: 11px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 21px 17px;
}

.buttontermdiv button {
  color: white;
  background-color: #0166B3;
  padding: 11px 28px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
  font-size: 11pt;
}
.addon {
  background-color: transparent;
  border: 1px solid #c3bcbc;
  border-radius: 10px;
  padding: 7px 3px;
  font-family: 'Poppins';
  color: gray;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  margin: auto;
  transition: all 0.3s ease;
  animation: expand 0.3s ease forwards;
}
.addon ul li {
  color: #645e5e;
  font-size: 9pt;
  margin: 4px 0px;
  list-style: none;
}
.termtdit {
  width: 95%;
  margin: 33px auto;
}
.userdetailsdivs {
  width: 97%;
  background-color: #ffffff;
  box-shadow: -1px 0px 8px -3px rgba(0, 0, 0, 0.75);
  margin: 1px auto;
  border-radius: 11px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 7px 11px;
}
.mobilefilter{
  display: flex;
  justify-content: end;
  align-items: center;
}
.detailspolicy .planename {
  font-size: 16pt;
  margin-bottom: 0px;
  color: #0166b3;
  font-weight: 500;
}
.termdetailsdivs p {
  margin: 0 10px;
  color: #424141;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10pt;
}
.editp {
  margin: 10px;
  font-weight: 500;
  font-size: 0.7rem;
  font-family: "Poppins";
}
.clamsterm span{
  font-size: 10pt;
}
button.editb {
  border: none;
  background: #0166b3;
  font-weight: 500;
  /* padding: 3px 10px; */
  border-radius: 3px;
  color: white;
  font-family: "Poppins";
  font-size: 10pt;
  /* width: 90%; */
}
.imageterm img {
  width: 76px;
  aspect-ratio: 3 / 3;
  object-fit: contain;
}
.buttontermdiv {
  padding: 8px 0px;
  width: 95%;
  margin: auto;
}
.details {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.OpenAddon {
  border: 1px solid #ebebeb;
  border-radius: 10px;
  display: block;
  justify-content: center;
  align-content: center;
  padding: 4px 10px;
  cursor: pointer;
}
.addondivs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
}
.termdetailsdivs {
  display: flex;
  align-items: center;
  padding: 3px 0px;
}
.underplanepageterm {
  width: 97%;
  margin: auto;
  display: flex;
  justify-content: start;
}

.imageterm {
  display: flex;
  padding: 3px 0px;
  width: 100%;
  text-align: center;
}

  }