.headeradvice {
    width: 90%;
    margin: auto;
    /* padding: 15px 14px; */
    padding-left: 0px;
    max-width: 1200px !important;
}
.headertextsee h3 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #ed3543;
    letter-spacing: 7.4px;
    background: #ed35431a 0% 0% no-repeat padding-box;
    border-radius: 0.45rem;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.headertextsee p {
    color: rgb(95, 93, 93);
    font-family: "Poppins";
    margin-top: 14px;
}
.contextsguide img{
 width: 380px;
 object-fit: contain;
 aspect-ratio: 3/2;
}
.undertextcontext li {
    font-family: "Poppins";
    font-size: 13pt;
    margin: 15px 0;
    list-style-type: square;
    color: rgb(95, 93, 93);
}
.undertextcontext b{
    color: #2959B8;
    font-weight: 500;
}
.titlecolor {
    color: #2959B8;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 13pt;
}
.underimagecontext{
 display: flex;
 justify-content: center;
 align-items: center;
}
.undertextimages form {
    width: 95%;
    background-color: #f2f5fb;
    border-radius: 15px;
    margin: 0px auto;
    padding: 15px 9px;
}
.undertextimages form p {
    margin-bottom: 0rem;
    color: #000000;
    font-weight: 400;
    border: none;
    font-size: 12pt;
    margin: 3px 8px;
    font-family: 'Poppins';
}
.undertextimages form select {
    margin-bottom: 0rem;
    color: gray;
    outline: none;
    width: 100%;
    border: none;
    background-color: white;
}
.margincss{
    margin: 60px auto;
}

.inputTag {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: none;
    outline: none;
    /* padding: 0px 5px; */
    padding: 0px 10px;
    margin: 5px auto;
}

.inputTagMassage {
    width: 100%;
    height: 72px;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 12px 12px !important;
    margin: 5px 0px;
}
.headingclass {
    color: #2959B8;
    font-size: 22pt;
    font-weight: 600;
}
.inputfild {
    margin: 5px 0px;
}
.inputfild select{
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #9ea4b1;
    outline: none;
    padding: 0px 5px;
}
.captchacode {
    width: 75%;
    border-radius: 5px;
    height: 53px;
    padding: 0px 9px;
    border: none !important;
}
.headingfild{
    text-align: center;
}
.headingfild h3 {
    color: #2959B8;
    margin: 15px 0px;
    margin: 5px 0px;
    font-family: sans-serif;
    font-weight: 600;
}
.inputfild button {
    color: white;
    background-color: #2959B8;
    border: none;
    padding: 11px 15px;
    border-radius: 6px;
    width: 100%;
    margin: 10px 0px;
}
.underimagecontext img{
    width: 50%;
    text-align: center;
}
.underimagesdivs{
    display: flex;
    justify-content: center;

}
.parentformdivs{
    width: 50%;
    position: relative;
}
.underimagesdivs img{
    width: 450px;
    position: absolute;
}
.underflexdivs {
    display: flex;
    justify-content: start;
}

.underflexdivs img {
    width: 223px;
    height: 328px;
    position: absolute;
    right: -0;
    left: 512px;
    bottom: 205px;
}
.buttonadvicecapta {
    width: 48px;
    height: 28px;
    margin-right: 10px;
}
.captcha-divadive {
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    background-color: #17a2b82b;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 15px;
    /* color: red; */
}

@media screen and (max-width:1200px) {

    .headertextsee h3 {
      font-size: 30px!important;
    }
  
  }



@media screen  and (max-width: 1100px){
    .underflexdivs img {
        width: 223px;
        height: 328px;
        position: absolute;
        right: -0;
        left: 465px;

        bottom: 205px;
    }
}
@media screen and (max-width: 1085px) {
    .underflexdivs img {
display: none;
    }
}

@media screen  and (max-width: 896px){
    .parentformdivs {
        width: 100%;
        position: relative;
        padding: 15px 0px;
    }
}

.underfaqs {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.underfaqs li{
    list-style-type: square;
}
.undertextimages {
    align-items: start;
    display: flex;
    justify-content: start;
    width: 100%;
    margin-top: 35px;
}
.dropdowntext{
    font-family: "Poppins";
    font-size: 12pt;
    margin: 15px 0;
    list-style-type: square;
    color: rgb(95, 93, 93);
}
.advicefromexpertformbottom {
    width: 100%;
    display: flex;
}

@media screen and (max-width: 820px) {
    .headeradvice {
        width: 100%;
        margin: 15px auto;
        padding: 15px 14px;
    }

.underflexdivs {
    display: block;
}
.underfaqs {
    width: 100%;
}
.undertextimages {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
}
.headertextsee h3 {
    background-color: #f96766;
    color: aliceblue;
    font-family: "Poppins";
    margin: 10px 0px;
    font-weight: 700;
    /* font-size: 24pt; */
    font-size: 34px;
    width: 100%;
    padding: 14px 0px;
    text-align: center;
    border-radius: 5px;
}
}


  
  @media screen and (max-width:760px) {
  
    .headertextsee h3 {
      font-size: 27px!important;
    }
  
  }
  @media screen and (max-width: 600px){
    .margincss {
        margin: 30px auto;
    } 
    .headingclass {
        color: #2959B8;
        font-size: 18pt;
        font-weight: 600;
    }

    .advicefromexpertformbottom {
        width: 100%;
    }
    .captchacode {
        width: 98%;
        /* border-radius: 5px; */
        height: 56px;
        padding: 0px 9px;
        border: none !important;
    }
    .inputfild button {
        color: white;
        background-color: #2959B8;
        border: none;
        padding: 15px 15px;
        border-radius: 6px;
        width: 100%;
        margin: 10px 0px;
    }
    .captcha-divadive {
        border: 1px solid gray;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        border-radius: 8px;
        margin-bottom: 18px;
    }
}