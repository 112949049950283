
@keyframes slideUp {
    from {
      transform: translateY(100%); /* Start below the viewport */
    }
    to {
      transform: translateY(0); /* End at the final position */
    }
  }
  
  .policybord {
    width: 100%;
    height: 251px;
    background-color: #ffffff;
    border-radius: 48px 48px 0px 0px;
    box-shadow: 2px 2px 39px -18px rgba(0, 0, 0, 0.9);
    position: fixed;
    bottom: -147px; /* Initial position, before animation */
    margin: 0 auto;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    animation: slideUp 0.5s ease-out forwards; /* Apply animation */
  }
  .policybox span {
    font-weight: 500;
    font-size: 13px;
    font-family: 'Poppins';
    color: #0166b3;
    margin: 0px 7px;
}
  

@media screen and (max-width: 1018px){
    .policybox span {
        font-weight: 500;
        font-size: 13px;
        font-family: 'Poppins';
        color: #0166b3;
        margin: 0px 7px;
    }
}


@media screen and (max-width: 762px) {
    .policybord{
        display: block;
        border-radius: 17px 17px 0px 0px;
        height: 562px;
    }

}

.policybox{
    width: 240px;
    height: 68px;
    background-color: #ffffff5c;
    position: relative;
    top: 18px;
    border: 1px dotted #a3a1a1;
    border-radius: 12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 19px;
}
@media screen and (max-width: 762px) {

    .policybox {
        height: 68px;
        width: 90%;
        background-color: #ffffff5c;
        position: relative;
        top: 18px;
        border: 1px dotted #a3a1a1;
        border-radius: 12px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 auto;
    }
}
.policybox img{
    width: 65px;
    background: transparent;
    aspect-ratio: 3/2;
    object-fit: contain;
}


.applybutton{
    width: 139px;
    height: 48px;
    background-color: #fba71b;
    color: white;
    position: relative;
    top: 30px;
    border: none;
    border-radius: 7px;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 0 15px;
}
.Deactiveapplybutton{
    background-color: #ebc076;
 width: 139px;
    height: 48px;
    color: white;
    position: relative;
    top: 30px;
    border: none;
    border-radius: 7px;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 0 15px;
}
.cancelldbutton{
    width: 109px;
    height: 43px;
    background-color: transparent;
    color: #0055a5;
    position: relative;
    top: 32px;
    border: none;
    border-radius: 7px;
    font-family: 'Poppins';
    font-weight: 600;
}
.vsdiv{
    border: 1px solid #cbbcbc;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 36px;
    color: #918989;
}

@media screen and (max-width:762px) {
    .vsdiv {
        border: 1px solid #cbbcbc;
        width: 48px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 20px;
        color: #918989;
        margin: 10px auto;
    }
    .applybutton{
        width: 139px;
        height: 48px;
        background-color: #fba71b;
        color: white;
        position: relative;
        top: 30px;
        border: none;
        border-radius: 7px;
        font-family: 'Poppins';
        font-weight: 600;
        margin: 14px 29px;
    }
    .Deactiveapplybutton{
        background-color: #ebc076;
     width: 139px;
        height: 48px;
        color: white;
        position: relative;
        top: 30px;
        border: none;
        border-radius: 7px;
        font-family: 'Poppins';
        font-weight: 600;
        margin: 14px 29px;
    }
    .cancelldbutton{
        width: 109px;
        height: 43px;
        background-color: transparent;
        color: #0055a5;
        position: relative;
        top: 32px;
        border: none;
        border-radius: 7px;
        font-family: 'Poppins';
        font-weight: 600;
    }
}





@keyframes slideIn {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: 250px; 
      opacity: 1;
    }
  }




  /* Singel Policy Compare Page CSS start hear */


  .poilicyheader {
    width: 1341px;
    background-color: #E5F2FE;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5px auto;
    border-radius: 8px;
}

.mainpolicy {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5F2FE;
    position: sticky;
    top: 0px;
    margin-bottom: 17px;
    margin-top: 10px;
}

.comapirChart {
    width: 1169px;
    background-color: white;
    margin: auto;
    margin-bottom: 15px;
    border-radius: 15px;
    border-radius: 14px;
    padding: 10px 0;
}
.lebealhead {
    width: 265px;
    height: 150px;
    padding: 10px 0;
    background-color: #E5F2FE;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lebealhead h4 {
    font-weight: 500;
    font-size: 15px;
    font-family: 'Poppins';
    color: #0166b3;
}
.lebealhead span{
    font-size: 14px;
    color: gray;
}
.policycart {
    width: 254px;
    padding: 7px 0;
    background-color: #E5F2FE;
    border: 1px solid #80808017;
    border-radius: 8px;
}
.policyimage {
    width: 43%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    background-color: white;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #80808057;
}
.policyimage img{
    width: 84px;
    aspect-ratio: 3 / 2;
    object-fit: contain;
}
.policyname{
    text-align: center;
}
.policyname span {
    font-size: 11pt;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 5px 0;
    color: #0166b3;
}
.policyamount{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.boldpolicyamount {
    font-weight: 600;
    color: #0166b3;
    font-size: 11pt;
    font-family: 'Poppins';
}
.smallpolicyamount{
    font-size: 8pt;
    font-family: 'Poppins';
    color: gray;
}

.blockamount{
display: block;
}
.buttondiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 11px 0; */
    margin-top: 13px;
}
.buttondiv button{
    color: white;
    background-color: #0166b3;
    border: none;
    width: 90%;
    height: 37px;
    font-family: 'Poppins';
    border-radius: 4px;
}
.buttondiv a{
    color: #0055a5;
}

.plandes {
    font-size: 8pt;
    /* margin-bottom: 12px; */
    color: #6f6e6e;
    margin-bottom: 0;
}
.policycartadddata{
    width: 254px;
    height: 205px;
    padding: 10px 0;
    background-color: #f0fdff;
    border-radius: 21px;
    border: 2px dotted #beb7b7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.policycartadddata span{
    color: gray;
    font-family: 'Poppins';

}

.chartstart {
    width: 100%;
    padding: 26px 0px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #8080805c;
}
.mainnargin-insurance-policypage {
    padding-top: 74px;
}

.compair {
    width: 254px;
    padding: 13px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: white;
    text-align: center;
}
.compair p {
    color: #5b5a5a;
    font-family: 'Poppins';
    font-size: 11pt;
    margin-bottom: 0rem;
}
.bottomchart{
    width: 254px;
    height: 205px;
    padding: 10px 0;
}
.LebalChart{
    width: 265px;
    padding: 10px 14px;

}
.LebalChart p {
    font-size: 10pt;
    font-weight: 500;
    font-family: 'Poppins';
    color: #524f4fd1;
    margin-bottom: 0rem;
}
.nodatafound {
    color: #524f4f;
    font-size: 8pt;
    font-family: 'Poppins';
}
.compair button{
    color: white;
    background-color: #0166b3;
    border: none;
    font-family: 'Poppins';
    padding: 10px 31px;
    border-radius: 4px;
}
.mobiltag{
    display: none;
}
@media screen and (max-width: 762px) {
 
    .poilicyheader {
        width: 100%;
        height: 254px;
        background-color: #F0FDFF;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 10px auto;
        border-radius: 8px;
    }
 

.policyimage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    background-color: white;
    border-radius: 6px;
    padding: 12px 0;
    /* border: 1px solid #80808057; */
}
    .LebalChart p {
        font-size: 9pt;
        font-weight: 600;
        font-family: 'Poppins';
        color: #524f4f;
    }
    .comapirChart {
        width: 100%;
        background-color: rgb(222 251 255);
        margin: auto;
        margin-bottom: 15px;
        border-radius: 15px;
        border-radius: 14px;
        padding: 10px 0;
    }
    .policycart {
        width: 32%;
        padding: 2px 0;
        background-color: #E5F2FE;
        border-bottom: 1px solid #8080803d;
        border: none;
    }
    .compair {
        width: 254px;
        height: 127px;
        padding: 13px 0;
        justify-content: center;
        /* align-items: center; */
        display: flex;
        background-color: white;
        border-right: 1px solid #8080803d;
        text-align: center;
        overflow: hidden;
    }
    .policyamount {
        display: block;
        text-align: center;

    }

.plandes {
    font-size: 8pt;
    margin-bottom: 0px;
    color: #6f6e6e;
}
    .policyname span {
        font-size: 10pt;
        font-weight: 600;
        font-family: 'Poppins';
        margin: 16px 0;
        text-align: center;
    }
    .LebalChart {
        display: none;
    }
    .lebealhead {
      display: none;
    }
    .mobiltag {
        display: block;
        margin: 5px 4px;
        border-radius: 5px;
        color: rgb(248, 249, 251);
        padding: 8px 43px;
        text-align: center;
        background-color: #0166b3;
        font-weight: 500;
    }



            .policycartadddata {
                width: 254px;
                height: 205px;
                padding: 10px 0;
                background-color: #f0fdff;
                border-radius: 21px;
                border: 2px dotted #beb7b7;
                display: none;
                justify-content: center;
                align-items: center;
            }
}


@media screen and (max-width: 1124px){
    .poilicyheader {
        width: 100%;
        height: 303px;
        background-color: #E5F2FE;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px auto;
        border-radius: 8px;
    }
    .comapirChart {
        width: 100%;
        background-color: white;
        margin: auto;
        margin-bottom: 15px;
        border-radius: 15px;
        border-radius: 14px;
        padding: 10px 0;
    }
}
@media screen and (max-width: 500px){
    .plandes {
        font-size: 0pt;
        margin-bottom: 0px;
        color: #6f6e6e;
    }
}