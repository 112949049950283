.curved {
    width: 95%;
    height: 328px;
    border-radius: 0px 39px 33px 0px;
    border-left: none;
    position: relative;
    margin: auto;
    background-color: white; /* Optional: Background color */
}

.curved::before {
    content: '';
    position: absolute;
    top: -17px; /* Match the border width */
    left: 0; /* Match the border width */
    right: -17px; /* Match the border width */
    bottom: -17px; /* Match the border width */
    border-radius: inherit; /* Keep the same border radius */
    background: linear-gradient(to right, #0166b3, #0166b3, rgba(232, 20, 20, 0.518)); /* Gradient border */
    z-index: -1; /* Place behind the main content */
}













.curved2 {
    width: 58%;
    height: 328px;
    border: 17px solid #0166b3;
    border-top: 2px;
    border-radius: 0px 22px 4px 33px;
    border-right: none;
}

.step1 {
    text-align: center;
    position: absolute;
    left: -57px;
    top: -31px;
    display: flex;              
    flex-direction: column;      
    align-items: center;       
    justify-content: center;  
    transition: 0.3s;   
    cursor: pointer;
}
.step1:hover{
    text-align: center;
    position: absolute;
    left: -57px;
    top: -36px;
    display: flex;              
    flex-direction: column;      
    align-items: center;       
    justify-content: center;  

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px;   
}


.description {
    display: none;
    position: absolute;
    top: 129px;
    width: 528px;
    background: white;
    border: 1px dashed #0166b3;
    padding: 10px;
    border-radius: 18px;
    z-index: 99999;
    color: rgb(122 119 119);
    font-family: 'Poppins';
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
}


/* Show description on hover */
.step1:hover .description {
    display: block; /* Show description on hover */
}
.step2:hover .description {
    display: block; /* Show description on hover */
}
.step3:hover .description {
    display: block; /* Show description on hover */
}
.step4:hover .description {
    display: block; /* Show description on hover */
}
.step5:hover .description {
    display: block; /* Show description on hover */
}
.step6:hover .description {
    display: block; /* Show description on hover */
}
.step7:hover .description {
    display: block; /* Show description on hover */
}
.step8:hover .description {
    display: block; /* Show description on hover */
}
.step9:hover .description {
    display: block; /* Show description on hover */
}
.step2 {
    text-align: center;
    position: absolute;
    left: 227px;
    top: -31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
}
.step2:hover{
    text-align: center;
    position: absolute;
    left: 227px;
    top: -36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}



.step3 {
    text-align: center;
    position: absolute;
    left: 535px;
    top: -31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
}



.step3:hover{
    text-align: center;
    position: absolute;
    left: 535px;
    top: -35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}











.step4 {
    text-align: center;
    position: absolute;
    left: 836px;
    top: -31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    transition: 0.3s;
}

.step4:hover{
    text-align: center;
    position: absolute;
    left: 836px;
    top: -39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}




.step5 {
    text-align: center;
    position: absolute;
    left: 1029px;
    top: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}


.step5:hover{
    text-align: center;
    position: absolute;
    left: 1029px;
    top: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}






.step6 {
    text-align: center;
    position: absolute;
    right: 76px;
    top: 316px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: 0.3s;
}


.step6:hover{
    text-align: center;
    position: absolute;
    right: 76px;
    top: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}



.step7 {
    text-align: center;
    position: absolute;
    right: 378px;
    top: 315px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    transition: 0.3s;
}

.step7:hover{
    text-align: center;
    position: absolute;
    right: 378px;
    top: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}



.step8 {
    text-align: center;
    position: absolute;
    right: 703px;
    top: 313px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    transition: 0.3s;
}


.step8:hover{
    text-align: center;
    position: absolute;
    right: 703px;
    top: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}







.step9 {
    text-align: center;
    position: absolute;
    right: 1039px;
    top: 314px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    transition: 0.3s;
}


.step9:hover{
    text-align: center;
    position: absolute;
    right: 1039px;
    top: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    background: white;
    border: 1px dashed #0166b3;
    padding: 13px 10px;
    border-radius: 14px; 
}

.medicalnumber {
    color: #0166b3;
    background-color: #ffffff;
    border-radius: 50%;
    width: 44px;
    border: 2px solid #0166b3;
    font-size: 20pt;
    font-family: 'Poppins';
    font-weight: 600;
    margin-bottom: 13px;
}
.medicalHeading {
    color: #0166b3;
    font-family: 'Poppins';
    font-size: 12pt;
    font-weight: 600;
    background-color: white;
}