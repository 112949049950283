.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
  width: 87%;
}
  .inputGroup label {
    padding: 13px 24px;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.onebox{
  background-color: red;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  margin: 10px auto;
  
}








  .inputGroup label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    background-color: #1851ce;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    opacity: 0;
    z-index: -1;
   
  }
  
  .inputGroup label:after {
    width: 32px;
    height: 32px;
    content: '';
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
  }
  
  .inputGroup input:checked ~ label {
    color: #fff;
  }
  
  .inputGroup input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
  }
  
  .inputGroup input:checked ~ label:after {
    background-color: rgb(253, 146, 2);
    border-color: rgb(253, 146, 2);
  }
  
  .inputGroup input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
  .margindivs{
    margin: 30px auto;
  }

.whatsappbtn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.whatsappbtn p{
  margin-bottom: 0rem;
  margin-left: 10px;
}


  .sliding-toggle {
    position: relative;
    width: 57px;
    height: 27px;
    background-color: #ccc;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
  .sliding-toggle.active {
    background-color: #139d25;
  }
  .toggle-circle {
    position: absolute;
    top: 6%;
    left: 2px;
    width: 23px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
}
  .sliding-toggle.active .toggle-circle {
    transform: translateX(30px);
  }
  .flexdisplayhealthstep4{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 1024px){
    .inputGroup label {
      padding: 12px 30px;
      width: 100%;
      display: block;
      text-align: left;
      color: #3c454c;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      font-size: 10pt;
  }
  }

@media screen and (max-width: 950px){
  .inputGroup label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 9pt;
}

}

@media screen and (max-width: 896px){
  .inputGroup label {
    padding: 12px 0px;
    padding-left: 11px;
    width: 100%;
    display: flex;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: left;
    /* text-align: left; */
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 13pt;
    padding-left: 7px;
}
}

  
@media screen  and (max-width: 600px){
  .inputGroup label {
    
    padding: 12px 0px;
    padding-left: 11px;
    width: 100%;
    display: flex;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: left;
    /* text-align: left; */
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 11pt;
    padding-left: 7px;
}
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
  width: 95%;
}
  .inputGroup label:after {
    width: 32px;
    height: 32px;
    content: '';
    border: 2px solid #d1d7dc;
    background-color: #fff;
    /* background-image: url(data:image/svg+xml,%3Csvg width=%2732%27 height=%2732%27 viewBox=%270 0 32 32%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z%27 fill=%27%23fff%27 fill-rule=%27nonzero%27/%3E%3C/svg%3E); */
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
}
.inputGroup label {
  padding: 15px;
  width: 100%;
  height: 67px;
  /* display: block; */
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
}