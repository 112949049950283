.hide{
    display: none;
}
.boxusingcar{
    width: 220px;
    height: 120px;
    border-left: 1px dotted red;
    border-bottom: 1px dotted red;
}
.quots_car_listtag {
    margin-bottom: 10px;
    font-weight: 500;
    color: #706464;
}

.compare_quotes_stepper {
    font-family: 'Arial', sans-serif;
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .compare_quotes_stepper_header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .compare_quotes_stepper_header h2 {
    font-size: 28px;
    color: #333;
  }
  
  .compare_quotes_stepper_content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .compare_quotes_stepper_title h3 {
    font-size: 22px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .compare_quotes_stepper_content p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
  }
  
  .compare_quotes_stepper_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .compare_quotes_stepper_button {
    padding: 12px 30px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .compare_quotes_stepper_button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
  
  .compare_quotes_stepper_button:hover {
    background-color: #0056b3;
  }
  
  .compare_quotes_stepper_navigation {
    margin-top: 30px;
    text-align: center;
  }
  
  .compare_quotes_stepper_navigation ul {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }
  
  .compare_quotes_stepper_navigation_item {
    margin: 0 10px;
    /* padding: 10px 15px; */
    background-color: #f1f1f1;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .compare_quotes_stepper_navigation_item.active {
    background-color: #007bff;
    color: white;
  }
  
  .compare_quotes_stepper_navigation_item:hover {
    background-color: #007bff;
    color: white;
  }
  /* Container for the stepper */
.roadmap_stepper {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .roadmap_stepper_header {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .roadmap_stepper_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .roadmap_stepper_steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .roadmap_stepper_step {

    position: relative;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .roadmap_stepper_step:hover {
    transform: scale(1.05);
  }
  
  .roadmap_stepper_step_circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
    margin: 10px auto;
}
  
.roadmap_stepper_step_title {
    font-size: 14px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}
.quotslist_tag {
    margin: 15px 0px;
    font-weight: 500;
}
  
  .roadmap_stepper_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
  }
  
  .modal_content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .modal_content p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .close_modal {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close_modal:hover {
    background-color: #0056b3;
  }
  .comparequots_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .comparequots_section {
    width: 48%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .comparequots_title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }
  
  .comparequots_list {
    list-style-type: none;
    padding-left: 0;
    margin-top: 10px;
  }
  
  .comparequots_list li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.5;
    position: relative;
  }
  
  .comparequots_list li::before {
    content: "\2022";
    color: #007bff;
    font-size: 1.5rem;
    position: absolute;
    left: -20px;
    top: 0;
  }
  
  .comparequots_list li:last-child {
    margin-bottom: 0;
  }
  .car_renualHeading{
    color: #0056b3;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 15pt;
  }
  .car_renuwalList_ul {
    list-style-type: none;
    padding: 0;
  }
  .car_renuwalList_li {
    font-size: 16px;
    line-height: 1.8;
    background-color: #f3f4f6;
    padding: 10px;
    margin-bottom: 10px;
    border-left: 4px solid #3498db;
    border-radius: 4px;
  }
  .car_renuwalList_li:hover {
    background-color: #e9f5ff;
  }
  
  @media (max-width: 768px) {
    .comparequots_wrapper {
      flex-direction: column;
      align-items: center;
    }
    .comparequots_section {
      width: 90%;
      margin-bottom: 20px;
    }
  }
  