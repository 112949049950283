.logo-scroller-container {
  overflow: hidden;
  width: 100%;
}

.logo-scroller {
  display: flex;
  animation: slider 6s  infinite;
  animation-play-state: running;
}

/* Pause animation on hover */
.logo-scroller-container:hover .logo-scroller {
  animation-play-state: paused;
}

.logo-scroller .partner-company-image {
  max-height: 80px;
  margin-left: 55px;
  object-fit: contain;
}





@keyframes slider {
  0% {
    transform: translateX(100%);
  }
 
  
  50% {
    transform: translateX(-100%);
  }
  50%{
    transform: translateX(100%);
  }
}
@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  display: flex;
  overflow: hidden;
  padding: 30px 0px;
  white-space: nowrap;
  position: relative;
  align-items: center;
}

.logos:before, .logos:after {
  position: absolute;
  top: 0;
  content: '';
  width: 250px;
  height: 100%;
  z-index: 2;
}



.logo_items {
  display: inline-flex;
  animation: 38s slides infinite linear;
}

.logos:hover .logo_items {
  animation-play-state: paused;
}
.partner-company-image {
  height: 107px;
  margin: 0 15px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
  border: 1px dashed #80808085;
  border-radius: 7px;
  padding: 10px;
}


