.stateline {
    width: 90%;
    height: 9px;
    background-color: #0166b3;
    border-radius: 31px;
    border: 1px solid #0166b3;
    position: relative;
    margin: auto;
}



.headingone {
    position: absolute;
    bottom: -55px;
    left: -168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
}
.headingtwo {
    position: absolute;
    bottom: -234px;
    left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
}


.headingthree {
    position: absolute;
    bottom: -52px;
    left: 253px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
}

.headingfour {
    position: absolute;
    bottom: -234px;
    left: 452px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
}


.headingfive {
    position: absolute;
    bottom: -53px;
    left: 657px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
}


.headingsix {
    position: absolute;
    bottom: -235px;
    left: 860px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
}
.disc{
    width: 100%;
    height: 20px;
    background-color: #0166b3;

}

.Discription {
    border-radius: 18px;
    padding: 14px 13px;
    width: 365px;
    height: 157px;
    margin-bottom: 3px;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    color: gray;
    font-family: 'Poppins';
    z-index: 9999;
    font-size: 10pt;
    align-content: center;
}


.line {
    height: 59px;
    width: 3px;
    background-color: #fca84c;
    border-radius: 16px;
}

.count {
    width: 40px;
    height: 40px;
    background-color: #ffc107;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #0166b3;
    font-family: 'Poppins';
    font-weight: 600;
    color: #0166b3;
    margin-bottom: 13px;
}
.count2{
    width: 40px;
    height: 40px;
    background-color: #ffc107;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #0166b3;
    font-family: 'Poppins';
    font-weight: 600;
    color: #0166b3;
    margin-top: 13px;
}
.discriptionname{
    color: #0166b3;
    font-weight: 600;
    font-family: 'Poppins';
    background-color: white;
}

.headingonemob{
    display: none;
}
.headingtwomob{
    display: none;
}
.headingthreemob{
    display: none;
}
.headingfourmob{
    display: none;
}
.headingfivemob{
    display: none;
}
.headingsixmob{
    display: none;
}
.stepmargin {
    /* height: 458px; */
    margin: 257px auto;
}
.blueline {
    width: 39%;
    height: 2px;
    background-color: #fca84c;
    border-radius: 5px;
    margin-top: 10px;
}
.mobilescreenone{
    display: none;
}
@media screen and (max-width: 1164px){
    .headingone{
        display: none;
    }
    .headingtwo{
        display: none;
    }
    .headingthree{
        display: none;
    }
    .headingfour{
        display: none;
    }
    .headingfive{
        display: none;
    }
    .headingsix{
        display: none;
    }
    .stepmargin {
        margin: 20px auto;
    }
    
    
    .stateline {
        width: 10px;
        height: 1098px;
        background-color: #0166b3;
        border-radius: 31px;
        border: 1px solid #0166b3;
        position: relative;
        margin: auto;
    }
    .line {
        height: 3px;
        width: 56px;
        background-color: #2959b8;
        border-radius: 16px;
    }
    .headingonemob {
        position: absolute;
        bottom: 973px;
        left: -431px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        z-index: 1;
    }
    .headingtwomob {
        position: absolute;
        bottom: 724px;
        left: -61px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        z-index: 1;
    }
    .headingthreemob {
        position: absolute;
        bottom: 550px;
        left: -423px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        z-index: 1;
    }
    .headingfourmob {
        position: absolute;
        bottom: 334px;
        left: -61px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        z-index: 1;
    }
    .headingfivemob {
        position: absolute;
        bottom: 153px;
        left: -423px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        z-index: 1;
    }
    .headingsixmob {
        position: absolute;
        bottom: -49px;
        left: -61px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        z-index: 1;
    }






    .blockone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .line {
        height: 3px;
        width: 55px;
        background-color: #2959b8;
        border-radius: 16px;
    }
 
}


@media screen and (max-width:831px){
    .headingonemob{
        display: none;
    }
    .headingtwomob{
        display: none; 
    }
    .headingthreemob{
        display: none;  
    }
    .headingfourmob{
        display: none;  
    }
    .headingfivemob{
        display: none;     
    }
    .headingsixmob{
        display: none;  
    }
    .stateline{
     display: none;
    }
    .mobilescreenone {
        margin: 14px 0px;
        display: block;
    }
    .mobilediscription {
        width: 95%;
        background-color: white;
        box-shadow: 0 6px 16px #0166b333;
        border-radius: 18px;
        padding: 12px;
    }
    .mobilediscription span {
        color: gray;
        font-size: 11pt;
        font-family: 'Poppins';
    }
    .blockmob{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .countmobile
        {
            color: #0166b3;
            font-family: 'Poppins';
            border: 3px solid;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
        
    }
    .mobilename {
        color: #fff;
        font-weight: 600;
        margin-top: 7px;
        background-color: #0166b3;
        padding: 5px 8px;
        border-radius: 5px;
    }
    .mobileline {
        width: 4px;
        height: 35px;
        background-color: #0166b3;
        border-radius: 1px;
    }


}