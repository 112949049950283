.calculatemain{
    width: 90%;
    /* background-color: red; */
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 20px;
}
.cardcalculator {
    height: 200px;
    border: 1px solid rgb(140, 141, 143);
    margin: 5px 0;
    border-radius: 5px;
    transition: 0.3s;
    overflow: hidden;
}
.cardcalculator:hover{
    border: 1px solid rgb(1 102 179);
    box-shadow: 0px 0px 1px 0px rgb(1 102 179);
    z-index: 999;
    background-color: white;
}

.imgcalculator {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 110px;
    padding: 0 15px;
}
.imgcalculator span{
    color: rgb(121, 116, 116);
    font-weight: 500;
}

.calculatediscription {
    width: 90%;
    height: 90px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 9pt;
    margin: 0 auto;
    color: rgb(94, 91, 91);
    border-top: 1px solid rgb(204, 198, 198);
    overflow: hidden;
}
.imgcalculator img{
    width: 120px;
   aspect-ratio: 3/2;
   object-fit: contain;

}
.maincalculator
{
    width: 75%;
    margin: 0 auto;
    padding: 0 10px;
    margin-top: 20px;
}
.maincalculator p{
    font-size: 10pt;
    color: gray;
}
.maincalculator .titlecalculators{
    font-size: 20pt;
    color: rgb(112, 108, 108);
    line-height: 2.4375rem;
    font-weight: 500;
}



@media screen and (max-width: 820px) {
    .imgcalculator img{
        width: 84px;
       aspect-ratio: 3/2;
       object-fit: contain;
    
    }
    .imgcalculator {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 110px;
        padding: 0 15px;
        overflow: hidden;
    }
}
@media screen and (max-width: 761px) {
    .imgcalculator img {
        width: 120px;
        aspect-ratio: 3 / 2;
        object-fit: contain;
    }
}