.upper-question-para {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  flex-direction: column;
  padding-left: 0px;
  flex: 0%;
}

/* .MuiStack-root .css-4jnixx-MuiStack-root {
  width: 30% !important;
  overflow: hidden !important;
} */

.lower-question-para {
  display: flex;
  justify-content: space-between;
  flex-direction: column
}

.hdfc-date-one {
  width: 100%;
  /* min-width: 200px; */
  margin-top: 10px;
}

.hdfc-date-one >div{
  overflow: hidden!important;
}

.setting-overflow{
  overflow: hidden!important;
}

.hdfc-select {
}

.hdfc-question-set {
  display: flex;
  /* flex-direction: row; */
  padding-left: 0px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hdfc-disease {
  flex: 0 0 0 !important;
}

.hdfc-select {
  font-size: 13px;
}

.hdfc-date {
  min-width: 0px !important;
  padding-top: 0px;
  height: fit-content;
}

.hdfc-date input {
  font-size: 13px !important;
  width: 100px !important;
  min-width: 87px !important;
}

.hdfc-date div {
  max-width: 100% !important;
}

.hdfc-date button {
  margin-left: -31px;
}

.hdfc-date svg {
  font-size: 20px;
}

.hdfc-select > div select {
  height: auto;
  width: 113px !important;
  font-size: 13px;
  text-wrap: wrap !important;
  min-width: 0px !important;
  line-height: 17px;
}

.hdfc-disease label {
  font-size: 13px;
}

.hdfc-treatment label {
  font-size: 13px;
}

.hdfc-addon{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 11px;
  min-width: 0px;
}

.addedClassHdfc{
  display: flex!important;
  flex-direction: row!important;
  padding: 6px!important;
  justify-content: space-around;
}

.hdfc-addon-para{
  width: fit-content;
  color: #424040;
  display: flex;
  font-family: Poppins;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
}

.test-form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;


  padding-bottom: 22px;
  margin: auto;

  border-radius: 6px;

  background: #fffbfb;

  box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
}

.test-input-field {
  width: 100%;
}

.test-date-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 7px;
  margin-bottom: -6px;
}

.test-date-container > div {
  border-bottom: 1px solid #837d7dd9;
  padding: 4px;
  border-radius: 0;
  padding-left: 0px;
}

.custom-label {
  font-size: 14px;
  color: #0357a6;
  font-weight: 500;
  margin-bottom: -9px;
}

.test-date-picker .MuiInputBase-input {
  padding: 6px;
  font-size: 14px;
  color: #575252;
  padding-left: 0px;
  padding-bottom: 0px;
}

.date-input-field {
  font-size: 14px !important;
  color: #575252 !important;

  border-bottom: none !important; /* Removes default border */
  padding: 4px;
}

.test-date-picker .MuiInputBase-input::placeholder {
  text-align: left !important;
}