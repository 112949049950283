.contect-banner-img img {
    max-width: 500px;
    transform: scaleX(-1);
}
.contract{
    color: rgb(73, 73, 241);
}
.captchainput{
    padding: 10px 5px;
    border-radius: 5px;
    width: 50%;
    border: 1px solid gray;
    outline: none;

}
.contractusimage {
    width: 66%;
    -webkit-transform: scaleX(-1);
}
@media screen and (max-width: 600px){
    .captchainput{
        padding: 10px 5px;
        border-radius: 5px;
        width: 100%;
        border: 1px solid gray;
        outline: none;
    
    }
}