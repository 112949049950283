.privecy {
    width: 100%;
    padding: 30px 0px;
    text-align: center;
}

.privecy h1{
    color: #2959B8;
    font-size: 30pt;
}
.privecy p{
    color: gray;
}
.privecyfooter{
    background-color: #2959b814;
    width: 99%;
    margin: 0 auto;

    border-radius: 15PX;
}
.privecyfooter .undertext{
    width: 80%;
    margin: 0 auto;
    padding: 15px 0;

}
.undertext h6 {
    color: #2959B8;
    /* font-weight: bold; */
    font-weight: 600px;
    font-size: 18pt;
    font-family: 'Poppins';
}

.undertext a {
    color: #2959B8;
    font-weight: 500;
    font-size: 13pt;
}