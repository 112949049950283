.userdataabddetails {
    width: 100%;
    background-color: #f0f8ff6b;
    display: flex;
    justify-content: center;
    padding: 33px 0px;
}
.userdetailsterm {
    width: 901px;
    background-color: white;
    border-radius: 17px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.9);
    padding: 20px 0px;
    margin: 10px 0px;
}
.colordivs {
    background-color: #f0f8ff6b;
    padding: 15px;
}
.headerlabel {
    color: #787474;
    font-size: 8pt;
    margin-bottom: 4px;
}

.underfilter{
    text-align: start;
    margin: 0 5px;
}
.usermargin{
    margin: 10px 0px;
}
.checkboxinput{
    margin: 0px 5px;
}
.colorflexdiv{
    color: gray;
}
.nextstpebutton{
    width: 100%;
    height: 50px;
    background-color: #0166B3;
    color: white;
    border: none;
    font-family: 'Poppins';
    border-radius: 5px;
    margin: 10px 0;
}
.nextstpebuttondeactive{
    width: 100%;
    height: 50px;
    background-color: #4996d1;
    color: white;
    border: none;
    font-family: 'Poppins';
    border-radius: 5px;
    margin: 10px 0;
}
.arrowimg{
    width: 20px;
}
.Brochure {
    background-color: white;
    height: 50px;
    width: 317px;
    margin: 10px auto;
    border-radius: 11px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.brouch {
    color: gray;
    font-size: 9pt;
    margin: 0 13px;
}
.undertextareadiv{
    width: 1100px;
    text-align: start;
    margin: auto;
}
.policydetailsterm {
    width: 318px;
    height: 337px;
    background-color: white;
    margin: 0 10px;
    border-radius: 12px;
    padding: 5px 5px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.9);
}
.descriptiontermplnae{
    font-size: 8pt;
    color: gray;
}
.inputcontainer {
    background-color: #F0F8FF;
    width: 99%;
    border-radius: 7px;
    border: none;
    outline: none;
    height: 45px;
    font-size: 10pt;
    color: #7a7575;
    padding: 0 12px;
}
.flexuserpoint{
    display: flex;
    justify-content: start;
    align-items: center;
}

.labelterm{
    color: #0166B3;
    font-size: 8pt;
}
.termcovers {
    width: 318px;
    height: 124px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.9);
    margin: 10px auto;
    background-color: white;
    border-radius: 15px;
    padding: 13px 16px;
}
.blockdivs{
    display: block;
}
.gstspan{
    font-size: 8pt;
    color: gray;
}
.moneyamount{
    font-size: 12pt;
    color: #0166B3;
    font-weight: 600;
}
.moneycovers {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.termcovertext{
    color: gray;
    font-size: 9pt;
}
.logoandname {
    display: block;

    text-align: center;
}
.logoandname img {
    width: 108px;
    aspect-ratio: 3 / 2;
    object-fit: contain;
    margin: 10px 0px;
}

.logoandname p {
 font-size: 10pt;
 color: #0166B3;
 font-weight: 500;
}
.coverandcoveruptoterm{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
}
.termsubdiv{
    text-align: center;
}
.termsubdiv p {
    font-size: 10pt;
    color: gray;
}
.termsubdiv span {
    color: #0166B3;
    font-size: 12pt;
    font-weight: 500;
    font-family: 'Poppins';
}

@media screen and (max-width: 1024px){
    .userdataabddetails {
        width: 100%;
        background-color: #f0f8ff6b;
        padding: 33px 0px;
    }
    
}
@media screen and (max-width: 800px){
    .userdataabddetails {
        width: 100%;
        background-color: #f0f8ff6b;
        padding: 33px 0px;
        display: block;
    }

.userdetailsterm {
    width: 100%;
    background-color: white;
    border-radius: 17px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.9);
    padding: 20px 0px;
    margin: 10px 0px;
}
.Brochure {
    background-color: white;
    height: 50px;
    width: 100%;
    margin: 10px auto;
    border-radius: 11px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.policydetailsterm {
    width: 100%;
    height: 337px;
    background-color: white;
    margin: 0 auto;
    border-radius: 12px;
    padding: 5px 5px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.9);
}
.termcovers {
    width: 100%;
    height: 124px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.9);
    margin: 10px auto;
    background-color: white;
    border-radius: 15px;
    padding: 13px 16px;
}

}

