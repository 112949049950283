.termgenderselect {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 120px;
    height: 58px;
    border: 2px solid #2959b8;
    border-radius: 15px;
    font-family: 'Poppins';
}
.deactive {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 120px;
    height: 58px;
    border: 2px solid gray;
    border-radius: 15px;
    -webkit-filter: grayscale(1);
    font-family: 'Poppins';
}
.termheading {
    color: #F96766;
    font-family: 'poppins' !important;
    border: 2px dotted #F96766;
    padding: 3px 8px;
    border-radius: 15px;
    font-size: 12pt !important;
    font-weight: 600 !important;
}
.undertermform h3{
    color: gray;
    font-size: 9pt;
}

.Labelform {
    font-family: 'Poppins';
    font-weight: 400;
    color: #6b6565;
    margin-bottom: 0rem;
    position: relative;
    top: 13px;
    left: 15px;
    background: white;
    font-size: 11pt;
    padding: 0px 5px;
}
.dobinput {
    font-family: 'Poppins';
    font-size: 10pt;
    height: 49px;
}
.txtOnly{
    height: 60px;
}
.termsconditiondiv{
    display: flex;
    justify-content: center;

}
.containerdivcaptcha{
    width: 90%;
}
.checkboxterm {
    font-size: 9pt;
    font-family: 'Poppins';
}
.checkboxterm a{
    color: #2959b8;
    font-family: 9pt;
}

.qutationbutton {
    color: white;
    background-color: #2959b8;
    padding: 10px 5px;
    border-radius: 5px;
    font-family: 'Poppins';
    width: 100%;
    border: none;
    font-weight: 500;
    margin: 10px auto;
}
.qutationbutton img{
    width: 20px;
}
.centeralinediv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
}
.termdivs {
    width: 1014px;
    height: 450px;
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.9);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.WelcomeMassageterm{
    font-family: 'Poppins';
    margin-bottom: 15px;
    color: #6b6565;
}
.WelcomeMassageterm b{
    color: #2959b8;
}
.termtext{
    color: rgb(101, 100, 100);
    font-family: 'Poppins';
}
.textterm{
    margin-bottom: 10px;
}
.advantagedisadbantegdiv {
    font-size: 15pt;
    height: 150px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 85px;
}
.advantagedisadbantegdiv h4 {
    color: #0055a5;
    font-size: 19pt;
}

.flexdivterm{
    display: flex;
}
.linktagreadmore{
    color: green;
}

/* New Row Start Hear */
@keyframes slideIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .payoutboxs {
    width: 100%;
    height: 352px;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #dc35454a;
    text-align: center;
}

.payoutboxs2 {
    width: 100%;
    height: 352px;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #ffc1074d;
    text-align: center;
}
.retureterm li {
    list-style: none;
    color: #0166b3;
    font-weight: 600;
    font-size: 13pt;
    margin: 26px 0;
}
.payoutboxs3 {
    width: 100%;
    height: 352px;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #007bff52;
    text-align: center;
}




  .underterminsurence {
    width: 1150px;
    padding: 10px 0px;
    margin: 0px auto;
    height: 585px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectgenderdivs {
    display: flex;
    align-items: center;
    justify-content: center;
}
.deactivegender {
    background-color: #dbdbdb;
    margin: 0 5px;
    width: 148px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    border-radius: 7px;
    font-family: 'Poppins';
    cursor: pointer;
    color: #585353;
    position: relative;

}
.activegender{
    background-color:#2959b8;
    margin: 0 5px;
    width: 148px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    border-radius: 7px;
    font-family: 'Poppins';
    color: white;
    transition: 0.5s ;
    cursor: pointer;
}
.smokeInput[type="checkbox"] {
    width: 27px;
    height: 28px;
    border-radius: 50%;
    margin-left: 10px;
    appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  /* Style the checkbox when checked */
  .smokeInput[type="checkbox"]:checked {
    background-color: rgb(253, 146, 2);
    color: white;
  }
  
  /* Style the checkbox's inner circle */
  .smokeInput[type="checkbox"]::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  /* Style the checkbox's inner circle when checked */
  .smokeInput[type="checkbox"]:checked::before {
    background-color: rgb(253, 146, 2);
    color: white;
  }
  
  /* Add the SVG image under the checked checkbox */
.smokeInput[type="checkbox"]:checked::after {
    content: "";
    display: block;
    width: 37px;
    height: 29px;
    position: absolute;
    top: 68%;
    left: 59%;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml,%3Csvg width=%2732%27 height=%2732%27 viewBox=%270 0 32 32%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z%27 fill=%27%23fff%27 fill-rule=%27nonzero%27/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    z-index: 1;
    /* padding: 15px 19px; */
}

.postiontermstep1{
    position: relative;
}

.undertermform {
    width: 60%;
    margin: auto;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.agedivterm{
    position: relative;
}

.Myage {
    color: #2959b8;
    background-color: #dde4eb;
    border-radius: 10px;
    margin-bottom: 0rem;
    width: 20%;
    text-align: center;
    font-family: 'Poppins';
    padding: 9px 0px;
    margin: 5px 5px;
    font-size: 9pt;
    position: absolute;
    right: 0;
    bottom: 17px;
}
.underbutton{
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.underbutton button{
color: white;
background-color: #2959b8;
border: none;
padding: 12px 0;
border-radius: 10px;
width: 100%;
font-family: 'Poppins';
}
.underbutton img {
    position: absolute;
    width: 25px;
    right: 279px;
}
.somkedivs{
    display: flex;

}

.qustiondivs {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-align: center;
}
.qustiondivs p{
    font-family: 'Poppins';
}
.Qulifictaiondiv{
    margin: auto;
}



/* TableComponent.css */
.termlandingtable {
    border-collapse: collapse;
    width: 100%;
    margin: 20px 0;
  }
  
  .termlandingtable-th {
    background-color: #0166b3;
    color: white;
    padding: 10px;
    border: 1px solid #ddd;
    width: 500px; /* Set width to control the column width */
  }
  
  .termlandingtable-td {
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 13pt;
    color: rgb(122 119 119);
}









.incomeproofdiv {
    width: 100%;
    height: 263px;
    border-radius: 10px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #3498db8a;
}
.identyproofdiv {
    width: 100%;
    height: 263px;
    border-radius: 10px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #e74c3c8a;
}
.incomeproofdiv2 {
    width: 100%;
    height: 263px;
    border-radius: 10px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #fd7e1499;
}
.incomeproofdiv3 {
    width: 100%;
    height: 263px;
    border-radius: 10px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: var(--toastify-color-success);
}

.headingboxproof {
    padding: 18px 15px;
    text-align: center;
}
.cardbox {
    padding: 9px 15px;
}
.cardbox li {
    list-style: none;
    color: #080808;
    font-size: 13pt;
    font-weight: 600;
    margin: 10px auto;
}


.headingboxproof .docheading {
    color: #000;
    font-size: 13pt;
    font-weight: 500;
}

.tabcontainer {
    width: 100%;
    border: 1px solid #80808075;
    border-radius: 5px;
    padding: 15px;
}

.undertabterm {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    display: flex;
    margin-bottom: 27px;
}
.docrequird {
    font-size: 12pt;
    font-family: 'Poppins';
    position: relative;
    left: 19px;
}


.undertabterm::-webkit-scrollbar {
    height: 3px; /* Height of the scrollbar */
}

.undertabterm::-webkit-scrollbar-thumb {
    background: #e0dbdb; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.undertabterm::-webkit-scrollbar-thumb:hover {
    background: #cccccc; /* Color when hovering over the scrollbar thumb */
}
.doctabs {
    padding: 10px;
    border-radius: 5px;
    width: 290px;
    cursor: pointer;
    background-color: #f967665e;
    color: #0055a5;
    font-weight: 600;
    text-align: center;
}

.doctabsde {
    padding: 10px;
    border-radius: 5px;
    color: #2959b8;
    width: 290px;
    margin: 0px 21px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
}



  /* Additional styles for termlandingtable if needed */
  .termlandingtable {
    border: 2px solid #0166b3; /* Example: Add a border around the table */
    border-radius: 5px; /* Example: Add rounded corners */
  }
  
  .termnumbering {
    color: #ffffff;
    font-weight: 700;
    padding: 3px 12px;
    background-color: #0166b3;
    border-radius: 50%;
}
.numberingdiv{
    background-color: red;
    width: 20px;
    height: 20px;
}

.boxterm {
    width: 100%;
    height: 239px;
    background-color: #007bff47;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxterm2{
    width: 100%;
    height: 239px;
    background-color: #6f42c12b;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxterm3 {
    width: 100%;
    height: 239px;
    background-color: #dc35453d;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxterm4 {
    width: 100%;
    height: 239px;
    background-color: #23b20d26;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxterm5 {
    width: 100%;
    height: 239px;
    background-color: #964b0040;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxterm6 {
    width: 100%;
    height: 239px;
    background-color: #ffc10747;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxterm7 {
    width: 100%;
    height: 239px;
    background-color: #6f42c12b;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxterm8 {
    width: 100%;
    height: 239px;
    background-color: #fd7e1445;
    border-radius: 23px;
    gap: 2px;
    margin: 10px auto;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    align-content: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payout-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive columns */
    gap: 20px; /* Space between grid items */
    padding: 20px; /* Padding around the grid */
  }


@media screen and (max-width: 1024px) {
    .flexdivterm{
        display: block;
    }
    .payoutboxs {
        width: 100%;
        height: 383px;
        border-radius: 15px;
        padding: 15px;
        box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
        background-color: #dc35454a;
        text-align: center;
        align-content: center;
        margin-bottom: 10px;
    }

.payoutboxs2 {
    width: 100%;
    height: 383px;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #ffc1074d;
    text-align: center;
    align-content: center;
    margin-bottom: 10px;
}
.payoutboxs3 {
    width: 100%;
    height: 383px;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    background-color: #007bff52;
    text-align: center;
    align-content: center;
    margin-bottom: 10px;
}
    .underterminsurence {
        width: 100%;
        padding: 10px 0px;
        margin: 10px auto;
        height: 544px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .incomeproofdiv {
        width: 100%;
        height: 286px;
        border-radius: 10px;
        margin: 10px auto;
        box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
        background-color: #3498db8a;
    }
    .identyproofdiv {
        width: 100%;
        height: 286px;
        border-radius: 10px;
        margin: 10px auto;
        box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
        background-color: #e74c3c8a;
    }
    .incomeproofdiv2 {
        width: 100%;
        height: 286px;
        border-radius: 10px;
        margin: 10px auto;
        box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
        background-color: #fd7e1499;
    }
}
@media screen and (max-width: 752px) {
    .undertermform {
        width: 95%;
        margin: auto;
    }
    .underbutton img {
      display: none;
    }

}
@media screen and (max-width: 600px){
    .termlandingtable-td {
        padding: 10px;
        border: 1px solid #ddd;
        font-size: 11pt;
        color: rgb(122 119 119);
    }
    .termlandingtable-th {
        background-color: #0166b3;
        color: white;
        padding: 10px;
        border: 1px solid #ddd;
        width: 500px;
        font-size: 11pt;
    }
    .flexdivterm{
        display: flex;
    }

}