.steeperdiv{
    width: 100%;
    padding: 15px 0;
    margin: 0 auto;
}
.imgdiv{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

}
/*.steppertext {
    width: 95%;
}*/


.popupdivs{
    width: 220px;
    height: 220px;
    background-color: red;
    border-radius: 15px;
}

  
.imgdiv img {
    width: 100%;
}
.textdivss {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin: 0 auto;
}
.onesdivs {
    width: 220px;
    background-color: #F7F6F9;
    border-radius: 10px;
    margin: 0 20px;
    padding: 7px 12px;
    text-align: center;
    min-height: 295px;
    align-content: center;
}
.flexydisplay{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.onesdivs h3{
    color: #0166b3;
    font-size: "12pt";
    font-family: "Poppins";
    font-size: 34pt;
}
.onesdivs span{
    color: #3a87d2;
    font-family: "Poppins";
    font-weight: 500;
    margin-bottom: 11px;
    font-size: 13pt;

}
.onesdivs p {
    color: gray;
    font-family: "Poppins";
    font-size: 10pt;
    margin-top: 13px;
    text-align: center;
}
.onesdivs img{
    width: 60px;
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 1050; */
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* outline: 0; */
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: auto!important;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-content{
    min-width: 300px
}

.stepper-img-home{
    width: 70%;
}

@media screen and (max-width: 1024px) {
    .textdivss {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        margin: 0 auto;
    }
    .imgdiv {
      display: none;
    }
    
}

@media screen and (max-width:677px) {
    /* .modal-content{
        width: 56%!important;
    } */
    .get-content-div{
       width: fit-content;
       margin: auto;
    }
    .getstarted-buttons-first{
      display: flex;
      flex-direction: column;
    }

    .getstarted-buttons-first button{
        text-align: center;
    }
    .get-content-div button{
        margin: 8px;
    }
    
  }

  @media screen and (max-width:500px) {
 .get-img{
    display: none!important;
 }
    
  }

@media screen and (max-width: 786px) {
    .imgdiv img {
        width: 91%;
    }
    .textdivss {
        width: 100%;
        display: block;
        padding: 10px 0;
        margin: 10px auto;
    }
    .onesdivs {
        width: 95%;
        height: 259px;
        background-color: whitesmoke;
        border-radius: 10px;
        margin: 14px auto;
        padding: 10px 12px;
        text-align: center;
    }
    .onesdivs img {
        width: 106px;
    }
    .onesdivs span {
        color: #3a87d2;
        font-family: "Poppins";
        font-weight: 500;
        margin-bottom: 11px;
        font-size: 13pt;
    }
    .onesdivs p {
        color: gray;
        font-family: "Poppins";
        font-size: 9pt;
        margin-top: 13px;
        text-align: center;
    }
}