.proposal-popup-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .proposal-popup-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .proposal-popup-exit {
    opacity: 1;
    transform: scale(1);
  }
  .proposal-popup-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
  .proposal-popup {
    position: fixed;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 58px 1028px rgb(0 0 0 / 50%);
    width: 653px;
    z-index: 99999999;

}
  

.proposalpop-body {
  background-color: #0166b317;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: none;
}
.overweight {
  color: #ff00009e;
  font-weight: 500;
  background-color: #FDEAEC;
  padding: 5px 5px;
  border-radius: 6px;
  border: 1px dashed;
}
.normalbmi {
  color: #008e1cdb;
  font-weight: 500;
  background-color: #58e81a17;
  padding: 5px 5px;
  border-radius: 6px;
  border: 1px solid;
}

.bmiresultsprop {
  padding: 4px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .proposalpop-body h2{

    font-size: 13pt;
    font-family: "Poppins";
    color: rgb(89, 88, 88);
    margin-bottom: -2px;
  }

  .bmi-heading h3{
    font-size: 13pt;
    font-family: "Poppins";
  }

  @keyframes rotate {
    0% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.6, 0.05, 0.2, 0.95); /* Start fast */
    }
    100% {
        transform: rotate(360deg);
        animation-timing-function: linear; /* End slowly */
    }
}

.thumb-iconover {
  display: inline-block;
  animation: rotate 1s forwards;
  color: #ff00009e;
  margin-right: 5px;
  border: 1px solid #ff00009e;
  width: 30px;
  height: 30px;
  font-size: 13pt;
  border-radius: 50px;
  text-align: center;
  background-color: #fff;
}


.flextop{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.thumb-iconnormal {
  display: inline-block;
  animation: rotate 1s forwards;
  color: #008e1cdb;
  margin-right: 5px;
  border: 1px solid #008e1cdb;
  width: 30px;
  height: 30px;
  font-size: 13pt;
  border-radius: 50px;
  text-align: center;
  background-color: #fff;
}
.bminameshowingdiv{
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.bmishowingdiv{
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
}

@media screen and (max-width: 500px){
  .proposal-popup {
    position: fixed;
    top: 49%;

    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 58px 1028px rgb(0 0 0 / 50%);
    width: 90%;
    z-index: 999;
}
.bmiresultsprop {
  padding: 4px 0px;
  display: block;
}
.bminameshowingdiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;
}
.bmishowingdiv{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
}


.bmi-loader-container {
  position: fixed;
  top: 10%; /* Adjust the distance from the top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 20; /* Ensure it appears above other elements */
  background-color: #f9f9f9; 
  /* Optional: background for visibility */
  padding: 10px 20px; /* Optional: padding for better spacing */
  border-radius: 8px; /* Rounded corners for aesthetics */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
  margin-top: -83px;
}

.bmi-loader {
  font-size: 20px;
  font-weight: bold;
  color: #0055a5;
  text-align: center;
}

/* Animated Dots */
.bmi-loader-dots::after {
  content: "";
  display: inline-block;
  animation: bmi-loader-dots-animation 1.5s infinite;
}

@keyframes bmi-loader-dots-animation {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.loader-bmi-data{
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 20px; */
  /* background-color: white; */
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  /* box-shadow: 0 4px 58px 1028px rgb(0 0 0 / 50%); */
  width: 653px;
  z-index: 999999999;
  
}

 /* ------------------------------------------------- */

 