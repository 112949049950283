.set-two-body {
    /* border: solid 1px rgb(0 0 0 / 19%);
    padding: 10px; */
    border-radius: 8px;
    /* box-shadow: 2px 2px 2px 1px #1e1e1e1a; */
    margin: 10px 0;
    /* max-width: 300px;  */
    padding: 5px;
}
.lower-set-two-answers{

}
.set-two-body-title {
    font-size: 0.8rem;
    color: rgb(77 74 74);
    letter-spacing: 1px;
    font-family: "Poppins";
    font-weight: 600;
    text-align: center; /* Center the title */
}

.underline-textField .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.underline-textField .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #4A90E2;
}

.underline-textField .MuiInput-underline:after {
    border-bottom: 2px solid #4A90E2;
}

/* Added styles for bordered text field */
.bordered-textField .MuiOutlinedInput-root {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 0 10px;
}

.bordered-textField .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.bordered-textField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #4A90E2;
}

.bordered-textField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #4A90E2;
}



.set-two-date{
    padding-left: 0px!important;
    margin-top: 22px!important;
}

.lower-date{
   margin-top: 15px!important;
}

.set-two-date > div > div input{
    padding-right: 0px!important;
}




.set-two-date-upper{
    padding-left: 0px!important;
    margin-top: 20px!important;
   
}

.set-two-date-upper > div > div input{
    padding-right: 0px!important;
}



.flat-date-two {
    border: none !important;
    border-bottom: 1px solid rgb(157, 154, 154) !important;
    width: 90%;
    margin-left: 10px;
    margin-top: 0.5px;
    box-shadow: 0 0.3px 0 rgb(191, 190, 190);
    font-family: 'Poppins'!important;


    
    
}
.flat-date-two:hover{
    border: 1.5px solid;
    border-color: #4A90E2!important;
    box-shadow: 0 1px 0 #4A90E2;
    
}

.flat-date-two::placeholder{
    font-size: 15px;
    font-family: 'Poppins'!important;
}


.flatpickr-input:focus {
    border: none; /* Ensure no border on focus */
    outline: none; /* Ensure no outline on focus */
  }