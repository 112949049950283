.tabulerbuy1 {
    background-color: aliceblue;
    height: 68px;
    width: 93%;
    border-radius: 37px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
}
.numberbuy1 {
    width: 50px;
    height: 50px;
    background-color: var(--toastify-color-warning);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    margin: 0px 14px;
    font-family: 'Poppins';
    position: relative;
    font-size: 13pt;
}
.numberbuy2{
    width: 50px;
    height: 50px;
    background-color: var(--toastify-color-warning);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    margin: 0px 14px;
    font-family: 'Poppins';
    position: relative;
    font-size: 13pt;  
}

.numberbuy1::after {
    content: '';
    width: 5px;
    height: 35px;
    background-color: var(--toastify-color-warning);
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
}


.textbuy1 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13pt;
    color: #828282;
}

.fmailimagegifsstepper{
    width: 225px;
    margin: auto;
}

@media screen and (max-width: 600px){
    .textbuy1 {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 11pt;
        color: #828282;
    }
    .fmailimagegifsstepper {
        width: 100%;
        margin: 39px auto;
    }
}