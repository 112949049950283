.language-dropdown select {
  min-width: 80px;
  text-align: center;
  margin: auto;
}

.nav-dropdown-content {
  position: absolute;
  left: -150%;
  border-radius: 0.25rem;
  width: 840px;
  height: -moz-fit-content;
  height: fit-content;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
  padding: 0.5rem 0.5rem;
  color: #212529;
  z-index: 5000;
  margin: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.nav-dropdown-content a {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 11px 3px;
  text-decoration: none;
  color: rgb(80, 85, 92);
  font-size: 11pt;
  transition: 0.3s;
}
.nav-dropdown-content a:hover{
  color: #0166b3;
  font-weight: 500;
}


.nav-dropdown-content p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 17px;
  color: #141414;
}

.nav-dropdown-content a:hover {
  background: #007fff0f 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.nav-dropdown-content span:hover {
  background: #007fff0f 0% 0% no-repeat padding-box;
  border-radius: 6px;
  cursor: pointer;
}

.nav-dropdown-content p:focus-visible {
  outline: 2px solid #333;
  outline-offset: 4px;
  border-radius: 2px;
  color: darkmagenta;
  transition: 200ms ease-out;
}

.Menubar .nav-dropdown:hover:after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    bottom: -5px;
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    background-color: #fff;
    background-image: linear-gradient(135deg, #fff 50%,transparent 0);
    border-color: #c8ceed transparent transparent #c8ceed;
    border-style: solid;
    border-width: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 2px 0 0 0;
    z-index: 1001;
    -webkit-animation: arrowSlideIn .25s ease-out forwards;
    animation: arrowSlideIn .25s ease-out forwards;
}

/* .nav-dropdown-content:before {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
} */


.nav-dropdown-content:before {
  /* content: "";
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;

  display: block;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  top: calc(100% + 10px);
  left: -100%;
  z-index: 1; */
}


@arrowSlideIn {

  0% {
    opacity: 0;
    margin-top: 6px;
    z-index: -1;
  }
  100% {
    opacity: 1;
    margin-top: 0;
    z-index: 1;
  }
}


/* ------------------------ */

.Navbar {
  width: 94%;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 20px; */
  
  margin: auto;
  /* padding-top: 30px; */
  margin-top: 20px;
}
.stickynavbar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 94%;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: auto;
  padding-top: 30px;
}
.Navbar .logo {
  width: 13%;
  height: 70px;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  bottom: 18px;
}
.Navbar .logo button {
  display: none;
  margin: 0 15px;
}
.Navbar .logo img {
  width: 100%;
  min-width: 150px;
}
/* .Navbar .Menubar {
  display: flex;
  width: 40%;
  height: 70px;
  justify-content: center;
  align-items: center;
  min-width: 515px;
} */

.Navbar .Menubar {
  display: flex;
  width: 60%;
  height: 70px;
  justify-content: center;
  align-items: center;
  min-width: 723px;
  max-width: 900px;
}
.Navbar .Menubar ul {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  bottom: 7px;
}
.Navbar .Menubar ul li {
  list-style: none;
  margin-right: 5px;
}
.Navbar .Menubar ul .menuahrf {
  color: #68768f;
  font-weight: 500;
  margin: 0 7px;
  font-size: medium;
  height: 35px;
  display: inline-flex;
  align-items: center;
  /* font-weight: 660; */
}

.loginbutton {
  color: white;
  background-color: #0166b3;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 15px;
  border: none;
}
.loginbutton:hover {
  color: white;
}
.myaccount {
  color: #0166b3;
  background-color: white;
  border-radius: 4px;
  padding: 10px 13px;
  border: 1px solid #0166b3;
  margin-right: 10px;
}
.callingbutton {
  color: #2959b8;
  font-size: 18px;
  background-color: #2959b80f;
  border-radius: 25px;
  padding: 7px 11px;
  border: 1px solid #2959b8;
  animation: glowing 1.5s infinite;
}

.underDropdown {
  position: relative;
}

.underarrowspan {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.underDropdown img {
  height: 37px;
}

.flexdisplay {
  /* width: 600px; */
  width: 100%;
  position: relative;
}

.underarrowspan {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.outer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftsidedrop {
  min-width: 255px;
  background-color: rgb(236, 240, 244);
  padding: 10px;
  border-radius: 10px;
}
.leftsidedrop a {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 11pt;
  transition: 0.4s;
}
.leftsidedrop a:hover {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 11pt;
  color: #0166B3 !important;
  /* scale: 1.1; */
}






.leftsidedrop a img {
  margin-right: 13px;
}

.childdrop {
  width: 99%;
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: space-between;
}
.rightDropdown {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 9px;
}
.childlink{
  display: flex;
  justify-content: left!important;
  font-weight: 300;
}



.myaccountdrop {
  width: 143px;
  padding-top: 10px;
  margin-top: 10px;
  padding: 13px 0px;
  animation: slideDown 0.5s ease-in-out forwards;
  display: block;
  background-color: white;
  position: absolute;
  padding: 10px 0px;
  z-index: 99;
  border-radius: 9px;
  box-shadow: 1px 1px 10px -4px;
}
.MyaccountDropLink {
  border-bottom: 1px solid #8080807a;
  width: 100%;
  margin: 0px 0px;
  display: block;
  padding: 10px 9px;
  color: #0166B3;
  font-size: 9pt;
}
.MyaccountDropLink:hover{
  background-color: #0166b31b;
}


@keyframes slideDown {
  0% {
    top: 50px; /* Start off-screen */
    opacity: 0; /* Start invisible */
  }
  100% {
    top: 60px; /* Slide down to this position */
    opacity: 1; /* Fade in */
  }
}

@media screen and (max-width: 940px) {
  .Navbar {
    padding: 0px;
  }
  .Menubar {
    display: none !important;
  }

  .nav-ham {
    display: block;
    cursor: pointer;
  }
}

@media screen and (min-width: 941px) {
  .Menubar {
    display: block;
    cursor: pointer;
  }

  .nav-ham {
    display: none !important;
  }
}
.extrawidthEditprofile {
  max-width: 690px;
}
.addsressEdit{
  height: 120px;
  width: 100%;
}
.boxadd{
  width: 107px;
  height: 64px;
  border: 1px dotted #0166B3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: 0 3px;
}
.flexdisplayse{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexxdisplaye{
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveprofile{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.savebutton{
  background-color: #2959B8;
  padding: 18px 78px;
  border-radius: 7px;
  border: none;
  color: white;
  font-weight: 500;
  margin-bottom: 11px;
}


/* @media screen and (max-width: 900px) {
  .extrawidthEditprofile{
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 540px) {
  .extrawidthEditprofile{
    width: 300px;
    margin: 0 auto;
  }
} */

.car-logo-header{
  height: 40px;
}
.bannermenu {
  width: 100%;
  height: 141px;
  background: linear-gradient(to right, #ffff002b 0%, #4169e14f 100%);
}
.crossdivbtn {
  width: 91%;
  margin: auto;
  padding: 14px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crossdivbtn button{
  background-color: transparent;
  border: none;
  font-size: 25px;
}
.crossdivbtn img {
  width: 139px;
}
