.mainflex {
    width: 82%;
    display: flex;
    justify-content: center;
    margin: auto;
}
.latemaincalculator {
    width: 70%;
    margin: 0 auto;
}
.sidecalculator {
    width: 20%;
    margin: 0px auto;
}

.childeducation {
    width: 79%;
margin: 20px auto;

}

.childeducation h1 {
    font-size: 22pt;
    font-weight: 500;
    color: gray;
    font-family: 'Poppins';
}
.breadcum {
    font-size: 9pt;
    color: gray;
    margin-bottom: 10px;
}





.childeducation .spantext{
    color: gray;
    position: relative;
    font-family: 'Poppins';

}
.educationCalculators {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #2959b8;
    margin-bottom: 19px;
    border-radius: 8px;
    padding: 31px 0px;
}
.education-calculator{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-evenly;
}
.educationCalculators p{
    color: #2959b8;
    font-family: 'Poppins';
    font-weight: 500;
    position: relative;
    left: 75px;
    top: 18px;

    text-transform: capitalize;
}
.planescode{
    width: 87%;
    margin: 10px auto;
    padding: 15px 0px;
}
.educationcal{
    width: 87%;
    margin: 6px auto;
    padding: 10px 0;
}
.educationcal select{
    width: 260px;
    outline: none;
    background-color: transparent;
    border: 1px solid #2959b8;
}
.buttoncalculate{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttoncalculate button{
    color: white;
    background-color: #2959b8;
    border: none;
    border-radius: 5px;
    padding: 12px 54px;
    font-family: 'Poppins';
}
.resultdivforchild {
    width: 95%;
    height: 475px;
    border: 1px solid rgb(172, 170, 170);
    border-radius: 10px;
    margin: 15px auto;
 
}
.calculateamount {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 334px;
    text-align: center;
}
.buttonback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 169px;
}


.buttonback .buybutton{
    font-family: 'Poppins';
    background-color: #2959b8;
    color: white;
    border-radius: 5px;
    padding: 15px 73px;
    border: none;
    text-transform: capitalize;
}
.calculatemoney h2{
    color: #2959b8;
    font-size: 35pt;
    font-family: 'Poppins';
}
.calculatemoney span{
    color: #635f5f;
    font-size: 12pt;
    font-family: 'Poppins';
}

.backbuttons{
    margin: 0 50px;
    background-color: transparent;
    border: none;
    color: #2959b8;
    font-size: 15pt;
    font-family: 'Poppins';
}
.calculatemoney .amounttotext{
    font-size: 10pt;
    color: #2959b8;
}
.selectcalclulatortype {
    width: 100%;
    height: 63px;
    margin: auto;
    margin-bottom: 11px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.monthly{
    color: white;
    background-color: #2959b8;
    padding: 13px 10px;
    width: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    border-radius: 14px;
    font-family: 'Poppins';
    cursor: pointer;
}
.reguler{
    color: white;
    background-color: #2959b8;
    padding: 13px 10px;
    width: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 14px;
    font-family: 'Poppins';
}
.deactivate{
    color: #2959b8;
    background-color: rgb(235, 228, 228);
    padding: 13px 10px;
    width: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 14px;
    font-family: 'Poppins';
    margin: 0 10px;
}
.additnal {
    width: 100%;
    margin: auto;
    margin-bottom: 13px;
    border-radius: 0px 0px 25px 29px;
    /* margin-top: -16px; */
    overflow: hidden;
    border: 1px solid #c1bbbb;
    padding: 10px 15px;
}
.calculatorresultmain{
    display: flex;
    justify-content: center;
    align-items: center;
}
.calculatorresultmain{
 width: 100%;
}


.childeducationresult {
    margin-top: 35px;
    overflow: hidden;
    border: 1px solid #c9c6c6;
    border-radius: 15px;
    margin: 15px 21px;
    padding: 39px 13px;
}

@media screen and (max-width: 1191px) {
    .educationCalculators{
        width: 95%;
        padding: 15px 0px;
        border: none;

    }
    .educationCalculators p {
        color: #2959b8;
        font-family: 'Poppins';
        font-weight: 500;
        position: relative;
        left: 24px;
        top: 17px;
        text-transform: capitalize;
    }
    .education-calculator {
        display: block;
        justify-content: space-evenly;
        margin: 8px 0px;
    }
    .slider-lvh {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    .value-lvh {
        width: 65%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }

    .childeducation {
        width: 88%;
      
  
    }
    .childeducation h6 {
        font-size: 26pt;
        color: gray;
        font-family: 'Poppins';
    }
    .childeducation .spantext {
        color: gray;
        position: relative;
        font-family: 'Poppins';
    }
    .buttoncalculate {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 0px;
    }
}
@media screen and (max-width: 1024px) {
    .mainflex {
        width: 95%;
        display: flex;
        justify-content: center;
        margin: auto;
    }
 
}
@media screen and (max-width: 768px) {
    .calculatemoney{
        padding: 15px 0px;
    }
    .buttonback {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90px;
    }
}
@media screen and (max-width: 520px) {
    .childeducation {
        width: 90%;

    }
    .resultdivforchild {
        width: 95%;
        height: 570px;     
        height: 531px;
        border: 1px solid rgb(172, 170, 170);
        border-radius: 10px;
        margin: 15px auto;
        padding: 22px 0px;
        overflow: hidden;
    }
    .calculateamount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 73%;
        height: 362px;
        margin: auto;
        text-align: center;
}
.buttonback .buybutton {
    font-family: 'Poppins';
    background-color: #2959b8;
    color: white;
    border-radius: 5px;
    padding: 8px 55px;
    border: none;
    text-transform: capitalize;
}
.buttonback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 70px;
    position: relative;
    top: 59px;
}
.additnal {
    width: 95%;
    margin: auto;
    border: 1px solid #b8b0b0;
    margin-bottom: 13px;
    border-radius: 0px 0px 25px 29px;

}
.calculatemoney {
    /* padding: 37px 58px; */
    border: none;
    /* padding: 37px 46px; */
    /* border: 1px solid #919397; */
    /* border-radius: 104px 0px 104px 0px; */
}
.childeducationresult {
    overflow: hidden;
    height: 606px;
    border: none;
}

}

@media screen and (max-width: 658px) {
    .latemaincalculator {
        width: 100%;
        margin: 0 auto;
    }
    .mainflex {
        width: 100%;
        display: block;
    }
    .sidecalculator {
        width: 95%;
        margin: 0px auto;
    }
    .childeducation {
        width: 96%;
    }
    .selectcalclulatortype {
        width: 95%;
        height: 63px;
        margin: auto;
        margin-bottom: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .calculatemoney {
        width: 97%;
        /* border: none; */
        border-bottom: 1px solid #c5c3c3;
        padding: 15px 9px;
        margin: auto;
    }
    .childeducation h6 {
        font-size: 24pt;
        color: gray;
        font-family: 'Poppins';
    }

}
@media screen and (max-width: 848px) {
    .calculateamount {
        display: block;
        margin: auto;
        width: 100%;
        height: 370px;
        text-align: center;
    }
}