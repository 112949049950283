.underdashbordreminder {
    width: 96%;
    height: 553px;
    border: 1px dotted rgb(222 215 215);
    border-radius: 15px;
    margin: 0 auto;
    padding: 0 2px;
}
.navigationbar{
   visibility: hidden;
}
.buttonadd {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 15px;
}
.buttonadd .setreminder{
    background-color: #FCA84C;
    color: white;
    font-size: 12pt;
    padding: 5px 8px;
    border-radius: 8px;
    border: none;
}
.popupfooter{
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
.popupsubmitfooter{
    color: white;
    background-color: #2957B6;
    border: none;
    border-radius: 25px;
    padding: 15px 75px;
    font-weight: 500;

}
.modalwidth{
    max-width: 694px;
    padding: 15px 15px;
}
.submitafter{
    width: 50%;
    height: 50px;
    margin: 0 auto;
    border-radius: 25px;
    background-color: rgb(0 128 0 / 25%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.submitafter span{
    color: green;

}
.submitafterfail{
 
        width: 50%;
        height: 50px;
        margin: 0 auto;
        border-radius: 25px;
        background-color: rgba(255, 45, 45, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
    
}
@media screen and (max-width: 1000px) {
    .buttonadd {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
    }
    .navigationbar{
        background-color: #FCA84C;
        color: white;
        font-size: 12pt;
        padding: 5px 8px;
        border-radius: 8px;
        border: none;
        visibility: visible;
    }
}
  