/* .modal-header{
 height: 25px;
} */
.modal-content{
    border-radius: 20px;
    padding-bottom: 15px;
    z-index: 999;
    
}
@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.8); /* Start smaller */
    }
    100% {
        opacity: 1;
        transform: scale(1); /* Normal size */
    }
}

.lopinotppopup {
    width: 374px;
    height: 347px;
    animation: fadeInScale 0.5s ease forwards; /* Adjust duration as needed */
}

.request-otp-header{
    text-align: center;
    width: 80%;
    margin: 0 auto;
}
.form-controls {
    border: none;
    padding: 10px 15px;
    background-color: transparent;
    outline: none;
    border-right: 1px solid #c1c1c1;
    border-radius: 0px;
    margin: 4px 10px;
}


/* .form-group {
 display: grid;    
} */
.input-edit{
 border: none !important;
 background-color: white!important;

}
.input-edit::placeholder{
   margin-right: 44px;
}
.custom-select-login{
    width: 80% !important;
    margin: 0 auto;
}
.emailtop{
    border: 1px solid gray;
    position: relative;
    top: 10px;
    
}
.arrowimage{
    margin: 0 10px;
    transition: transform 0.3s ease;
    
}
.error{
    font-size: 8pt;
    color: red;
}
.coloralert{
    color: red;
    font-family: 'Poppins';
    font-size: 9pt;
    text-align: center;
}
.validatediv{
    margin: auto;
    text-align: center;
}
.extrawidthpopup{
    max-width: 50%;
}
.popupmainsubmit{
    width: 100%;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popupmainsubmit button{
    width: 40%;
    height: 40px;
    background-color: #2959B8;
    color: white;
    border-radius: 5px;
    border: none;
}
@media screen and (max-width: 762px) {
    .extrawidthpopup{
       min-width: 98%;
    }
}