/* Container for the entire page */
.health-insurance-container {
    width: 80%; /* Set the width to 80% of the page */
    max-width: 900px; /* Maximum width to avoid it becoming too large */
    margin: 0 auto; /* Center the container */
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px 40px; /* Adjusted padding for a more compact look */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .health-insurance-container:hover {
    /* transform: scale(1.02); */
  }
  
  /* Header */
  .health-insurance-header {
    background: linear-gradient(to right, #007bff, #00c6ff);
    color: #fff;
    padding: 9px 10px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 25px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .health-insurance-title {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  
  /* Insurer and Plan Details */
  .health-insurance-details {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Reduced gap between cards */
  }
  
  .health-insurance-insurer-info,
  .health-insurance-plan-info {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .health-insurance-insurer-info:hover,
  .health-insurance-plan-info:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }
  
  .health-insurance-insurer-logo {
    width: 120px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .health-insurance-insurer-name {
    font-size: 28px; /* Reduced font size */
    color: #333;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .health-insurance-insurer-description {
    font-size: 16px; /* Reduced font size */
    line-height: 1.5;
    color: #555;
  }
  
  .health-insurance-plan-info h2 {
    font-size: 24px; /* Reduced size */
    margin-bottom: 15px;
    color: #007bff;
    text-transform: uppercase;
  }
  
  .health-insurance-plan-info ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .health-insurance-plan-info li {
    font-size: 16px; /* Reduced font size */
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
  }
  
  .health-insurance-plan-info li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #00c6ff;
    font-size: 16px;
    top: 3px;
  }
  
  /* Contact Section */
  .health-insurance-contact-section {
    background-color: #f9f9f9;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
  }
  
  .health-insurance-contact-section h2 {
    font-size: 22px; /* Smaller heading */
    color: #333;
    margin-bottom: 12px;
  }
  
  .health-insurance-contact-section ul {
    list-style-type: none;
  }
  
  .health-insurance-contact-section li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .health-insurance-contact-section li a {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
  }
  
  .health-insurance-contact-section li a:hover {
    text-decoration: underline;
  }
  
  /* Footer */
  .health-insurance-footer {
    text-align: center;
    margin-top: 30px;
    color: #777;
  }
  
  .health-insurance-footer p {
    font-size: 14px; /* Reduced font size */
    opacity: 0.8;
  }
  
  /* Responsive Design */
  @media (max-width: 1000px) {
    .health-insurance-container {
      padding: 20px;
    }
  
    .health-insurance-header {
      padding: 25px;
    }
  
    .health-insurance-details {
      gap: 15px;
    }
  
    .health-insurance-insurer-info,
    .health-insurance-plan-info {
      padding: 15px;
    }
  
    .health-insurance-insurer-logo {
      width: 100px;
    }
  
    .health-insurance-title {
      font-size: 30px;
    }
  
    .health-insurance-insurer-name {
      font-size: 24px;
    }
  
    .health-insurance-plan-info h2 {
      font-size: 20px;
    }
  
    .health-insurance-plan-info li {
      font-size: 14px;
    }
  
    .health-insurance-contact-section h2 {
      font-size: 20px;
    }
  
    .health-insurance-contact-section li {
      font-size: 14px;
    }
  
    .health-insurance-footer p {
      font-size: 12px;
    }
  }
  