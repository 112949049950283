.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  overflow-x: hidden;
  /* transition: width 0.5s ease; Added transition */
}

.slideInComponent {
  position: fixed;
  top: 0;
  height: 100%;
  width: 46%;
  max-width: 455px;
  min-width: 450px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.5s ease;
  z-index: 700;
}

.age-whole-body{
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
 
}

.ageupper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 13px 12px;
  box-shadow: 0 4px 8px 0 rgba(128, 122, 122, 0.2), 0 6px 20px 0 rgba(160, 147, 147, 0.19);
}

.age-member-details {
  margin-top: 13px;
}
.ageupper h3{
  color: #0166b3;
  font-size: 15pt;
  margin-bottom: 0rem;
}

.agecontext {
  height: calc(100% - 330px);
  padding: 20px;
  padding-left: 35px;
  overflow-y: auto;
}

.age-difference-details{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.age-membername{
  font-size: 18px;
  font-weight: 550;
  color: #0166B3;
  font-family: "Poppins";
  margin-bottom: 8px;
}
.totalprimuim{
  color:#0166B3;
  font-size: 12pt;
  font-weight: 600;

  font-family: "Poppins";
}

.agecontext-lower {
  display: block;
  flex-direction: row;
  align-items: center;
}
.agecontext-lower p {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins';
  color: #897979;
  margin-bottom: 0rem !important;
}
.agecontext-lower img {
  width: 94px;
  object-fit: contain;
  aspect-ratio: 3 / 2;
}



.modal-wrapper {
  position: fixed;
  top: 0;
  right: -100%; /* Start off-screen to the right */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Background overlay */
  z-index: 1000;
  transition: right 0.3s ease-in-out; /* Smooth transition effect */
}

/* Modal open state */
.modal-open {
  right: 0; /* Bring modal into view from the right */
}

/* Modal close state */
.modal-close {
  right: -100%; /* Hide modal off-screen to the right */
}


















.appear-none{
  display: none;
}

.ageCloseButton{
  background-color: white;
  border: none;
}

.ageCloseButton-icon{
  color:#808080;
  margin-top: -13px;
  font-size: 27px;
}

.agerightside{
  margin-right: 35px;
}

.age-premium-inner span{
  font-size: 16px;
  color: #0c6db6;
}

.showingchangemassage{
  color: #0166B3;
}

.age-premium-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  position: absolute;
  bottom: 165px;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  padding-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -13px 6px -12px, rgba(0, 0, 0, 0.06) 0px -2px 12px -11px;
}

  .contextwarning {
    color: #d61a1a;
    font-family: "Poppins";
    font-size: 15px;
    margin-bottom: 4px;
}



.agedetails-flex p {
  font-size: 16.5px;
  margin-bottom: 6px;
  text-decoration: underline;
  text-underline-offset: 0.5em;
  font-family: 'Poppins';
  text-decoration-color: rgb(71 67 67);
  color: #767676;
}

.agedetails-flex h3 {
  font-size: 14px;
  color: #0166b3;
  font-family: 'Poppins';
  font-weight: 600;
  }

.age-premium-inner p{
  color: gray;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins';
  text-decoration: underline;
  text-underline-offset: 0.5em;
  text-decoration-color: rgb(161, 156, 156);
  position: relative;
  bottom: -11px;
}




.slideInComponent.closing {
  right: -50%; 
}

.overlay.active {
  width: 100%;
}

.slideInComponent.active {
  right: 0; /* Slide in from the right */
}

/* Prevent scrolling when the popup is active */
body.popup-open {
  overflow: hidden;
  
}

.content {
  padding: 20px;
}



/* -------------footer css start ------------------- */


.age-premium-footer {
  width: 100%;
  background-color: #b4b4b457;
  position: absolute;
  bottom: 0px;
  padding: 6px 26px;
  border-radius: 27px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.age-premium-change{

}
.otehrplanes {
  color: #ff6c00;
  background-color: transparent;
  font-family: "Poppins";
  border: none;
  padding: 15px 0px;
}

.age-footer-upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 24px;
}

.age-footer-premium-detail{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.age-old-premium{
  margin-right: 12px;
  font-weight: 400;
  font-family: 'Poppins';

}

.age-new-premium{
  font-size: 15px;
  font-weight: 800;
  margin-top: -5px;
  font-family: 'Poppins';
}

.age-footer-btn{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.age-footer-btn .acceptplane {
  margin-bottom: 6px;
  height: 45px;
  padding: 5px;
  border: none;
  border-radius: 9px;
  background-color: #ff7108;
  color: white;
  font-weight: 600;
  font-family: 'Poppins';
}