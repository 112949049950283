.homepagecalculator {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 10px;
    width: 90%; /* Adjust as needed */
    max-width: 1550px;
    margin: 15px auto;
    padding: 10px; /* Added padding for better spacing */
  }
  .calculatorsspage {
    width: 95%;
    height: 191px;
    margin: 15px auto;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px 10px;
    transition: transform 0.3s ease-in-out;
}

.calculatorsspage:hover {
    animation: moveUp 0.3s forwards;
}

@keyframes moveUp {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
}

.covertextcalculator{
    width: 90%;
    margin: 5px auto;
    max-width: 1550px;
}
.calculatorsspage img {
    width: 70%;
    height: 124px;
    object-fit: contain;
    aspect-ratio: 3 / 2;
}
.calculatorsspage h3{
    font-family: "Poppins";
    color: #2959b8;
    font-size: 12pt;
    padding: 15px 0px;
}
.calculatorsspage span{
    font-family: "Poppins";
}

.calculatordis span{
    font-family: "Poppins";
    color: gray;
    font-size: 12pt;
}

@media screen  and (max-width: 1000px) {
    .calculatorsspage {
        width: 90%;
        height: 242px;
        margin: 20px auto;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        padding: 15px 10px;
        transition: transform 0.3s ease-in-out;
    }
}
@media screen and (max-width: 1405px) {
    .calculatorsspage {
        width: 93%;
        height: 191px;
        margin: 15px auto;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        padding: 15px 10px;
        transition: transform 0.3s ease-in-out;
    }
}
@media screen and (max-width: 1023px) {
    .calculatorsspage {
        width: 80%;
        height: 260px;
        margin: 15px auto;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        padding: 15px 10px;
        transition: transform 0.3s ease-in-out;
    }
    .calculatorsspage h3 {
        font-family: "Poppins";
        color: #2959b8;
        font-size: 12pt;
        /* padding: 15px 0px; */
    }
}
@media screen and (max-width: 632px) {
    .calculatorsspage {
        width: 90%;
        height: 280px;
        margin: 15px auto;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        padding: 15px 10px;
        transition: transform 0.3s ease-in-out;
    }
    .calculatorsspage h3 {
        font-family: "Poppins";
        color: #2959b8;
        font-size: 12pt;
        /* padding: 15px 0px; */
    }
    .calculatortext {
        width: 100%;
    }
}
.carsouldiv {
    width: 100%;
    margin-bottom: 63px;
    margin: 25px auto;
}
.headercalculator{
    margin-top: 15px;
}
.morebuttondivs {
    width: 98%;
    margin: 17px auto;
    display: flex;
    justify-content: start;
    align-items: center;
}
.morebuttondivs a {
    color: #ffffff;
    font-family: "poppins";
    background-color: #2959b8;
    padding: 13px 17px;
    border-radius: 5px;
}