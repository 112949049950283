.retairment {
    width: 100%;
    margin: 0 auto;
}
.retairment h6{
    font-size: 35pt;
    color: gray;
}
.Mainheading{
    color: #0166b3;
    font-family: 'Poppins';
}
.subtaital{
    font-family: 'Poppins'; 
    color: gray;
}
.calculatorRetaiment {
    width: 100%;
    border: 1px solid #2959b8;
    margin: 10px auto;
    padding: 25px 10px;
    animation: fade-in 0.45s ease-in-out forwards;
    border-radius: 8px;
}
@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.calculatorRetaimentse{
    width: 1000px;
    border: 1px solid rgb(197, 195, 195);
    margin: 10px auto;
    padding: 25px 10px;
}
.retsultcalculator {
    width: 100%;
    height: 515px;
}

.calculatetorsdiv{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.sliderCalculator{
    width: 50%;
}
.textcalculator{
    width: 20%;

}
.textcalculator input {
    color: #0166B3;
    background-color: #0166b325;
    width: 90px;
    height: 30px;
    outline: none;
    font-weight: 400;
    font-family: 'Poppins';
    border: none;
    padding: 0px 6px;
    border-radius: 4px;
}
.calculatorRetaiment label{
    color: #2959b8;
    font-family: 'Poppins';
    margin-left: 70px;
    margin-top: 22px;
    font-weight: 500;
    margin-bottom: -25px;
}
.Investmentlabel{

    margin-top: 45px; 
    margin-bottom: -10px;
}
.buttondivcalculator {
    width: 100%;
    padding: 15px 0;
    margin: 25px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttondivcalculator button{
    color: white;
    background-color:#0166b3;
    padding: 10px 45px;
    border-radius: 5px;
    border: none;
    font-family: 'Poppins';
    font-size: 14pt;
    margin: 0 15px;
    
}
.backbtndiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
.backbtndiv button{
    color: white;
    background-color: #0166b3;
    font-family: "poppins";
    padding: 10px 25px;
    border: none;
    width: 30%;
    height: 50px;
    border-radius: 5px;
    text-transform: capitalize;
}
.buttondivcalculator .Previous{
    color: #0166b3;
    background-color: transparent;
    border: transparent;
}

.selctinvestmenttype {
    width: 88%;
    display: flex;
    justify-content: start;
    align-items: start;
    margin: auto;
}
.underflexcalculator {
    display: flex;
    height: 328px;
    justify-content: space-around;
    align-items: center;
    position: relative;
    /* top: 190px; */
}
.underflexcalculator h2{
    color: #2959b8;
    font-weight: 600;
    font-size: 24pt;
}
.Resultdivcalculator {
    width: 100%;
    text-align: center;
}
.Resultdivcalculator .moneyamountcalculator{
    font-size: 9pt;
    color: #656262;
    font-family: 'Poppins';
}

@media screen and (max-width: 800px) {
    .retairment {
        width: 100%;
        /* background-color: red; */
        margin: 0 auto;
    }
    .calculatetorsdiv {
        width: 100%;
        display: block;
        justify-content: space-around;
        align-items: center;
    }
    .calculatorRetaiment {
        width: 95%;

        border: none;
        margin: 10px auto;
        padding: 25px 10px;
        animation: fade-in 0.45s ease-in-out forwards;
    }
    .textdivs{
        width: 95%;
        margin: 0 auto;
    }
    .sliderCalculator {
        width: 95%;
    }
    .textcalculator {
        width: 50%;
    }
    .calculatorRetaiment label {
        color: #2959b8;
        font-family: 'Poppins';
        margin-left: 5px;
        margin-top: 7px;
        font-weight: 500;
        margin-bottom: -25px;
    }
    .buttondivcalculator {
        width: 100%;
        padding: 15px 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        position: relative;
        top: 15px;
    }
    .failnalcalculculatorbutton {
        display: flex;
        justify-content: center;
        align-items: end;
        height: 309px;
        position: relative;
        margin: auto;
    }
    .underflexcalculator {
        display: block;
        align-items: center;
        position: relative;

    }
    .Resultdivcalculator {
        width: 100%;
        text-align: center;
        margin: 15px 0px;
    }
}
@media screen and (max-width: 767px) {
    .calculatorRetaiment {
        width: 95%;
        height: 798px;
        border: none;
        margin: 10px auto;
        padding: 25px 10px;
        animation: fade-in 0.45s ease-in-out forwards;
    }

    .buttondivcalculator {
        width: 100%;
        padding: 15px 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        position: relative;
        top: 145px;
    }
}
@media screen and (max-width: 700px) {
    .underflexcalculator {
        display: block;

    }

.underflexcalculator h2 {
    color: #2959b8;
    font-weight: 600;
    font-size: 24pt;
    margin: 15px auto;
}
}
.failnalcalculculatorbutton {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 307px;
    position: relative;

}
.failnalcalculculatorbutton .Previous{
    color: #0166b3;
    border: none;
    margin: 0 17px;
    background-color: white;
    font-family: "Poppins";
    width: 40%;
    height: 48px;
}
.failnalcalculculatorbutton .next{
    color: white;
    background-color: #0166b3;
    font-weight: 400;
    font-family: "Poppins";
    border: none;
    border-radius: 5px;
    width: 40%;
    height: 48px;
}

.headincalculators {
    color: #e59c4b;
    font-weight: 500;
    font-family: 'Poppins';
}

@media screen and (max-width:695px) {
    .calculatorRetaiment {
        width: 95%;
        height: 888px;
        border: none;
        margin: 10px auto;
        padding: 25px 10px;
        animation: fade-in 0.45s ease-in-out forwards;

        overflow: hidden;
    }

    .buttondivcalculator {
        width: 100%;
        padding: 15px 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        position: relative;
        top: 225px;
    }
}
