.amountselect{
    display: flex;
    justify-content: space-around;
}
.amountselect p{
    position: relative;
    left: -21px;
    top: 8px;
}
.fetchplanetag {
    color: rgb(0, 85, 165);
    background-color: rgba(0, 85, 165, 0.17);
    font-size: 0.7rem;
    border-radius: 5px;
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 14px; */
    margin: 4px 18px;
}
.backgroundcarasoulfetchplane{
    background-color: var(--white);
    border-radius: 13px;
    margin-top: 11px;
    box-shadow: 0px 1px 5px -2px;
}
.bocurewarding {
    color: #0166b3;
    padding: 5px 10px;
    margin-right: 5px;
    font-weight: 500;
    border-radius: 6px;
    font-family: 'Poppins';
    font-size: 8pt;
    text-align: center;
}
.brosurediv {
    display: flex;
    justify-content: space-around;
    align-items: start;
    position: relative;
    height: 39px;
}
.tikplane{
    width: 20px;
    height: 20px;
}

.planelist {
    list-style: none;
    margin: 10px;
    color: #3a3737;
    font-size: 10pt;
    font-family: 'Poppins';
}
.planelistdis{
    font-size: 8pt;
    font-family: 'Poppins';
    color: #3a3737;
    padding-bottom: 0px;
}
.listheaderplanepage{
    text-align: start;
}

.listfetchplane {
    color: #0166b3;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12pt;
}

.onlytesteminial {
    width: 100%;
    height: 304px;
    border-radius: 10px;
    padding: 15px;
    background-color: #f9f9f9;
}
.onlytesteminial span {
    font-size: 10pt;
    color: #585252;
    font-family: 'Poppins';
}
.flextestemonial{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.testemonialicon {
    width: 74px;
    height: 37px;
    aspect-ratio: 3 / 2;
    object-fit: contain;
}
@keyframes rotateDown {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes rotateUp {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.dowclass {
    animation: rotateDown 0.3s forwards;
    font-size: 16pt;
    font-weight: 500;
    color: #605252;

}

.upclass {
    animation: rotateUp 0.3s forwards; /* Rotate up */
    font-size: 13pt;
    font-weight: 500;
    color: #4b4343;

}


