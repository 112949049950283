.maincompanydiv{
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;

    
}
.maincontentCompany{
    width: 70%;

    padding: 25px 0;
}
.catagory{
    color: #2959b8;
    background: #2959b81f;
    padding: 3px 5px;
    font-family: 'Poppins';
    border-radius: 3px;
    font-size: 0.9rem;
    font-weight: 500;
}
.sildebarCompany{
    width: 20%;
    /* height: 820px; */
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 25px 0px;
    position: sticky;
    top: 25px;
}

.Companydetails{
    width: 95%;
    height: 550px;
    background-color: #f5f5f5;;
    border-radius: 15px; 

    position: sticky;
    top: 37px;

}
.menucompany {
    width: 95%;
    margin: 0 auto;
}
.Companynamedetails{
    width: 95%;
    text-align: center;
    margin: auto;
    padding: 25px 0;
    border-bottom: 1px solid rgb(223 223 223);;
}
.Companynamedetails h4{
    font-size: 17pt;
    color: #216fb5;

}
.Companynamedetails img{
    width: 87px;
}
.aboutsection {
    width: 95%;
    /* height: 421px; */
    background-color: #f5f5f5;
    margin: 0 auto;
    border-radius: 15px;
    padding: 31px 23px;

}
.aboutsection h3{
    font-size: 18pt;
    color: #0166b3;
    margin-top: 19px;
}
.discriptioncompany span{
  color: #474747;
  font-size: 0.9rem;
  font-family: "Poppins";
}
.chickens{
    margin-top: 25px;
}
.chickens h2{
    color: #0166b3;
    font-size: 12pt;
    font-family: 'Poppins';
}
.companyheading{
    color: #818181;
    font-family: "Poppins";
    font-size: 15pt;
    margin-left: 36px;
    margin-top: 23px;
}
.viewpolicys{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    width: 95%;
    margin: auto;
}
.policycards{
    background-color: #f5f5f5;
    padding: 28px 24px;
    border-radius: 17px;
}
.policycards h4{
    color: #0166b3;
    font-family: "Poppins";
    font-size: 14pt;
}
.policycards span{
    color: gray;
    font-family: "Poppins";
}
.policycards a{
    font-family: "Poppins";
    color: #0166b3;
    font-size: 0.9rem;
}
.instructionsmaindivs{
    color: gray;
    font-family: "Poppins";
    font-size: 14pt;
    margin-left: 15px;
}
.headingdivas{
    width: 95%;
    margin: 20px auto;
    padding: 0 8px;
}
.headingdivas h3{
    color: #818181;
    font-family: "Poppins";
    font-size: 15pt;
}
.headingdivas span{
    color: gray;
    font-size: 0.9rem;
    font-family: "Poppins";
}
.clamepolicycompany{
    width: 95%;
    display: flex;
    justify-content: space-around;
    padding: 15px 0px;
    margin: 10px auto;
}
.classlessclame{
    width: 45%;
    background-color: whitesmoke;
    border-radius: 15px;
    padding: 15PX 0PX;
}
.reambusment{
    width: 45%;
    padding: 15PX 0PX;
    background-color: whitesmoke;
    border-radius: 15px;
}
.detailsdivs{
    width: 95%;
    /* height: 190px; */
    margin: 35px auto;
    padding: 5px 26px;
}
.classlessclame .headerclass{
    color: #818181;
    font-family: "Poppins";
    font-size: 15pt;
    margin-left: 44px;
}
.classlessclame .clamename{
    color: #1866b3;
    font-family: "Poppins";
    font-size: 15pt;
}
.numberingspan{
    color: #0166b3;
    font-family: "Poppins";
    font-size: "15pt";
    background-color: #e5e2e2;
    border-radius: 36px;
    padding: 4px 12px;
    font-size: 13pt;
}
.detailsdivs span{
    color: #474747;
    font-size: 0.9rem;
    font-family: "Poppins";
}




.reambusment .headerclass{
    color: #818181;
    font-family: "Poppins";
    font-size: 15pt;
    margin-left: 44px;
}
.reambusment .clamename{
    color: #1866b3;
    font-family: "Poppins";
    font-size: 15pt;
}


.companyinsurencerenual{
    width: 95%;
   padding: 15px 15px;
    border-radius: 15px;
    background-color: whitesmoke;
}
.renuallistdiv{
    width: 95%;
    /* height: 250px; */
    margin: 15px auto;
    padding: 27px 0px;
}
.renuallistdiv h3{
    color: #0166b3;
    font-family: "Poppins";
    font-size: 15pt;
}
.renuallistdiv span{
    color: #474747;
    font-size: 12pt;
    font-family: "Poppins";
}
.relatedpolicys{
    width: 95%;
    height: 250px;
    margin: 15px;
    border-radius: 15px;
    margin-right: 0px;
    display: flex;
    justify-content: start;
    align-items: center;

}

.relatedpolicyses{
    width: 200px;
    border-radius: 10px;
    background-color: whitesmoke;
    text-align: center;
    padding: 41px 0px;
    margin:  0 15px;

}

.relatedpolicyses img{
    width: 110px;
}
.relatedpolicyses span{
    color: #0166b3;
    font-size: 18pt;
    font-family: "Poppins";
    font-weight: 500;
}

@media screen and (max-width: 960px) {
    .maincompanydiv {
        width: 100%;
        margin: 10px auto;
        display: block;
        /* justify-content: center; */
    }
    .sildebarCompany {
        width: 95%;
        /* height: 820px; */
        margin: 0 15px;
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 25px 0px;
        position: relative;
    }
    .maincontentCompany {
        width: 100%;
        padding: 25px 0;
    }
    .companyinsurencerenual {
        width: 95%;
        padding: 15px 15px;
        border-radius: 15px;
        background-color: whitesmoke;
        margin: auto;
    }
    .menucompany {
        width: 95%;
        margin: 0 auto;
        display: flex;
    }
    .Companydetails {
        width: 95%;
        height: 280px;
        background-color: #f5f5f5;
        border-radius: 15px;
        /* position: sticky; */
        /* top: 37px; */
    }
    
    .menucompany {
        width: 95%;
        margin: 0 auto;
        display: flex;
        overflow: auto;
    }
    .deactiveclss {
        list-style: none;
        margin: 10px 0;
        border-radius: 10px;
        padding: 10px 13px;
        display: flex;
        justify-content: start;
        align-items: center;
        cursor: pointer;
        font-family: 'Poppins';
        width: 191px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Activeclass {
        list-style: none;
        background-color: #407bff;
        margin: 10px 0;
        border-radius: 10px;
        padding: 10px 17px;
        display: flex;
        justify-content: start;
        align-items: center;
        color: white;
        cursor: pointer;
        font-family: 'Poppins';
        width: 191px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .relatedpolicys {
        width: 95%;
        height: 250px;
        margin: 15px;
        border-radius: 15px;
        margin-right: 0px;
        display: flex;
        justify-content: start;
        align-items: center;
        overflow: auto;
    }
    .chickens h2 {
        color: #0166b3;
        font-size: 11pt;
        font-family: 'Poppins';
        margin: 15px 0px;
    }
    .detailsdivs {
        width: 95%;
        /* height: 190px; */
        margin: 35px auto;
        padding: 5px 2px;
    }
    .classlessclame .headerclass {
        color: #818181;
        font-family: "Poppins";
        font-size: 15pt;
        margin-left: 18px;
    }
    .reambusment .headerclass {
        color: #818181;
        font-family: "Poppins";
        font-size: 15pt;
        margin-left: 18px;
    }
}
@media screen and (max-width: 568px) {
    .clamepolicycompany {
        width: 95%;
        display: block;
        justify-content: space-around;
        padding: 15px 0px;
        margin: 10px auto;
    }
    .classlessclame {
        width: 100%;
        background-color: whitesmoke;
        border-radius: 15px;
        padding: 15PX 0PX;
    }
    .reambusment {
        width: 100%;
        padding: 15PX 0PX;
        background-color: whitesmoke;
        border-radius: 15px;
        margin: 15px auto;
    }

.menucompany {
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.Companydetails {
    width: 95%;
    height: 258px;
    background-color: #f5f5f5;
    border-radius: 15px;
}

.menucompany {
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.deactiveclss {
    list-style: none;
    margin: 10px 0;
    border-radius: 10px;
    padding: 10px 13px;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    font-family: 'Poppins';
    width: 209px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Activeclass {
    list-style: none;
    background-color: #407bff;
    margin: 10px 0;
    border-radius: 10px;
    padding: 10px 17px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    cursor: pointer;
    font-family: 'Poppins';
    width: 209px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.relatedpolicys {
    width: 95%;
    height: 250px;
    margin: 15px;
    border-radius: 15px;
    margin-right: 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    overflow: auto;
}
.chickens h2 {
    color: #0166b3;
    font-size: 11pt;
    font-family: 'Poppins';
    margin: 15px 0px;
}
}