.hlv-container {
    width: 79%;
    margin: auto;
    border-radius: 10px;
    margin-top: 47px;
}
.headingcolor{
    color: gray;
    font-family: Poppins;
    font-size: 22pt;
    font-weight: 500;
}
.headingback{
    color: #2959b8;;
}
.hlv-container p{
    color: gray;
    font-family: 'Poppins';
    margin-top: 27px;
}
.calculator-hlv {
    width: 100%;
    border: 1px solid #2959b8;
    margin: 10px auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.under-hlv{
    width: 100%;
    padding: 10px 10px;
}
.under-hlv p{
    margin-left: 15px; 
    position: relative;
    top: 24px;
    left: 44px;
    font-family: 'Poppins';
    color: #625f5f;
}
.section-lvh{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.slider-lvh{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.value-lvh{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.value-lvh input{
    color: #0166B3;
    background-color: #0166b325;
    width: 90px;
    height: 30px;
    outline: none;
    font-weight: 400;
    font-family: 'Poppins';
    border: none;
    padding: 0px 6px;
    border-radius: 4px;
}
.section-lvh-select{
    width: 90%;
    margin: 0 auto;
}
.calculatediv{
    width: 100%;
    margin: 0 auto;
    height: 80px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}
.calculatebutton{
    width: 151px;
    height: 50px;
    border-radius: 10px;
    font-family: 'Poppins';
    font-weight: 400;
}
.calculatemassage{
    width: 91%;
    margin: 15px auto;
}
.calculatemassage span{
    color: #3e3c3c;
    font-size: 9pt;
    font-family: 'Poppins';

}

.captchaSection{
    display: flex;
    justify-content: start;
    align-items: center;
    height: 24px;
}

.captchaSection button{
    width: 60px;
    background: transparent;
    color: #0166b3;
    font-size: 19px;
}
.genarateCaptcha {
    color: #0166b3;
    background-color: #0166b314;
    padding: 6px 41px;
    border-radius: 6px;
}
.Captachcode {
    border-radius: 5px;
    padding: 6px 5px;
    outline: none;
    border: 2px solid #a0a1a38c;
    width: 69% !important;
    color: #979797;
    font-family: 'Poppins';
}

.showData-Container{
    width: 95%;

    /* background-color: red; */
    margin: 5px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    animation: slideIn 1s forwards; 
}
.backdiv{
    width: 95%;
    margin: 0 auto;
}
.showData-Container img{
    width: 50%;
}
.divprimium{
    width: 50%;
}
.divprimium h2{
    color: #2959b8;
    font-size: 38pt;
    font-family: 'Poppins';
    font-weight: 500;
}
.divprimium button{
    background-color: #2959b8;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 0 10px;
}
.divprimium p{
    font-size: 15pt;
    font-family: 'Poppins';
    color: gray;
}
@keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }


  @media screen and (max-width:800px) {
    .hlv-container {
        width: 95%;
        margin: 10px auto;
        border-radius: 10px;
    }
    .calculator-hlv {
        width: 99%;
        border: none;
        margin: 10px auto;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .under-hlv p {
        position: relative;
        top: 21px;
        left: 0px;
        font-family: 'Poppins';
        color: #625f5f;
    }
    .section-lvh {
        display: block;
    }
    .under-hlv p{

    }
  }
  @media screen and (max-width: 803px) {
    .showData-Container{
        width: 95%;
        margin: 40px auto;
        display: block;
        text-align: center;
        animation: slideIn 1s forwards; 
    }
    .divprimium {
        width: 100%;
    }
  }
  .calculatebuttonse{
    width: 151px;
    height: 50px;
    border-radius: 10px;
    font-family: 'Poppins';
    font-weight: 400;
    background-color: #0166b3;
    color: white;
    font-family: 'Poppins';
    border: none;
}
  .calculatebuttondesable{
    width: 151px;
    height: 50px;
    border-radius: 10px;
    font-family: 'Poppins';
    font-weight: 400;
    background-color: #79bff4;
    color: white;
    font-family: 'Poppins';
    border: none;
  }