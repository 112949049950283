.carrermain{
  width: 100%;
  padding: 25px 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

}
.textareacarrer{
  width: 60%;
  animation: floatLeftToRight 2s forwards;
}

/* @keyframes floatLeftToRight {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
} */




.textareacarrer span{
  font-family: 'Poppins';
  color: gray;
  font-size: 11pt;
}
.textareacarrer h2 {
  color: #0266AE;
  font-family: 'Poppins';
  font-weight: 600;
  margin-bottom: 26px;
}
.imagecarrer{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

}


.imagecarrer img {
  width: 55%;
  animation: floatAnimation 3s infinite;
}
.carrermain .imagemodel{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carrermain .imagemodel img{
    width: 50%;
}
.carrermain .search{
    width: 50%;
}
.search .undersearch{
    width: 90%;
    margin: 0 auto;
}
.undersearch h5{
    color: #FBA71B;
    font-size: 3rem;
}
.undersearch p{
    color: gray;
    font-weight: 600;
    font-family: system-ui;
}
.searchdiv input{
     width: 75%;
     padding: 15px 10px;
    border-radius: 20px;
    border: 2px solid #0669B4;
}
.searchdiv button{
    position: relative;
    right: 0px;
    top: 0px;
    left: -58px;
    border: 1px solid #0669b4;
    background: #0669b4;
    border-radius: 28px;
    color: white;
    padding: 9px 14px
}

/* Opening Tab start Hear */
.opening {
  width: 1238px;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 38px;
}
.opening .jobsdiv {
  width: 101%;
  padding: 10px 10px;
  border-bottom-width: 1px;
  border-bottom-width: 6px;
  margin: 11px auto;
  border-radius: 10px;
  display: block;
  box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
}
 .microdis{
  font-size: 9pt;
  color: #0669B4;
  font-size: 9pt;
    color: #0669B4;
    margin-left: 15px;
 }

.discription{
  width: 100%;

  padding: 4px 25px;
}
.shoertdis{
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    /* background-color: red; */
}
.rolls{
  width: 100%;
  margin-left: 20px;
}
.rolls span {
  color: #0266AE;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15pt;
}
.discrip{
width: 100%;
padding: 10px 20px;

overflow: hidden;
}
.salaryamount{
  width: 100%;
  padding: 3px 20px;
}
.salaryamount span{
  color: gray;
  font-family: 'Poppins';
}
.discrip span{
  color: #7c7d80;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 9pt;
}
.Experince{
  width: 100%;

  padding: 0px 20px;
}
.Experince span{
  color: #7c7d80;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 9pt;
}
.applybuttons {
  width: 100%;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}
.applybuttons button{
  color: white;
  border: none;
  padding: 6px 12px;
  background-color: #4780ff;
  border-radius: 6px;
  font-family: 'Poppins';
  font-weight: 500;
}
.applybuttons a{
  font-family: "Poppins";
  color: #0669B4;
}
.innerHTML{
  font-family: "Poppins";
  color: #0669b4;
}
.modal-body span{
  font-family: "poppins";
}
.modal-body .job-title{
  color: #0669B4;
  font-family: "Poppins";
}
.labeltext{
  color: gray;
  font-family: 'Poppins';
}
.jobresponcebility{
  color: rgb(78, 76, 76);
  font-family: "Poppins";
}

.discription2 .jobcolor{
    color: rgb(75, 73, 73);
    display: flex;
    font-weight: bold;
    
}.discription2 .offer{
    margin: 0 5px;
    color: #0669b4;
}

.applydiv{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    /* background-color: red; */
    padding: 5px 15px;
}

.modal-body {
    display: grid;
    gap: 15px;
    padding: 20px;
  }
  
  .input-group {
    grid-column: span 2;
  }
  
  .jobinput {
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;

    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 20px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    margin: 0 auto;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(119, 109, 109, 0.94);
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #ced4da !important;
    border-radius: 0.5rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: "Poppins";
}

.error{
  margin: 0px;
  text-align: left;
  font-size: 10px;
  margin-left: 7px;
}
  
  input[type="datetime"] {
    /* Depending on your browser support, you might need additional styling for date inputs */
    width: 100%;
  }
  
  select {
    background-color: #f8f8f8;
  }
  
  input[type="file"] {
    grid-column: span 2;
    margin-top: 10px;
    outline: none;
  }
  .mashcordimg {
    position: absolute;
    right: -139px;
    width: 183px;
    top: 72px;
}




  .captcha-div{
    margin: 0px;
    background-color: aliceblue;
    width: 112px;
    border-radius: 13px;
    justify-content: space-around;
    font-size: 17px;
  }
  
  /* Add more styling as per your design needs */
  .resume{
    background-color: #2959B80F;
  cursor: pointer;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  padding: 18px 0px;
  position: relative;
  text-align: center;
  border: 0.5px dashed #2959B8;
  border-radius: 24px;
}
.upload-cv-file {
    background-color: #2959B80F;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 18px 0px;
    position: relative;
    text-align: center;
    border: 0.5px dashed #2959B8;
    border-radius: 24px;
    width: 90%;
    margin: 0 auto;
  }
  .discription{
    color: #0669B4;

  }
  .shoertdis p{
    font-size: 15px;
  }

  .carreermodel{
    width: 680px;
    padding: 0 25px;
  }

  .career-pop-inp{
    display: flex;
    width:100%;
  }

  .career-pop-inp-div{
    display: flex;
    flex-wrap: wrap;
  }

  .career-pop-inp-div input{
    margin: 4px;
  }

  












  @media screen and (max-width: 770px) {
    .opening .jobsdiv {
        width: 90%;
        padding: 15px 0;
        background-color: rgb(255, 255, 255);
        margin: 20px auto;
        border-radius: 10px;
        display: block;
        justify-content: space-evenly;
        align-items: center;
      }
      .experince{
 display: none;
    }
    .discription{
        width: 100%;
        display: contents;
    }
    .searchdiv input{
        width: 95%;
        padding: 8px 19px;
        border-radius: 7px;
        border: 2px solid #0669B4;
   }
   .carrermain{
    width: 100%;
    padding: 0px 0px;
    display: block;
}
.carrermain .search {
    width: 100%;
  }
  .imagemodel img{
    display: none;
  }
  .undersearch h5{
    color: #e09616;
    font-size: 21pt;
}
.undersearch p {
    color: gray;
    font-weight: 400;
    font-size: 12px;
    font-family: system-ui;
  }
  .shoertdis{
    width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;

  padding: 0 15px;
  }
  .shoertdis p{
    font-size: 10px;

  }
  }


  @media screen and (max-width:460px) {
    .carreermodel{
      margin-top: 121px;
    }

    .career-pop-inp{
      display: flex;
      flex-wrap: wrap;
    }

    .carreermodel{
      padding: 5px;
    }
  }


  .slary{
    font-size: 11pt;
    color: #423f3f;
    margin-left: 15px;
  }

  .jobs-grid {
    display: grid;
   
  }


  @media screen and (max-width: 1403px) {
    .carrermain {
      width: 100%;
      padding: 25px 95px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
  }
  .opening {
    width: 100%;
    border-radius: 15px;
    margin: auto;
    margin-bottom: 5px;
}
.opening .jobsdiv {
  width: 100%;

  padding: 10px 10px;
  background-color: rgb(41 89 184 / 9%);
  border-bottom-width: 1px;
  border-bottom-width: 6px;
  margin: 11px auto;
  border-radius: 10px;
  display: block;
}
  }
  @media screen and (max-width:731px){
    .carrermain{
      display: block;
      width: 100%;
      padding: 10px 10px;
    }
    .textareacarrer{
      width: 100%;
    }
    .imagecarrer{

      width: 100%;
        margin-top: 37px;
    }
    .textareacarrer span {
      font-family: 'Poppins';
      color: gray;
      font-size: 9pt;
    }
  }












  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: slideDown 0.5s ease-out;
}
  

.modal-contentcarrer {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 1151px;
  padding: 20px;
  height: 722px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

  
.modal-header {
  display: flex;
  align-items: center;
}
  
.jobtitletext{
  color: gray;
  font-size: 13pt;
  font-family:"poppins";
}
  
  
  .job-details {
    margin: 10px 0;
  }
  
  .applybtn-carrer {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 50px;
    padding: 15px;
    width: 100%;
}
  
  .applybtn-carrer:hover {
    background-color: #0056b3;
  }
  .modal-bodycarrer {
    overflow: scroll;
    overflow-x: hidden; 
    height: 567px;
}
.job-title {
  color: #0056b3;
  font-size: 15pt;
  font-family: "poppins";
  font-weight: 600;
}


.divs{
  height: 50px;
  width: 50px;
  background-color: red;
}
.applymeshcode-carrer {
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  width: 90%;
  position: relative;
}

.applyformcsscarrer-close {
  background-color: #007bff;
  color: white;
  font-size: 17pt;
  border-radius: 5px;
  border: none;
  padding: 1px 10px;
}
.applyformcsscarrer-button {
  color: white;
  background-color: #0056b3;
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 13px;
}
.applyheadertopcss{
  display: flex;
  justify-content: end;
  align-items: center;
}