.bluebord {
    border-right: 1px dashed  gray;
    margin: 25px 0px;
    border-radius: 1px;
}
.bluebord-img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bluebord-img img {
    width: 98%;
    object-fit: contain;
    aspect-ratio: 3 / 2;
}
.textstepper {
    width: 71%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px dashed gray;
    padding: 10px 0px;
}
.textstepper h3 {
    color: #ffffff;
    font-family: 'Poppins';
    padding: 5px 7px;
    font-size: 11pt;
    margin: 0 5px;
    border-radius: 100%;
}
.textstepper span{
    font-family: 'Poppins'; 
    color: gray;
}
.Numberdivses{
    background-color: #407bff;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}