.cardone {
    height: 247px;
    background-color: #0055a5;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0px 28px;
}

.cardtwo {
    height: 247px;
    background-color: #F96766;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0px 28px;
}
.flexdisplaydocrequird{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.cardone li {
    color: white;
    list-style: none;
    font-size: 13pt;
    margin: 16px 0px;
    font-weight: 600;
}

.cardtwo li{
    color: white;
    list-style: none;
    font-size: 13pt;
    margin: 16px 0px;
    font-weight: 600;
}

@media screen and (max-width:600px){
    .flexdisplaydocrequird {
        display: block;
    }
    
    
    .cardone {
        width: 100%;
        height: 221px;
        background-color: #0055a5;
        border-radius: 15px;
        display: flex;
        align-items: center;
        padding: 0px 5px;
        margin: 12px 0px;
    }
    .cardtwo {
        width: 100%;
        height: 204px;
        background-color: #F96766;
        border-radius: 15px;
        display: flex;
        align-items: center;
        padding: 0px 5px;
    }
    .cardtwo li {
        color: white;
        list-style: none;
        font-size: 10pt;
        margin: 16px 0px;
        font-weight: 600;
    }
    .cardone li {
        color: white;
        list-style: none;
        font-size: 10pt;
        margin: 16px 0px;
        font-weight: 600;
    }
   
    
    
}