/* Top head */
.topheader{
    width: 1249px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
}
.textmodel{
    width: 70%;
    text-align: center;
    animation: floatLeftToRight 2s forwards;
}

@keyframes floatLeftToRight {
    0% {
      transform: translateX(-10%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .textmodel h6 {
    color: #4857A6;
    font-size: 29pt;
    font-family: 'Poppins';
}

.textmodel span{
    color: gray;
    font-family: 'Poppins';
    font-size: 9pt;
}
.blogimages{
    width: 30%;
}
@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
.blogimages img{
    width: 100%;
}

.tabsclick{
    width: 1181px;
    margin: 0 auto;
    height: 50px;
    animation: floatLeftToRight 2s forwards;
}
.searchbar{
    margin-top: 15px;
    margin-left: 10px;
}
.searchbar input {
    border: 2px solid #0055a5;
    border-radius: 7px;
    width: 33%;
    caret-color: #0055a5;
    outline: none;
    padding: 10px 5px;
    margin: 0px 11px;
}
.tappdtabsdiv{
    display: flex;
    align-items: center;
    overflow: auto;
    width: 1380px;
}
.undertabs{
    display: flex;
}

.tabse {
    width: 103px;
    height: 50px;
    display: flex;
    justify-content: center;
    background-color: #4857A6;
    color: white;
    border-radius: 23px;
    align-items: center;
    font-family: 'Poppins';
    margin: 0 16px;
    cursor: pointer;
    transition: 0.3s;
}
.deactivtabs {
    width: 103px;
    height: 50px;
    display: flex;
    justify-content: center;
    background-color: #cbcdd157;
    color: white;
    border-radius: 23px;
    align-items: center;
    font-family: 'Poppins';
    margin: 0 16px;
    color: #0000008f;
    cursor: pointer;
}



@media screen and (max-width:827px) {
    .topheader{
        width: 100%;
        display: block;
   
    }
    .textmodel{
        width: 95%;
        margin: auto;
    }
    .blogimages{
        width: 100%;
    }
    .tabsclick{
        width: 100%;
        height: 143px;

    }
    .undertabs{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tabse{

        width: 103px;
        height: 50px;
        display: flex;
        justify-content: center;
        background-color: #407bff;
        color: white;
        border-radius: 23px;
        align-items: center;
        font-family: 'Poppins';
        margin: 0 16px;
        cursor: pointer;
        transition: 0.3s;
    
    }
    .deactivtabs{
        width: 103px;
        height: 50px;
        display: flex;
        justify-content: center;
        background-color: #cbcdd1;
        color: white;
        border-radius: 23px;
        align-items: center;
        font-family: 'Poppins';
        margin: 0 16px;
        color: #0000008f;
        cursor: pointer;
    }
    .tappdtabsdiv {
        display: flex;
        align-items: center;
        overflow: auto;
        width: 100%;
        height: 94px;
    }
    
    /* Hide the scrollbar for WebKit browsers (e.g., Chrome, Safari) */
    .tappdtabsdiv::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide the scrollbar for Firefox */
    .tappdtabsdiv {
        scrollbar-width: none;
    }
    
      .searchbar{
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .searchbar input{
        width: 95%;
        height: 60px;
        border-radius: 10px;
      }
}












.underhead{
    width: 70%;

}
.underhead .undertextdiv{
    width: 80%;
    margin: 0 auto;
}
.modaldiv{
    width: 30%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.modaldiv img{
    width: 50%;
}
.underdivh5{
    color: #2959B8;
    font-size: 25pt;
}

@media screen and (max-width: 640px) {
    .topheader{
        width: 100%;
        display: block;

    }
    .underhead{
        width: 100%;
    }
    .modaldiv{
        width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .modaldiv img{
        width: 80%;
    }
}








.blog-list-page {
    width: 100%;
    background-color: #ffffff;
    padding: 40px 0 60px;
}

.container {
    max-width: 1200px !important;
    padding: 0 15px !important;
}
.container-main{
    width: 100%;
}.textdiv{
    width: 50%;
}

.blog-banner-content {
    text-align: center;
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}
.blog-banner-content h1 {
    font-size: 56px;
    line-height: 58px;
    font-family: "Poppins";
    font-weight: bold;
    margin-bottom: 10px;
}
.blog-banner-content p {
    text-align: left;
}
.blog-banner-content p {
    color: #68768f;
    font-size: 16px;
    line-height: 26px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
}
.search-section {
    width: 100%;
    margin-top: -25px;
}


.search-box {
    max-width: 555px;
    margin: 0 auto;
    position: relative;
}

.search-box .form-group {
    margin: 0;
}
.search-box-inner {
    position: relative;
}
.search-box input.form-control {
    ont-size: 14px;
    line-height: 17px;
    font-family: "Poppins";
    font-weight: normal;
    padding-left: 20px;
    height: 65px;
    position: relative;
    bottom: 25px;
    /* margin-bottom: 15px; */
    background-color: #ffffff;
    color: #68768f;
    border: 1px solid #00000000;
    box-shadow: 0px 3px 30px #0000000f;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
}
.searchbutton{
    padding: 8px 13px;
    position: absolute;
    bottom: 20px;
    left: -9px;
    font-size: 15pt;
    border-radius: 23px;
    background-color: #2959b8;
    color: white;
    border: 1px solid #2959
}

.search-box .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
}
button.bg-none-icon {
    padding: 0;
    background: transparent;
    border: none;
}

.blog-tab-section {
    padding-top: 40px;
}

.our-blog-section {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 50px;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
.genaralul{
    width: 100%;
}


@media screen and (max-width: 1024px){
    
.topheader {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
}
.tappdtabsdiv {
    display: flex;
    align-items: center;
    overflow: auto;
    width: 100%;
}
.tabsclick {
    width: 100%;
    margin: 0 auto;
    height: 50px;
}
}
@media screen and (max-width: 807px) {
    .tabs{
        overflow: auto;

    }
    .genaralul li{
        margin: 0 15px;
        list-style: none;
        padding: 10px 2px;
    }
    .genaralul li a{
       height: 30px;
    }
    .tabbed-content .tabs li a {
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 600;
        line-height: 39px;
        color: #141414;
        padding: 12px 30px;
        box-shadow: 0px 3px 30px #00000029;
        border-radius: 24px;
        width: 100%;
        text-align: center;
        transition: all 0.5s;
    }
}


.searchblog{
    width: 100%;
    height: 90px;
    position: relative;
    top: 55px;
}

.searchblog input {
    display: flex;
    width: 50%;
    position: relative;
    left: 16px;
    bottom: -18px;
    padding: 15px 10px;
    border-radius: 20px;
    border: 2px solid #2959B8;
}

.call-body-adj{
    padding: 0 0px!important;
    margin: 0px;
}
@media screen and (max-width: 600px){
    .textmodel {
        width: 95%;
        margin: auto;
        text-align: left;
    }
    .textmodel h6 {
        color: #2959B8;
        font-size: 15pt;
        font-family: 'Poppins';
    }
}
@media screen and (max-width: 577px){
    .topheader {
        width: 100%;
        display: block;
        justify-content: space-around;
        align-items: center;
        margin: auto;
    }
    .textmodel {
        width: 90%;
        margin: auto;
        text-align: left;
    }
}