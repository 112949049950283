.cgCalculatorFlex {
    display: flex;
    justify-content: space-around;
    align-items: start;
    width: 1573px;
    margin: 15px auto;
}
.cgcalculator {
    width: 70%;
    border: 1px solid #0166B3;
    border-radius: 15px;
    padding: 19px 0px;
}
.textword {
    color: gray;
    font-family: 'Poppins';
    font-size: 16px;
    /* margin: -1px 0px; */
}
.mainclass{
    font-size: 16pt;
}

.cgcartone {
    padding: 12px 46px;
    position: relative;
}
.calculatebtn{
    width: 100%;
    height: 50px;
    color: white;
    background-color: #0166B3;
    border: none;
    border-radius: 10px;
}

.cgcartone p {
    color: #0166B3;
    margin-bottom: 0rem;
    font-weight: 600;
    position: relative;
    left: 13px;
}
.cgcalinput p{
    color: #0166B3;
    margin-bottom: 0rem;
    font-weight: 600;
    position: relative;
    left: 13px; 
}
.calculatorlist {
    width: 25%;
}

.cgcaldate {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 59px;
}
.undercgdate{
    width: 100%;
}
.undercgdate p{
    margin-bottom: 0rem;
}
.undercgdate p {
    color: #0166B3;
    font-weight: 500;
    font-size: 13pt;
}
.flexcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.disblebtn{
    width: 100%;
    height: 50px;
    color: white;
    background-color: #509fdb;
    border: none;
    border-radius: 10px;
}

.ddyymms {
    width: 100%;
    height: 65px;
    border: 1px solid #0166B3;
    border-radius: 5px;
    color: gray;
    font-family: 'DM Sans';
    text-transform: uppercase;
    outline: none;
    padding: 0px 10px;
}

.Warning{
    color: red;
    margin: 0px 15px;
}



@media screen and (max-width: 1569px){
    .cgCalculatorFlex {
        display: flex;
        justify-content: space-around;
        align-items: start;
        width: 100%;
        margin: 15px auto;
    }
}
@media screen and (max-width: 1009px) {
    .cgCalculatorFlex{
        display: block;
    }
    .cgcalculator {
        width: 95%;
        border: 1px solid #0166B3;
        border-radius: 15px;
        padding: 19px 0px;
        margin: auto;
    }
    .calculatorlist {
        width: 95%;
        margin: 10px auto;
    }
}
@media screen and (max-width: 600px) {
    .cgcaldate {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 11px 29px;
    }
    .cgcartone {
        padding: 5px 23px;
        position: relative;
    }

.flexcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
}
}