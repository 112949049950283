.label {
    background-color: rgb(214 214 214 / 51%);
    padding: 18px;
    color: #5a5a5aba;
    font-family: 'Poppins';
    border-radius: 10px;
    font-weight: 500;
    font-size: 13pt;
    margin-bottom: 8px;
}
.planeshowpage {
    border-radius: 17px;
    padding: 10px 12px;
    margin-top: 9px;
    border: 1px solid #80808054;
}

.qustionset{
    margin: 25px 0px;
}
.kyc-error-click{
    color: rgb(20 98 208 / 90%);
    text-decoration: underline;
    font-weight: 600;
}
.kyc-error-url{
    font-weight: 600;
    color: rgb(228 25 75);
    background-color: rgb(228 164 8 / 9%);
    padding: 10px;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 10px;
    border: 1px solid #a53b0b57;
    width: 100%;
    /* font-size: 17px;
/* } */
}

.pagehader_everyone {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.planesummerysidebar{
    width: 100%;
    background-color: rgb(0, 255, 106);
    height: 15px;
}
.planesummery {
    width: 67%;
}
.sidebarplane{
    width: 32%;
}
.proposarlable {
    color: #2959B8;
    font-size: 14pt;
    margin-bottom: 0;
}
.proposaremail{
    color: gray;
    font-size: 10pt;
}
.proposerlabel {
    color: #0265B3;
    font-size: 10pt;
    font-weight: 500;
}
.proposarvalue {
    color: rgb(46 45 45 / 60%);
    font-weight: 500;
    font-family: 'Poppins';
}
.customeline {
    border-bottom: 1px dashed gray;
    width: 100%;
    margin: auto;
}
.insuredmember{
    color: #0265B3;
    margin-top: 15px;
    font-size: 15pt;
}
.relation_tag{
    color: gray;
    font-size: 10pt;
}
.pagehader_everyone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2959b84a;
    padding: 9px;
    border-radius: 6px;
    margin-bottom: 10px;
}
.qustionsbyID{
    color: gray;
}
.mainqustion {
    color: #0265B3;
    font-weight: 500;
}
.childqustion {
    color: gray;
    font-size: 10pt;
    font-weight: 500;
}
.datetime {
    color: gray;
    border-bottom: 1px dashed;
}
.isselectedicici {
    color: #0265B3;
    background-color: transparent;
    border: 1PX solid #0265B3;
    border-radius: 5px;
    margin-left: 18px;
    margin-top: 13px;
}
.mainicici{
    margin-bottom: 20px;
}
.penicon{
    margin: 0px 3px;
}
.editlink{
    color: #0265B3;
    font-family: 'Poppins';
    font-weight: 600;
}



.kycformsummery1 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: 100px;
    left: 36px;
  }
  
  .kycformsummery2 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: 27px;
    left: 271px;
  }
  
  .kycformsummery3 {
    height: 74px !important;
    width: 262px;
    border-radius: 10px !important;
    top: -44px;
    left: 507px;
  }
  
  .kycformsummery4 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: -39px;
    left: 36px;
  }
  .kycformsummery5 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: -114px;
    left: 270px;
  }
  .kycformsummery6 {
    height: 74px !important;
    width: 262px;
    border-radius: 10px !important;
    top: -186px;
    left: 508px;
  }
  
  .kycformsummery7 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: -114px;
    left: 36px;
  }
  .kycformsummery8 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: -188px;
    left: 269px;
  }
  .kycformsummery9 {
    height: 74px !important;
    width: 262px;
    border-radius: 10px !important;
    top: -262px;
    left: 508px;
  }
  .kycformsummery10 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: -242px;
    left: 36px;
  }
  .kycformsummery11 {
    height: 74px !important;
    width: 215px;
    border-radius: 10px !important;
    top: -315px;
    left: 269px;
  }
  .kycformsummery12 {
    height: 74px !important;
    width: 268px;
    border-radius: 10px !important;
    top: -387px;
    left: 501px;
  }
  .skallitonsummerysecond {
    height: 256px !important;
    border-radius: 8px !important;
    bottom: 367px;
    left: 16px;
}
.topBorderSummerysecond {
    height: 55px !important;
    border-radius: 8px !important;
    bottom: 570px;
    left: 24px;
    width: 98%;
}
.topBorderSummerysecond1{
    height: 69px !important;
    border-radius: 8px !important;
    bottom: 570px;
    left: 24px;
    width: 120px;
}
.topBorderSummerysecond2 {
    height: 69px !important;
    border-radius: 8px !important;
    bottom: 637px;
    left: 157px;
    width: 120px;
}
.topBorderSummerysecond3 {
    height: 69px !important;
    border-radius: 8px !important;
    bottom: 706px;
    left: 292px;
    width: 120px;
}
.topBorderSummerysecond4 {
    height: 69px !important;
    border-radius: 8px !important;
    bottom: 775px;
    left: 434px;
    width: 120px;
}


@media (max-width: 768px) {
    .blockinmobile {
    display: block;
    }
    .sidebarplane {
        width: 97%;
        margin: auto;
    }
    .planesummery {
        width: 98%;
        margin: 5px auto;
    }
    .label {
        background-color: #28a7450f;
        padding: 18px;
        color: var(--success);
        font-family: 'Poppins';
        border-radius: 10px;
        font-weight: 500;
        font-size: 9pt;
        margin-bottom: 8px;
    }
    .proposaremail {
        color: gray;
        font-size: 9pt;
    }

  }
  