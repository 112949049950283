.bikestepbox {
  width: 95%;
  height: 250px;
  background-color: #ffc1077d;
  border-radius: 15px;
  margin: auto;
  padding: 15px;
  align-content: center;
}

.bikestepboxtwo {
  width: 95%;
  height: 250px;
  background-color: #3498db82;
  border-radius: 15px;
  margin: auto;
  margin: 10px 0px;
  align-content: center;
}
.bikestepbox li {
  margin: 10px 0px;
  list-style: none;
  font-weight: 500;
}
.bikestepboxtwo li{
    margin: 10px 0px;  
    list-style: none;
    font-weight: 500;

}
.table-container {
    overflow-x: auto; /* Enables horizontal scrolling for smaller screens */
  }
  
  .bikeinsurancetable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .bikeinsurancetable th, .bikeinsurancetable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    color: rgb(122 119 119);
    font-size: 13pt;
    font-family: 'Poppins';
}
  
  .bikeinsurancetable th {
    background-color: #2959B8;
    color: white;
    font-family: 'Poppins';
}
  
  .bikeinsurancetable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .bikeinsurancetable tr:hover {
    background-color: #ddd;
  }
  .bikestepperdivnew{
    width: 100%;
  }

  .bikestepperwholediv {
    width: 85%;
    height: 51px;
    border-radius: 25px;
    background-color: #e4e4e4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px 0px;
    cursor: pointer;
}
.bikestepperwholedivactive {
  width: 85%;
  height: 51px;
  border-radius: 25px;
  background-color: #2959B8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 19px 0px;
  cursor: pointer;
  color: white;
}

.bikereadmore{
  height: 200px;
  overflow: hidden;
}
.boxtabuler {
  width: 100%;
  border: 1px solid #8080807a;
  border-radius: 5px;
  margin-bottom: 21px;
}
.overflowbikeinsurence{
  width: 100%;
  overflow: auto;
}
.scrollingtabulerbox {
  display: flex;
  align-items: center;
  width: 1099px;
  overflow-x: auto;
}

/* Custom scrollbar for webkit browsers */
.scrollingtabulerbox::-webkit-scrollbar {
  height: 0px; /* Adjust height to 1px for a slimmer scrollbar */
}

.scrollingtabulerbox::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.scrollingtabulerbox::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.scrollingtabulerbox {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
  height: 66px;
}

.tabpaddingbike{
  padding: 15px;
}

.activetabsbike {
  height: 43px;

  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  padding: 5px 8px;
  cursor: pointer;
  text-align: center;
  font-size: 13pt;
  background-color: #f967665e;
  color: #0055a5;
  font-weight: 600;
}
.deactivetabsbike {
  height: 43px;

  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  padding: 5px 8px;
  cursor: pointer;
  text-align: center;
  font-size: 13pt;
  background-color: transparent;
  color: #0055a5;
  font-weight: 600;
}







.bikestepnameactive {
  color: white;
  width: 88%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 17px;
  font-size: 13pt;
}
.numberdivbikeinsurence {
  width: 11%;
  height: 51px;
  background-color: var(--yellow);
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16pt;
}

.bikestepname {
  color: gray;
  font-size: 13pt;
  color: #545050;
  width: 88%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 17px;
}
.headerspan_bike {
  color: #0055a5;
  font-weight: 500;
}





@keyframes slideDown {
  0% {
      opacity: 0; /* Fully transparent at the start */
      transform: translateY(-20px); /* Move up */
  }
  100% {
      opacity: 1; /* Fully opaque at the end */
      transform: translateY(0); /* Move to original position */
  }
}
.descriptiondivbikestepper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  /* margin: auto; */
  padding: 0px 15px;
  opacity: 0; /* Start invisible */
  animation: slideDown 0.5s forwards; 
}
@media screen and (max-width: 768px){
  .bikestepbox {
    width: 95%;
    height: 323px;
    background-color: #ffc1077d;
    border-radius: 15px;
    margin: auto;
    padding: 0px;
    align-content: center;
}
.bikestepboxtwo {
  width: 95%;
  height: 323px;
  background-color: #3498db82;
  border-radius: 15px;
  margin: auto;
  margin: 10px 0px;
  align-content: center;
}
}
@media screen and (max-width: 600px){
  .bikestepbox{
   height: 300px;
  }
  .bikestepboxtwo {
    width: 95%;
    height: 300px;
    background-color: rgb(148, 255, 127);
    border-radius: 15px;
    margin: auto;
    margin: 10px 0px;
    align-content: center;
}
.bikestepperwholediv {
  width: 100%;
  height: 51px;
  border-radius: 25px;
  background-color: #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 19px 0px;
  cursor: pointer;
}
.bikeinsurancetable th, .bikeinsurancetable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  color: rgb(122 119 119);
  font-size: 11pt;
  font-family: 'Poppins';
}
.bikeinsurancetable th {
  background-color: #2959B8;
  color: white;
  font-family: 'Poppins';
  font-size: 11pt;
}
.bikestepname {
  color: gray;
  font-size: 11pt;
  color: #545050;
  width: 87%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
}
.numberdivbikeinsurence {
  width: 13%;
  height: 51px;
  background-color: var(--yellow);
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16pt;
}
}