.understatndpolicy{
    width: 100%;
    max-width: 1550px;
    margin: 15px auto;
}
.headerunderstand {
    width: 100%;
    /* text-align: center; */
    line-height: 30px;
    margin: 0 auto;
}


.headerunderstand span{
    color: #2959b8;
    font-family: "Poppins";
    margin: 10px 0;
}
.headerunderstand h3{
    color: #828385;
    font-family: "Poppins";
}
.headerunderstand p {
    color: #9a968f;
    font-family: "poppins";
    font-size: 12pt;
}
.policytagse {
    width: 60%;
    margin: auto;
    background-color: #ebebeb;
    padding: 13px 25px;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 25px auto;
}
.policytagse select{
    width: 25%;
    background-color: transparent;
outline: none;
    border-right: 1px solid gray;
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-radius: 0px;
    font-family: "Poppins";
}
 .policycheckbutton{
    /* width: 10%; */
    color: white;
    background-color: #2959b8;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-family: "Poppins";
}
.policyimagemotal{
    margin: 0 auto;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.policyimagemotal img{
    width: 50%;
}
@media screen and (max-width:786px) {
    .policytagse {
        width: 95%;
        margin: auto;
        background-color: #ffffff;
        padding: 13px 25px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }
        .policytagse select {
            width: 100%;
            background-color: transparent;
            outline: none;
            border: 1px solid #0166b3;
            border-radius: 9px;
            font-family: "Poppins";
            margin: 5px 0px;
            padding: 10px 7px;
            color: #0166b3;
        }

    .policycheckbutton {
        width: 100%;
        color: white;
        background-color: #2959b8;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        font-family: "Poppins";
        margin: 15px auto;
    }
    .headerunderstand {
        width: 97%;
        text-align: center;
        line-height: 30px;
        margin: 0 auto;
    }

.policyimagemotal {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.policyimagemotal img {
    width: 82%;
}
      
}