.heades{
    width: 100%;
    height: 180px;
   /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.heades h1{
    color: #0166B3;
    font-size: 30pt;
    text-align: center;
}
.heades .middleone{
    color: #FBA71B;
}
.heades .lastone{
    color: #EE2124;
}
.heades .underheader p{
    color: gray;
    font-size: 10pt;
    text-align: center;
}
/* Terms and Condition start Hear */


.termsandcondition{
    width: 99%;
    border-radius: 15px;
    padding: 20px 0;
    margin: 0px auto;
    background-color: #0166b310;
}
.termsandcondition .insuredetail{
    width: 80%;
    margin: 0 auto;
    /* background-color: red; */
    position: relative;
    top: 20px;
}

.termsandcondition .insuredetail .insureh3{
    color: #0166B3;
    margin: 18px 0;
    font-size: 16pt;
}
.margintermsnadpolicy{
    padding-top: 95px;
}