

.eaditprofile{
    width: 992px;
    margin: 15px auto;
    border-radius: 15px;
    padding: 20px 80px;
    box-shadow: 0px 1px 6px 0px #0166
}
.userimage{
    margin: auto;
    height: 124px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.editlable{
    color: #0166b3;
    font-family: 'Poppins';
    font-size: 11pt;
}
.addressdivs{
    display: block;
}
.addressdivs .district{
    color: #0166b3;
    font-size: 10pt;
}
.userimage img {
    width: 67px;
}
.userimage span{
    color: gray;
    font-size: 19pt;
    margin: 0 17px;
    font-family: 'Poppins';
}
.editinput{
    outline: none;
    font-family: 'Poppins';
    border-radius: 5px;
}
.divadd{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}
.divadd button{
    padding: 15px 25px;
    width: 40%;
}

.userimage img{
    border-radius: 15px;
  }

@media screen and (max-width:1000px){
    .eaditprofile{
        width: 90%;
        margin: 15px auto;
        border-radius: 15px;
        padding: 20px 80px;
        box-shadow: 0px 1px 6px 0px #0166
    }
    
}
@media screen and (max-width: 581px) {
    .eaditprofile{
        width: 90%;
        margin: 15px auto;
        border-radius: 15px;
        padding: 2px 20px;
    }
    .divadd button{
        padding: 15px 25px;
        width: 95%;
    }


    
    .userimage{
        margin: 73px auto;
        display: block;
 text-align: center;

    }
    .userimage img {
        border-radius: 15px;
        width: 99px;
    }

}