.heathmaincompo{
    width: 1350px;
    padding: 55px 0;
    /* border: 1px solid gray; */
    margin: 15px auto;
    display: flex;
    justify-content: space-around;
    margin: auto;
}
@media screen and (max-width: 1500px) {
    .heathmaincompo{
        width: 90%;
        padding: 55px 0;
        /* border: 1px solid gray; */
        margin: 15px auto;
        display: flex;
        justify-content: space-around;
        margin: auto;
    }
}
.slidebarse{
    width: 20%;

}
.maincomtents{
    width: 65%;
   padding: 0 15px;
}
.deactiveclss {
    list-style: none;
    margin: 10px 0;
    border-radius: 10px;
    padding: 10px 13px;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    font-family: 'Poppins';
    color: rgb(122, 119, 119);
    font-size: 13pt;
}
.Activeclass {
    list-style: none;
    background-color: #0166b3;
    margin: 10px 0;
    border-radius: 10px;
    padding: 10px 17px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 13pt;
}
.Activeclass a{
    color: rgb(255, 255, 255);
    font-family: 'Poppins';
    cursor: pointer;

   }
   .deactiveclss a{
    color: rgb(255, 255, 255);
    font-family: 'Poppins';
    cursor: pointer;
   }

.hero-blockcenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

   .heading{
    width: 100%;
   text-align: center;
   }
   .heading h3{
    font-family: 'Poppins';
    color: rgb(155, 149, 149);
   }
   .sectionone {
    width: 100%;
    margin: 15px auto;
    border-radius: 13px;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
    padding: 15px 25px;
}
.sectiononeimg{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .sectiononeimg img{
    width: 40%;
   }
   .sectiononetext p {
    font-family: 'Poppins';
    font-size: 13pt;
    color: rgb(122, 119, 119);
}
   .slidebarse span{
    color: gray;
    font-weight: 600;
    font-family: "Poppins";
   }
   .sectiononeimg iframe{
    width: 70%;
    height: 320px;
    margin: 10px 0;
   }
   .underslidebar{
    position: sticky;
    width: 275px;
    bottom: 663px;
    top: 10px;
   }
   .sectiononetext h3 {
    color: #2959b8;
    font-family: 'Poppins';
    margin: 10px 0;
}

@media screen and (max-width: 1004px) {
    .heathmaincompo {
        width: 100%;
        padding: 55px 0;
        display: block;
    }
    .slidebarse{
        width: 100%;
        overflow: auto;
        scrollbar-width: none;

    }
    .underslidebar {
        position: sticky;
        width: 2526px;
        margin: 15px 0px;
        display: flex;
    }
    .underslidebar a {
        margin: 0 8px;
        padding: 9px;
    }
    .deactiveclss{
        padding: 10px 5px;
        font-size: '15pt';
        font-family: 'Poppins';
        color: 'rgb(122, 119, 119)';
    }
    .maincomtents {
        width: 100%;
        padding: 0 15px;
    }
    .sectiononeimg img {
        width: 96%;
    }
    .sectiononetext{
      width: 95%;
      margin: 0 auto;
    }
    .sectiononeimg iframe {
        width: 95%;
        height: 320px;
        margin: 10px 0;
    }
    .sectiononetext h3 {
        color: #2959b8;
        font-family: 'Poppins';
        margin: 17px 0;
    }
    
}

@media screen and (max-width: 600px){
    .coPaymentTable-headerCell {
        border: 1px solid white;
        /* padding: 8px; */
        text-align: left;
        background-color: rgb(41, 89, 184);
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 10pt;
    }
    .coPaymentTable-cell {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
        font-size: 11pt;
        font-family: 'Poppins', sans-serif;
        color: rgb(122, 119, 119);
    }
    .sectiononetext h3 {
        color: #2959b8;
        font-family: 'Poppins';
        margin: 17px 0;
        font-size: 14pt;
    }
    .sectiononeimg img {
        width: 80%;
    }
    .sectionone {
        width: 100%;
        margin: 15px auto;
        border-radius: 13px;
        box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
        padding: 15px 25px;
    }
    .sectionone {
        width: 100%;
        margin: 15px auto;
        border-radius: 13px;
        box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
        padding: 15px 25px;
    }


}