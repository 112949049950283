.importenthead {
    width: 90%;
    max-width: 1200px !important;
    /* border: 1px solid red; */
    margin: 4px auto;
    /* padding: 2px 10px; */
}
.colorbottom{
    width: 82%;
    height: 1px;
    background-color: #ffbd2f;
    border: 1px solid #ffbd2f;
}
.textheads h3 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #ed3543;
    letter-spacing: 7.4px;
    background: #ed35431a 0% 0% no-repeat padding-box;
    border-radius: 0.45rem;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.imageimportent{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageimportent img{
    width: 70%;
}


@media screen and (max-width:1200px) {

    .textheads h3{
      font-size: 30px!important;
    }
  
  }
  
  @media screen and (max-width:760px) {
  
    .textheads h3{
      font-size: 27px!important;
    }
  
  }
.underimportent{
    display: flex;
    justify-content: space-around;

}


.importenttext {
    width: 100%;
    padding: 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.importenttext span {
    color: rgb(95, 93, 93);
    font-family: "Poppins";
    font-size: 12pt;
    line-height: 27px;

}
.importentimage{
    width: 50%;
}

.importentimage img {
    width: 100%;
}
.importentul {
    margin: 15px 0px;
    padding: 0px 0px 0px 0px;
}
.importentul li{
   list-style-type: square;
}
.importentul li b{
    color: #2959B8;
    font-family: "Poppins";
    font-weight: 500;
}
.importentul li span{
    color: gray;
}
@media screen and (max-width: 820px){
    .importenthead {
        width: 100%;
        margin: 4px auto;
        padding: 2px 10px;
    }
    .colorbottom{
        width: 95%;
        height: 1px;
        background-color: #ffbd2f;
        border: 1px solid #ffbd2f;
    }
}
@media screen and (max-width:600px){
    .theheadingcolor {
        color: #2959B8;
        margin: 10px 0;
        font-weight: 600;
        font-family: 'Poppins';
        font-size: 16pt;
    }
}