.navbartabs{
    width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: auto;
   margin-bottom: 25px;

}
.navbartabs ul{
    width: 50%;
}
.textcenter{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
}
.showmorefaq {
    color: #ffffff;
    background-color: #2959b8;
    border: none;
    border-radius: 5px;
    padding: 13px 23px;
    font-weight: 600;
    font-family: 'Poppins';
}
.navbartabs .tablies{
    width: 101px;
    height: 50px;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: rgb(10, 10, 10);
    font-weight: 500;
    cursor: pointer;
}
.navbartabs .activetabses{
    width: 101px;
    height: 50px;
    background-color: #2959b8;
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}




.tabnameactive {
    width: 120px;
    height: 50px;
    /* background-color: #2959b8; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    /* border-radius: 6px; */
    color: #2959b8;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 4px solid;
}
.tabnamedeactive {
    width: 120px;
    height: 50px;
    /* background-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 6px;
    color: #000000b5;
    font-weight: 500;
    cursor: pointer;
}




@media screen and (max-width: 833px) {

    @media screen and (max-width: 833px) {
        .navbartabs {
            width: 784px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;

            /* overflow: scroll; */
        }
        .overflowscrollcontainer{
            overflow: scroll;
            margin-bottom: 25px;
        }
    }
    .navbartabs .tablies{
        width: 101px;
        height: 50px;
        background-color: #cacaca;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: rgb(10, 10, 10);
        font-weight: 500;
        cursor: pointer;
    }
}



.faqsection {
    width: 100%;

    display: flex;
    justify-content: space-evenly;
}

.pageFAQ {
    display: block;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 5%), 0px 6px 20px rgb(0 0 0 / 8%);
    border-radius: 15px;
    padding: 20px;
    background-color: white;
    margin: 9px;
    width: 100%;
}
.sidebar-faq {
    position: sticky;
    top: 92px;
    overflow-y: auto;
    padding: 10px;
    width: 23%;
}

  .gurdpage {
    flex-grow: 1; /* Take the remaining width */
    padding: 20px;
    width: 75%;
  }


.activeclass {
    background-color: #2959b8;
    list-style: none;
    padding: 10px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
}
.deactiveactiveclass {
    background-color: #e7e9ed85;
    list-style: none;
    padding: 10px;
    border-radius: 8px;
    color: #938d8d;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
    font-size: 11pt;
    width: 100%;
}
.smallqustion{
    font-size: 9pt;
    color: #6e6a6a;

}
.activesmallqustion{
    font-size: 9pt;
    color: #2959b8;
}
.anwsar {
    margin-bottom: 0;
    color: #645d5d;
    font-family: 'Poppins';
}
.catagorytab {
    display: block;
    padding: 22px 4px;
    border-radius: 13px;
    background-color: #f5f5f561;
}


.listcatagory {
    border-radius: 8px;
    margin: 4px 6px;
    font-weight: 500;
    padding: 12px;
    cursor: pointer;
    background-color: #2959b8;
    color: white;
    list-style: none;
    font-size: 11pt;
    transition: all 0.3s ease;
}
.delistcatagory {
    border-radius: 8px;
    margin: 4px 6px;
    font-weight: 400;
    padding: 12px;
    cursor: pointer;
    color: #615e5e;
    list-style: none;
    font-size: 11pt;
}
.catagoryimage {
    width: 20px;
    margin-right: 10px;
    transition: all 0.3s ease;
}
.faqqustion {
    color: #2959b8;
    list-style: none;
    font-size: 11pt;
}


.faqtext {
    color: #676262;
    font-size: 11pt;
    margin-bottom: 0;
}
.headercatagory{
    display: flex
    ;
        justify-content: center;
        align-items: center;
        list-style: none;
}


.headerlist {
    margin-left: 17px;
    border-bottom: 3px solid #2959b8;
    padding: 10px;
    width: 6%;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2959b8;
}
.headerlistnone {
    margin-left: 17px;
    padding: 10px;
    width: 6%;
    text-align: center;
    cursor: pointer;
    color: #6a6969;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainbtnfaqallqustion{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px ;
}
.mainbtnfaqallqustion button{
    color: white;
    background-color: #2959b8;
    font-weight: 500;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

@media screen and (max-width: 1200px){
    
    .faqsection{
        display: block;
    }
    .sidebar-faq {
        position: relative;
        top: 0px;

        width: 100%;
    }
    .gurdpage {
        flex-grow: 1;
        padding: 10px;
        width: 100%;
    }
    .headercatagory {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
    }
    .headerlist {
        margin-left: 0px;
        border-bottom: 3px solid #2959b8;
        padding: 10px;
        width: 0%;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        display: flex
    ;
        justify-content: center;
        align-items: center;
        color: #2959b8;
    }
    .headerlistnone {
    margin-left: 17px;
    padding: 10px;
    width: 10%;
    text-align: center;
    cursor: pointer;
    color: #6a6969;
    font-weight: 500;
    display: flex
;
    justify-content: center;
    align-items: center;
}
}