.slide-in-panel {
  position: fixed;
  top: 0;
  

  width: 60vw;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-out;
  z-index: 1000; /* Ensure panel is above other content */
  overflow-y: scroll;
  max-width: 1200px!important;
}

.slide-in-panel.open {
  right: 0; /* Slide in to the screen */
}

.toggle-button {
    position: relative;
    /* top: 10px; */
    right: 10px;
    padding: 4px 9px;
    background-color: #007bff;
    margin-top: -5px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 17px;
    z-index: 1100;
    height: 32px;
}

.panel-content {
  /* padding: 20px; */
}
 .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 900; 
  opacity: 0.5;
} 


.panel-heading{
    background-color: aliceblue;
    padding: 8px;
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    justify-content: space-between;
    padding-left: 14px;

}
.panel-heading h3{
    font-size: 15pt;
    text-shadow: 0 0 black;
    color: #2959b8;
}



.panel-heading-img{
    height: 30px;
}


.panel-plan-logo{
    height: 56px;

}

.panel-body{
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.plan-description{
    display: flex;
    flex-direction: row;
}
.panel-plan-img{
  height: fit-content;
    border: 1px solid  #80808038;
    text-align: center;
    width: fit-content;
    padding: 9px;
    border-radius: 10px;
    background-color:#faebd717;
}

.panel-plan-detail{
    display: flex;
    width: 100%!important;
    flex-direction: row;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
 
}

.plan-para{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.plan-para p{
    margin-bottom: 0px;
    margin-left: 14px;
    color: rgb(168, 160, 160);
}


.plan-para h3{
    font-size: 17pt;
    text-shadow: 1px 1px 1px #00000078;
    color: rgb(136 127 127);
    margin-left: 10px;
    margin-bottom: 2px;
}

.plan-cover{
    background-color: #8080802e;
    padding: 9px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    align-items: center;
    border-radius: 7px;
}

.plan-cover h4{
    font-size: 13pt;
    margin-right: 10px;
    margin-bottom: 0px;
    color: #08085e;
}

.plan-cover p{
    margin-bottom: 0px;
    margin-top: 0px;
    color: #fca84c;
    text-shadow: 1px 1px 1px #000000;
}

.panel-body{
    display: flex;
    flex-direction: column;
}
.panel-breakup{
    width: 100%;
    /* height: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    background-color: antiquewhite;
    padding: 8px;
    border-radius: 10px;
    width: fit-content;
}

.panel-hr{
    width: 100%;
}

.panel-premium{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.panel-sections{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.panel-sec-hr{
    margin-top: 0px;
    width: 100%;
}

.panel-lower-body{
    display: flex;
    flex-direction: column;
}

.panel-sections p{
 
    margin-right: 50px;
    background-color: #80808033;
    padding: 5px;
    border-radius: 40px;
    min-width: 170px;
    text-align: center;
    color: #857b7b;   
}
.panel-sections p:focus{
    background-color: grey;
    color: white;
}
.button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-top: -14px;
    flex-wrap: wrap;
  }
  
  .toggle-button-panel {
    cursor: pointer;
    padding: 10px;
    margin: 5px 0;
    border: none;
    background-color: rgb(168 152 152 / 33%);
    border-radius: 10px;
    color: grey;
    font-size: 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    min-width: 169px;
    padding: 5px;
    justify-content: center;
  }
  
  .toggle-button-panel.clicked {
    background-color: #f0a500; /* Example color, change as needed */
    color: white;
  }
  
  .panel-lower-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    background-color: #f2f5fb;
    border-radius: 20px;
    padding: 11px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap;
  }

  .lower-opt{
    border-radius: 10px;
    border: 1px solid #80808057;
    color: #0055a5;
    text-shadow: 1px 1px #0055a56b;
    padding: 8px;
    background-color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
  
  }

  .lower-opt p{
        /* margin-bottom: 1rem; */
        margin: 0px;
        border-radius: 10px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        /* text-overflow: ellipsis; */
        min-width: fit-content;
        display: inline-block;
        vertical-align: text-bottom;
      
  }

  .lower-opt.selected {
    position: relative;
    height: fit-content;
    width: fit-content;
    border-radius: 10px;
    border: 1px solid #80808057;
    color: white;
    text-shadow: 1px 1px #0055a56b;
    padding: 8px;
    background-color: #0055a5; /* Change this color as needed */
   
  }

  .right-img-panel-div{
    display: flex;
  }

  .right-img-panel-div h3{

    font-size: 20px;
    font-family: 'Poppins';
    color: #625c5c;
  }
  
  .right-img-panel{
    height: 20px;
    margin: 5px;
  }
.cover-question{
    margin-top: 20px;
}

.right-img-panel-p{
    /* margin-left: 30px; */
    color: #807575;
}

.cover-question-hr{
    width: 70%;
    display: flex;
    justify-content: left;
    margin-left: 30px;
    margin-top: 5px;
}










.cover-question {
    /* padding: 20px; */
  }
  
  .right-img-panel-div {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 10px;
    /* background-color: #e4800714; */
    margin-bottom: 10px;
    /* box-shadow: 0 3px 4px 0 rgb(0 0 0 / 15%), 0 4px 30px 0 rgb(0 0 0 / 9%); */
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%), 0 2px 10px 0 rgb(0 0 0 / 9%);
    margin-bottom: 15px;
  }
  
  .right-img-panel-div h3 {
    margin-left: 10px;
    flex-grow: 1;
    display: flex;
    margin-bottom: 2px;
    color: #808080fc;
    font-size: 15px;
    font-family: 'Poppins';
    color: #625c5c;
    font-weight: 600;
  }
  
  .right-img-panel {
    margin-right: 10px;
  }
  
  .expanded-content {
    padding: 1px;
    padding-left: 47px;
    background-color: aliceblue;
    margin-top: 1px;
    border-radius: 5px;
  }
  
  .right-img-panel-p {
    margin-top: 10px;
  }
  
  .expanded-ul{
    padding-left: 0px;
    list-style: none;
  }

  .feature-arrow-div{
    display: flex;
    align-items: baseline;
  }

  .feature-arrow{

    font-size: 25px;
    color: #8080808a;
  }

  .cover-question >h3{
    font-size: 20px;
    color: #f0a500;
    text-shadow: 1px 1px #151515a8;
  }


  


  .panel-lower-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap; /* Ensure items are on a single line */
    gap: 16px;
    background-color: #f2f5fb;
  
    border-radius: 20px;
    padding: 11px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap;
    min-width: 300px;
    /* overflow-x: scroll; */
   
  }
  
  /* Scrollbar styles for WebKit browsers (Chrome, Safari) */
  .panel-lower-options::-webkit-scrollbar {
    height: 3px; /* Height of the horizontal scrollbar */
  }
  
  .panel-lower-options::-webkit-scrollbar-thumb {
    background: #0055a5; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
    
  }
  
  .panel-lower-options::-webkit-scrollbar-track {
    background: #f2f5fb; /* Background of the scrollbar track */
    border-radius: 10px; /* Roundness of the scrollbar track */
 
  }
  
  /* Scrollbar styles for Firefox */
  .panel-lower-options {
    /* scrollbar-color: #0055a5 #f2f5fb;  */
    /* Color of the scrollbar thumb and track */
    /* scrollbar-color: #bcd7f0 #f2f5fb; */
    scrollbar-width: thin; /* Width of the scrollbar */
  }
  
  .lower-opt {
    border-radius: 10px;
    border: 1px solid #80808057;
    color: #0055a5;
    text-shadow: 1px 1px #0055a56b;
    padding: 8px;
    background-color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .lower-opt p {
    margin: 0px;
    border-radius: 10px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    min-width: fit-content;
    vertical-align: text-bottom;
  }
  

  .active-question{
    background-color: #0055a5;
    border-radius: 10px;
    
  }

  .active-question h3{
    color: white;
  }



  /* --------------------------- for button container -------------------------------------- */
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* background-color: #f2f5fb; */
    background: none;
    border-radius: 20px;
    padding: 11px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap;
    flex-wrap: nowrap;
    /* justify-content: center; */
  }
  
  .button-wrapper {
    display: flex;
    gap: 16px;
    min-width: max-content; /* Ensure wrapper width accommodates all children */
  }
  
  /* Scrollbar styles for WebKit browsers (Chrome, Safari) */
  .button-container::-webkit-scrollbar {
    height: 3px; /* Height of the horizontal scrollbar */
  }
  
  .button-container::-webkit-scrollbar-thumb {
    background: #0055a5; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
  }
  
  .button-container::-webkit-scrollbar-track {
    background: #f2f5fb; /* Background of the scrollbar track */
    border-radius: 10px; /* Roundness of the scrollbar track */
  }
  
  /* Scrollbar styles for Firefox */
  .button-container {
    /* scrollbar-color: #f48e1e73 #f2f5fb; */
     /* Color of the scrollbar thumb and track */
    scrollbar-width: thin; /* Width of the scrollbar */
  }
  


  .cashless-heading{
    font-size: 13pt;
    background-color: #a9a9a975;
    padding: 12px;
    border-radius: 4px;
    border-bottom-right-radius: 75px;
    background-color: #D61A3C;
    color: white;
    font-weight: 700;
  }

  .cashless-search{
    display: flex;
    flex-direction: column;

  }

  .cashless-search label{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

 .cashless-upper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 }

 .cashless-location{
  display: flex;
  align-items: center;
  height: fit-content;

  padding: 6px;
  border-radius: 10px;

  margin-top: 0px;
 }

 .cashless-location-icon{
  color: #f70000;
  font-size: 31px;
  margin-bottom: 9px;
 }

 .cashless-location p{
  margin-bottom: 0px;
  font-size: 17px;
  /* margin-top: 5px; */
  /* text-decoration: underline; */
  color: #86888a;
  font-weight: 600;
  font-size: larger;
  width:fit-content

 }

 .cashless-search input{
  margin-top: -10px;
 }

 .cashless-lower-para{
  width: fit-content;
 }

 .cashless-lower-para > hr{
  margin-top: -9px;
  border: 1px solid #d6cfcf;
 }

 .cashless-lower-para span{
  color: green;
  font-weight: 600;
 }

 .cashless-questions{
  width: 22px;
  height: 22px;
  margin-top: 3px;
 }

 .cashless-hospital-name{
  display: flex;
  flex-direction: row;
 }

 .cashless-hospital-name p{
  margin-left: 10px;
  font-size: 12pt;
  color: #776a6a;
  font-weight: 500;
 }

 .cashless-hospital-address {
  font-size: 10pt;
  margin-top: -10px;
  margin-left: 10px;
  color: grey;
  margin-bottom: 20px;

 }

 .cashless-hospital{
 padding-left: 0px;
 }

 .cashless-hospital-icon{
  font-size: 26px;
  margin-right: 5px;
  color: #D61A3C;
 }

 /* ------------------------------faq starting -------------------------------------------------- */

 .faq-container {
  width: 100%;
  /* max-width: 600px;
  margin: 0 auto;
  padding: 20px; */
}

.faq-item {
  margin-bottom: 15px;
}

.faq-question {
  /* background-color: #f1f1f1; */
  background-color: antiquewhite;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  font-size: 15px;
  border-radius: 5px;
  padding-left: 10px;
  color: #767070;
  font-weight: 500;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%), 0 2px 10px 0 rgb(0 0 0 / 9%);
  
}

.faq-question-feature{
  font-size: 21px;
  margin-right: 10px;
  margin-bottom: 2px;
  color: #f0a500;
}

.faq-question:hover {
  background-color: #0055a5;
  color: white;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
 color: white;
}


.faq-all >div>h3{
  font-size: 20px;
  color: #0055a5;
  font-weight: 500;
  margin-bottom: 0px;
}

.faq-all-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;
}

.faq-answer {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease, padding 0.3s ease;
  padding: 0 20px;
  font-size: 16px;
}

.faq-answer.active {
  max-height: 200px; /* Adjust based on content length */
  padding: 10px 20px;
  color: #756d6d;
  background-color: aliceblue;
  border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 10pt;
}


.active-question{
  background-color: #0055a5!important;
  color: white;

  /* color: white; */
}


















/* -------------------------------new feature ------------------------------ */

.feature-question-item {
  margin-bottom: 20px;
}

.feature-question {
  cursor: pointer;
}

.feature-question:hover {
  opacity: 0.8;
}

.right-img-panel-div {
  height: 52px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.right-img-panel {
  width: 26px; /* Adjust as needed */
  height: 26px; /* Adjust as needed */
  margin-right: 10px;
}

.right-img-panel-p {
  margin-top: 10px;
  color: #706868;
  font-size: 10pt;
}

.arrow {
  margin-left: auto;
  margin: 10px;
  font-size: 30px;
  color: #ada9a9;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.feature-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.feature-answer.active {
  max-height: 200px; /* Adjust height as needed */
}


.cashless-lower-question{
  display: flex;
  flex-wrap: wrap;
}


