.owl-stage-outer img {
  width: 100%;
}

/* 
.new-two {
  margin-top: -165px;
} */

@media screen and (max-width: 755px) {
  .marginslider {
    margin-bottom: 35px;
  }
}

/* Second Section start hear */

section.home-second-section {
  margin-bottom: 135px;
}

/* Third Container Start hear */
@media only screen and (max-width: 1440px) {
  /* .align-self-center.new-two .top-dot-img::before {
      top: -22%;
    } */
}

@media only screen and (max-width: 1366px) {
  /* .align-self-center.new-two .top-dot-img::before {
      top: -21%;
    } */
}

@media only screen and (max-width: 1410px) {
  /* .align-self-center.new-two .top-dot-img::before {
      top: -28%;
    } */
}

@media only screen and (max-width: 1440px) {
  /* .align-self-center.new-two .top-dot-img::before {
      top: -25%;
    } */
}

@media only screen and (max-width: 1620px) {
  /* .align-self-center.new-two .top-dot-img::before {
      top: -25%;
    } */
}

/* .align-self-center.new-two .top-dot-img::before {
    top: -28%;
    left: 40%;
  } */

/* .top-dot-img:before {
    content: "";
    width: 50px;
    height: 50px;
    background: #fff;
    position: absolute;
    border-radius: 30px;
    border: 15px solid #fff;
    box-shadow: 3px 1px 1px 1px rgb(0 0 0 / 10%);
    top: -38%;
    left: 32%;
    right: 0;
    transform: translateX(-50%);
  } */
/* .top-dot-img {
    text-align: center;
  } */

/* Section four start hear */

section.home-fourth-section.home-section {
  background: #2959b80f;
}

/* Section Fifth start hear */


/* Section Eight strat hear */

@media only screen and (max-width: 1440px) {
  section.home-eight-section.home-section {
    margin-bottom: 70px;
  }
}

section.home-eight-section.home-section {
  margin-bottom: 193px;
}

/* Section nine start hear */

@media only screen and (max-width: 1440px) {
  section.home-nine-section.home-section {
    margin-bottom: 70px;
  }
}

/* Section Ten start hear */

@media only screen and (max-width: 1440px) {
  section.home-ten-section.home-section {
    margin-bottom: 70px;
  }
}

/* Section eleven start hear */

section.home-eleven-section.home-section {
  margin-bottom: 80px;
}

/* Section Twelve start hear */


/* .align-self-center.new-two {
  margin-top: -165px;
} */

/* .align-self-center.new-three {
  margin-top: -390px;
} */

/* .align-self-start.new-four {
  margin-top: -185px;
} */

/*  */

@media only screen and (max-width: 1440px) {
  section.home-twelve-section.home-section {
    margin-bottom: 50px;
  }
}
section.home-twelve-section.home-section {
  margin-bottom: 100px;
}

/* Third Section start */
section.home-third-section {
  margin-bottom: 125px;
}
/* 4-Steps numbers section*/
.second-section-of-third {
  margin-bottom: 50px;
  height: 100%;
}

.step-one h2 {
  color: #12BC95;
}
.step-one {
  bottom: 0;
}
.step-two h2 {
  color: #6172F1;
}
.step-two {
  bottom: 15%;
}
.step-three h2 {
  color: #3CBBDA;
}
.step-three {
  bottom: 30%;
}
.step-four h2 {
  color: #FCA84C;
}
.step-four {
  bottom: 45%;
}


.step-box-container .step-box-title {
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 11px;
}

.step-box-container .step-box-title p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 46px;
  font-weight: bold;
  color: #141414;
  margin-bottom: 0;
  line-height: 30px;
}

p.step-box-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #68768f;
  line-height: 26px;
}

.step-box-container .step-box-title h2 {
  font-family: "Proxima Nova Th";
  font-size: 100px;
  font-weight: bold;
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .step-box-container .step-box-title h2 {
    font-size: 60px;
  }
}

/* Step-cards flow*/
.second-section-of-third {
  flex-flow: column;
  background: none;
}
.second-section-of-third .col {
  min-width: 47%;
}
@media only screen and (min-width: 769px) {
  .second-section-of-third .col {
    min-height: fit-content;
    height: 240px;
  }
}
@media only screen and (min-width: 481px) {
  .second-section-of-third {
    flex-flow: wrap;
  }
}
@media only screen and (min-width: 1199px) {
  .first-section-of-third {
    position: relative;
    z-index: 999;
  }
  .second-section-of-third {
    flex-flow: row;
    height: 540px;
    align-items: flex-end;
    position: relative;
    top: -240px;
  }
  .second-section-of-third .col {
    min-width: 23%;
    position: relative;
  }
  section.home-third-section {
    margin-bottom: -240px;
  }
}
/* 4-Steps Section end */

/* All home-sections */
.home-section.callbackpop {
  display: flex;
  align-items: center;
  justify-content: center;
}


.callform label{

  font-family: 'Poppins'!important;
}

.callform input::placeholder{
  font-family: 'Poppins'!important;
}

.call-option{
  font-family: 'Poppins'!important;

}
.call-option input::placeholder{
  font-family: 'Poppins'!important;

}

.call-option ::placeholder{
  font-family: 'Poppins'!important;

}
.slick-arrow .slick-next{
 display: none;
}