.carformsubmited {
    width: 93%;
    /* background-color: red; */
}
.carformsubmited button{
    width: 100%;
    padding: 15px 0;
    border-radius: 10px;
    background-color: #0166b3;
    border: none;
    color: white;
}
.car_description h1{
    font-size: 18pt;
    color: #0166b3;
}
.list_tagcar{
    color: #0166b3;
    font-size: 14pt;
    font-family: 'Poppins';
    margin-bottom: 15px;
}

.comparativeCarInsurance-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .comparativeCarInsurance-table-bordered {
    border: 1px solid #ddd;
  }
  
  .comparativeCarInsurance-table th, .comparativeCarInsurance-table td {
    border: 1px solid #c3c1c1;
    padding: 8px;
    text-align: left;
    font-size: 13pt;
    color: rgb(122 119 119);
    font-family: 'Poppins';
}
.headterm h3 {
    color: #0166b3;
    font-size: 13pt;
    font-family: 'Poppins';
    font-weight: 600;
    padding: 10px 0px;
}

.carinsurence {
    width: 472px !important;
    z-index: -99999;
    left: -79px;
    position: relative !important;
}

  .comparativeCarInsurance-table th {
    background-color: rgb(41, 89, 184);
    color: white;
  }
  
  .comparativeCarInsurance-row {
    background-color: #f9f9f9; /* Optional for better readability */
  }
  
  .comparativeCarInsurance-row:hover {
    background-color: #f1f1f1; /* Optional hover effect */
  }
  

  .keyHighlightsOfCarInsurancePolicy-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .keyHighlightsOfCarInsurancePolicy-table-bordered {
    border: 1px solid #ddd;
  }
  
  .keyHighlightsOfCarInsurancePolicy-table th, .keyHighlightsOfCarInsurancePolicy-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-size: 13pt;
    font-family: 'Poppins';
    color: rgb(122 119 119);
}
  .keyHighlightsOfCarInsurancePolicy-table th {
    background-color: rgb(41, 89, 184);
    color: white;
  }
  

  .cardocreq {
    width: 100%;
    height: 278px;
    background-color: #ffc10752;
    border-radius: 15px;
    padding: 14px;
}
.cardocreq li {
    list-style: none;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 10px 0px;
}
.cardocreq2 {
    width: 100%;
    height: 278px;
    background-color: #f9676678;
    border-radius: 15px;
    padding: 14px;
}
.cardocreq2 li {
    list-style: none;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 10px 0px;
}
.separtaediv {
    font-family: 'Poppins';
    color: #0166b3;
    font-size: 13pt;
    margin: 19px 0px;
    display: flex;
    align-items: start;
}
.separtaediv span{
    color: #0166b3;
    margin: 15px 0px;
}
.numberingspancar {
    width: 5%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F96766;
    border-radius: 50%;
    margin: 0 10px;
    font-weight: 600;
    font-size: 19pt; /* Adjust this if numbers are too big */
    box-sizing: border-box; /* Ensure padding/border are included */
    overflow: hidden; /* Prevent overflow of content */
}
.separatetext{
    width: 95%;
}

.carstepper {
    width: 100%;
    height: 58px;
    border-radius: 29px;
    background-color: #e0e0e075;
    margin: 10px 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 14px;
    color: #2959B8;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 13pt;
}
.fmailimagegifscarstepper {
    width: 257px;
    margin: auto;
}
.numberstepper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FBA71B;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    margin-right: 10px;
    position: relative; /* Ensure the parent is positioned for the absolute positioning of ::after */
}

.numberstepper3 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FBA71B;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    margin-right: 10px;
    position: relative; /* Ensure the parent is positioned for the absolute positioning of ::after */
}
.numberstepper::after {
    content: '';
    position: absolute;
    left: 17px;
    top: 56px;
    transform: translateY(-50%);
    width: 5px;
    height: 36px;
    background-color: #FBA71B;
}
.documentdivcarinsurence {
    width: 100%;
    height: 231px;
    background-color: #ffc1077a;
    border-radius: 10px;
    padding: 15px;
    align-content: center;
}
.Readmore {
    height: 210px;
}


.carclamediscription {
    padding: 10px 15px;
    font-family: 'Poppins';
    font-size: 13pt;
    color: rgb(122, 119, 119);
}
.flexdivs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}



.documentdivcarinsurence2 {
    width: 100%;
    height: 231px;
    background-color: #007bff57;
    border-radius: 10px;
    padding: 15px;
    align-content: center;
}

.documentdivcarinsurence li{
    list-style: none;
    color: black;
    font-weight: 600;
    font-size: 13pt;
    font-family: 'Poppins';
    margin: 15px 5px;
}
.documentdivcarinsurence2 li{
    list-style: none;
    color: black;
    font-weight: 600;
    font-size: 13pt;
    font-family: 'Poppins';
    margin: 15px 5px;
}
.clamecarinsurence {
    width: 100%;
    height: 61px;
    border-radius: 30px;
    background-color: #e3e3e37d;
    margin: 10px 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 9px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13pt;
    cursor: pointer;
}

.clameNumber {
    border-radius: 50%;
    color: white;
    background-color: #01B6FC;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.company-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
}

.company-table-header {
    background: #f2f2f2;
}

.company-table-header-cell {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
    background-color: rgb(41, 89, 184);
    color: white;
}

.company-table-cell {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    color: rgb(122 119 119);
    font-family: 'Poppins';
    font-size: 13pt;
}
.iconsize{
    font-size: 14pt;
    color: #0166b3;
}


.car_insurance-section-heading {
    font-size: 1.5rem;
    color: #004085;
    margin-bottom: 15px;
    border-bottom: 2px solid #004085;
    padding-bottom: 5px;
  }
  
  .car_insurance-section-description {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
  }
  
  /* Table Styles */
  .car_insurance-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1rem;
    text-align: left;
  }
  
  .car_insurance-table-header {
    background-color: #0166B3;
    color: #ffffff;
    padding: 10px;
}
  
  .car_insurance-table-step, 
  .car_insurance-table-doc-name {
    width: 20%;
    font-weight: 500;
    padding: 10px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    font-size: 10pt;
}
  
  .car_insurance-table-description, .car_insurance-table-doc-purpose {
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    color: rgb(122 119 119) !important;
}
  
.car_steppername_stepper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .car_steppername_stepper-step {
    text-align: center;
    position: relative;
    flex: 1;
    cursor: pointer;
  }
  
  .car_steppername_step-number {
    background-color: #ccc;
    border-radius: 50%;
    color: white;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .car_steppername_step-title {
    font-size: 14px;
    color: #555;
  }
  
  .car_steppername_stepper-step.car_steppername_active .car_steppername_step-number {
    background-color: #007bff;
  }
  
  .car_steppername_stepper-step:not(.car_steppername_active) .car_steppername_step-title {
    color: #999;
  }
  
  .car_steppername_step-content {
    display: flex;
    flex-direction: column;
  }
  
  .car_steppername_step-content-item {
    display: none;
    padding: 15px;
    background: #f9f9f9;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .car_steppername_step-content-item.car_steppername_active {
    display: block;
  }
  
  .carinsurence_claimsettlement {
    padding: 30px;
    font-family: Arial, sans-serif;
  }
  
  .carinsurence_claimsettlement h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .carinsurence_claimsettlement_progress-bar {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .carinsurence_claimsettlement_progress {
    height: 100%;
    background-color: #007bff;
    border-radius: 5px;
    transition: width 0.3s ease;
  }
  
  .carinsurence_claimsettlement_stepper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .carinsurence_claimsettlement_stepper-steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .carinsurence_claimsettlement_stepper-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
    padding: 10px;
  }
  
  .carinsurence_claimsettlement_stepper-step:hover {
    transform: scale(1.1);
  }
  
  .carinsurence_claimsettlement_stepper-step.carinsurence_claimsettlement_active {
    font-weight: bold;
    color: #007bff;
  }
  
  .carinsurence_claimsettlement_step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .carinsurence_claimsettlement_step-title {
    font-size: 14px;
  }
  
  .carinsurence_claimsettlement_step-content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .carinsurence_claimsettlement_navigation {
    margin-top: 20px;
  }
  
  .carinsurence_claimsettlement_navigation button {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .carinsurence_claimsettlement_navigation button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .carinsurence_claimsettlement_navigation button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  

  
  /* Large Screens (laptops/desktops) */
  @media (min-width: 1200px) {
    .carinsurence_claimsettlement_progress-bar {
      height: 12px;
    }
  
    .carinsurence_claimsettlement_stepper-steps {
      gap: 20px;
    }
  
    .carinsurence_claimsettlement_stepper-step {
      min-width: 150px;
    }
  
    .carinsurence_claimsettlement_stepper-step.carinsurence_claimsettlement_active {
      font-size: 18px;
    }
  
    .carinsurence_claimsettlement_navigation button {
      padding: 12px 30px;
    }
  }

  @media (max-width: 1024px) {
    .carinsurence_claimsettlement_stepper-steps {
      gap: 10px;
    }
  
    .carinsurence_claimsettlement_stepper-step {
      min-width: 120px;
    }
  
    .carinsurence_claimsettlement_stepper-step.carinsurence_claimsettlement_active {
      font-size: 16px;
    }
  }
@media screen and (max-width: 788px) {
    .carformsubmited {
        width: 87%;
        margin: 0 auto;
    }
    .carinsurence {
        width: 100% !important;
        z-index: -99999;
        left: 0;
        position: relative !important;
    }
}

  /* Responsive Styles */
  @media (max-width: 768px) {
    .car_insurance-container {
      padding: 10px;
    }
  
    .car_insurance-section-heading {
      font-size: 1.2rem;
    }
  
    .car_insurance-table {
      font-size: 0.9rem;
    }
    .carinsurence_claimsettlement {
        padding: 15px;
      }
    
      .carinsurence_claimsettlement h2 {
        font-size: 20px;
      }
    
      .carinsurence_claimsettlement_progress-bar {
        height: 8px;
      }
    
      .carinsurence_claimsettlement_stepper-steps {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    
      .carinsurence_claimsettlement_stepper-step {
        flex: unset;
        min-width: 80%;
        margin-bottom: 15px;
      }
    
      .carinsurence_claimsettlement_step-content {
        width: 100%;
        margin-top: 15px;
      }
    
      .carinsurence_claimsettlement_navigation button {
        width: 100%;
        margin: 10px 0;
      }
  }
@media screen and (max-width: 600px){
    .comparativeCarInsurance-table th, .comparativeCarInsurance-table td {
        border: 1px solid #c3c1c1;
        padding: 8px;
        text-align: left;
        font-size: 11pt;
        color: rgb(122 119 119);
        font-family: 'Poppins';
    }
    .keyHighlightsOfCarInsurancePolicy-table th, .keyHighlightsOfCarInsurancePolicy-table td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
        font-size: 11pt;
        font-family: 'Poppins';
    }
    .separtaediv {
        font-family: 'Poppins';
        color: #0166b3;
        font-size: 11pt;
        margin: 19px 0px;
        display: flex;
        align-items: start;
    }
    .cardocreq2 {
        width: 100%;
        height: 309px;
        background-color: #f9676678;
        border-radius: 15px;
        padding: 14px;
    }
    .documentdivcarinsurence li {
        list-style: none;
        color: black;
        /* font-weight: 600; */
        font-size: 11pt;
        font-family: 'Poppins';
        margin: 15px 5px;
    }
    .documentdivcarinsurence {
        width: 100%;
        height: 169px;
        background-color: #ffc1077a;
        border-radius: 10px;
        padding: 15px;
        align-content: center;
    }
    .documentdivcarinsurence2 li {
        list-style: none;
        color: black;
        /* font-weight: 600; */
        font-size: 11pt;
        font-family: 'Poppins';
        margin: 15px 5px;
    }
    .documentdivcarinsurence2 {
        width: 100%;
        height: 172px;
        background-color: #007bff57;
        border-radius: 10px;
        padding: 15px;
        align-content: center;
    }
    .company-table-cell {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
        color: rgb(122 119 119);
        font-family: 'Poppins';
        font-size: 11pt;
    }
    .company-table-header-cell {
        border: 1px solid white;
        padding: 8px;
        text-align: left;
        background-color: rgb(41, 89, 184);
        color: white;
        font-size: 11pt;
    }
}