.howbeemawork{
    width: 100%;
    padding: 15px 0;
    margin: 70px 0;
    text-align: center;
}
.howbeemawork h2 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #ed3543;
    letter-spacing: 7.4px;
    background: #ed35431a 0% 0% no-repeat padding-box;
    border-radius: 0.45rem;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin: 11px 0px;
}
.textalinecenter {
    margin: auto;
    padding: 0px 19px;
}
.beemaworkprosess{
   width: 100%;
   margin: 0 auto;
   display: flex;
   justify-content: space-around;
   align-items: center;
}
.mainstatus{
    width: 30%;
    text-align: center;
}
.mainstatus img{
    width: 30%;
}
.mainstatus h3 {
    color: #474343;
    font-family: 'Poppins';
    font-size: 15pt;
}
.mainstatus .discription{
    color: rgb(122 119 119);
    font-size: 12pt;
    font-family: "Poppins";
}
.stepspan {
    color: #2959b8;
    font-size: 33pt;
}

@media screen and (max-width: 709px){
    .beemaworkprosess{
        width: 100%;
        margin: 0 auto;
        display: block;

     }
   .arrowimgs{
    display: none;
   }
   .mainstatus {
    width: 85%;
    text-align: center;
    margin: auto;
}
}