.topheaderpreffer {
    width: 100%;
    padding-bottom: 5px;

  }
  

.ratings{
   width: 100%;
   height: 90px;
   position: relative;
   top: 10px;
   border-radius: 15px 15px 0px 0px ;
   display: flex;
   justify-content: space-around;
   align-items: center;
   padding: 0 20px;
}
.comments {
    width: 100%;
    position: relative;
    top: 0px;
    padding: 45px 55px;
    margin-bottom: 37px;
}
.commentpolicy {
    width: 80%;
    display: grid;
    gap: 72px;
    grid-template-columns: repeat(2, 300px);
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}
.policynames{
    width: 33%;
}
.cardimage{
    width: 60px;
}
.starcontainer{
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}
.starcontainer p{
    font-size: 30pt;
}
.clames{
    width: 33%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.starcontainer .card-preffered{
    width: 60px;
}

.largestTxt {
    font-size: 15px;
    font-weight: 500;
    color: rgb(100, 99, 99);
    font-family: 'Poppins';
}
.headerstrong {
    color: #0166b3;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Poppins';
}


.headerparagraph {
    font-size: 12px;
    color: #393939;
    font-family: 'Poppins';
}
.left {
    margin-left: 50px;
}


.whyus {
    font-size: 22pt;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #0166b3, #0166b3, rgba(232, 20, 20, 0.518));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    width: 70%;
    display: inline-block;
    overflow: hidden;
}
.gridcards {
    width: 300px;
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
    padding: 10px 25px;
    border-radius: 0px 20px;
    border-left: 2px solid #0166b3;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
}
.gridcoin{
    width: 70px;
}
.gridcardcontainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px;
}

.whyweare {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gridcardcontainer img{
    width: 90px;
    aspect-ratio: 3/2;
    object-fit: contain;
}
.cardheads {
    color: #0166b3;
    font-size: 15px;
    font-family: 'Poppins';
}



.cardcontent {
    color: rgb(155 151 151);
    font-size: 13px;
    font-family: 'Poppins';
    margin-bottom: 0px;
}
.loading-overlaysee{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
    background-size: 200% 200%;
    animation: loading 2s infinite;
}
.flexcontainerpreff{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1024px){
    .flexcontainerpreff{
        display: block;
        justify-content: center;
        align-items: center;
    }
    .whyweare {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 45px 0px;
    }
    
    .whyus {
        font-size: 22pt;
        font-family: 'Poppins', sans-serif;
        background: linear-gradient(to right, #0166b3, #0166b3, rgba(232, 20, 20, 0.518));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        width: 100%;
        display: inline-block;
        overflow: hidden;
    }
}
@media screen and (max-width: 896px) {
    .topheaderpreffer {
        width: 100%;
        padding-bottom: 8px;
        overflow: hidden;
        position: relative;
        bottom: 0px;
      }
    .ratings {
        width: 100%;
   height: 290px;
        background-color: #F2F7FF;

        border-radius: 15px 15px 0px 0px;
        display: block;
        padding: 0 0px;
    }
    .policynames {
        width: 95%;
        text-align: center;
        padding: 15px 0px;
        margin: 0 auto;
      }
    .largestTxt{
        font-size: 15pt;
        color: #2959b8;
    }
    .starcontainer{
        width: 100%;
        padding: 22px 0px;
        
    }
    .clames {
        width: 95%;
        height: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 22px 0px;
        margin: 0 auto;
  
    }
    .headerstrong {
        color: #2959B8;
        font-weight: 600;
        font-size: 16pt;
    }
    .headerparagraph {
        font-size: 8pt;
        color: black;
    }
    .comments {
        width: 100%;
        display: block;
        margin: 0 auto;
        text-align: center;
        padding:  1px 5px;
    }
    .commentpolicy {
        width: 100%;
        display: block;
      
        margin: 0 auto;
    }
    .whyweare {
        width: 98%;
        margin: 24px auto;
      }
    .gridcards {
        width: 90%;
        height: 210px;
        background-color: rgb(255, 255, 255);
        border-radius: 15px;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.9);
        padding: 20px 25px;
        margin: 15px auto;
    }
 
    .starcontainer p {
        font-size: 22pt;
      }
}
@media screen and (max-width: 600px){
    .largestTxt {
        font-size: 9pt;
        color: #2959b8;
    }
    .whyus {
        font-size: 11pt;
        font-family: 'Poppins', sans-serif;
        background: linear-gradient(to right, #0166b3, #0166b3, rgba(232, 20, 20, 0.518));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        width: 100%;
        display: inline-block;
        overflow: hidden;
    }
    .ratings {
        width: 100%;
        height: 231px;
        background-color: #F2F7FF;
        border-radius: 15px 15px 0px 0px;
        display: block;
        padding: 0 0px;
    }
    .starcontainer {
        width: 100%;
        padding: 6px 0px;
    }
    .headerstrong {
        color: #2959B8;
        font-weight: 600;
        font-size: 14pt;
    }
    .headerparagraph {
        font-size: 7pt;
        color: black;
    }
    .ratings {
        width: 100%;
        height: 197px;
        background-color: #F2F7FF;
        border-radius: 15px 15px 0px 0px;
        display: block;
        padding: 0 0px;
    }

}