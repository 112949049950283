.getstarted-outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1200;
    background: #0003;
    display: block;
  }
  
  @keyframes appearAnimation {
      0% {
        opacity: 0;
        top: 40%;
      }
      100% {
        opacity: 1;
        top: 50%;
      }
  }
  @keyframes hideAnimation {
      0% {
        opacity: 1;
        top: 50%;
      }
      100% {
        opacity: 0;
        top: 40%;
      }
  }
  
  
  .getstarted-body {
    background: white !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px;
    border-radius: 10px;
    width: 500px;
  
    position: absolute;
    top: 40%;
    left: 50%;
    translate: -50% -50%;
  }
  
  .getstarted-body.show {
    animation: appearAnimation 0.3s ease-in-out forwards;
  }
  
  .getstarted-body.hide {
    animation: hideAnimation 0.3s ease-in-out forwards;
  }
  
  .getstarted-cross {
    width: 100%;
    display: flex;
    justify-content: right;
    position: relative;
    z-index: 99;
  }
  
  .getstarted-cross-icon {
    width: 30px;
    height: 30px;
    background-color: #fca84c;
    padding: 7px;
    border-radius: 50%;
    color: white;
    
  }
  
  .getstarted-text {
    font-family: "Poppins";
    text-align: center;
  }
  
  .getstarted-buttons-first {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .getstarted-buttons-first button {
    background-color: rgb(13, 72, 182);
    width: 200px;
    padding: 8px;
    border-radius: 8px;
    color: white;
  }
  
 