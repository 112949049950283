.bike-icon-img {
    max-width: 141px;
    width: 100%;
    max-height: 141px;
    min-height: 141px;
    height: 100%;
    text-align: center;
    padding: 45px;
    border: 8px solid #407bff;
    border-radius: 100px;
    margin: 0 auto;
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bike-icon-img:not(:last-child):before {
    content: "";
    border: 6px dotted #407bff;
    position: absolute;
    width: 100%;
    z-index: -1;
    left: auto;
    right: -55px;
    top: 50%;
    border-radius: 20px 24px 20px 20px;
    transform: translateY(-50%);
}


@media screen and (max-width: 800px){
    .bike-icon-img:not(:last-child):before {
        content: "";
        border: none;
        position: absolute;
        width: 100%;
        z-index: -1;
        left: auto;
        right: -55px;
        top: 50%;
        border-radius: 20px 24px 20px 20px;
        transform: translateY(-50%);
        overflow: hidden;
    }
}


.bikes-icon-img{
    max-width: 141px;
    width: 100%;
    max-height: 141px;
    min-height: 141px;
    height: 100%;
    text-align: center;
    padding: 45px;
    border: 8px solid #407bff;
    border-radius: 100px;
    margin: 0 auto;
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bike-icon-img img {
    width: 136px;
    object-fit: contain;
    aspect-ratio: 3 / 2;
}
.bikes-icon-img img{
    width: 136px;
    object-fit: contain;
    aspect-ratio: 3 / 2;
}

.bike-text {
    text-align: center;
    position: relative;
}
.bikestep-text {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    top: 10px;
}
.bikestep-text h1{
    color: #407bff;
    text-align: center;
    font-family: 'Poppins';
    margin-bottom: 0;

}
.bikestep-text h6{
    color: gray;
    font-family: 'Poppins'; 
}