.edit-line{
    height: 5px;
    
}

.checkpolicy{
    
}

.details-plus-btn, .details-minus-btn{
    /* display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 85, 165);
    width: 25px;
    height: 25px;
    color: white;
    border-radius: 5px;
    font-size: x-large;
} */
    padding: 0px;
    height: 30px;
    width: 30px;
    font-size: 20px;
    line-height: 0;
    padding: 6px 6px;
    border-radius: 6px;
}
.details-plus-btn{
    
    background-color: #489eee;
    /* background-color: #0055a5; */
    border: solid 2px #9dcffe;
    color: white;
}
.details-minus-btn{
    
    background-color: white;
    border: solid 2px #cde3ec;
}
.details-plus-btn:disabled {
    background-color: #ccc;
    border: solid 2px #ddd;
}

.editped-div{
    padding: 12px;
    text-align: center;
    display: flex;
    justify-content: left!important;
}

.editped-div label{
    margin-left: 10px;
}

.editped-div input {
    border: none;
}