.fullpage{
    width: 100%;
}
.imagedives{
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #2959b8fc;
    /* color: white; */
    text-align: center;
    margin-bottom: 40px;

}
.imagedives img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
}
.blog-img-div{
    width: 67%;
    height: 30%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.blog-img{
    max-width: 100%;

    margin: auto;
    max-height:auto ;
}

@media screen and (max-width: 1024px){
    .textblogs {
        width: 96%;
        margin: 0 auto;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
    .blog-img-div {
        width: 98%;
        /* height: 30%; */
        margin: auto;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px;
    }
    .titlediv {
        width: 97%;
        margin: 0 auto;
        height: 96px;
        background-color: rgb(255 231 205);
        border-radius: 15px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.9);
        /* position: relative; */
        bottom: 45px;
        padding: 26px 24px;
        text-align: center;
    }
}

.titlediv{
    width: 68%;
    margin: 0 auto;
    height: 96px;
    background-color: rgb(255 231 205);
    border-radius: 15px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.9);
    /* position: relative; */
    bottom: 45px;
    padding: 26px 24px;
    text-align: center;
}
.textblogs{
    width: 67%;
    margin: 0 auto;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.catagoryspan{
    background-color: #2959b800;
    padding: 10px 15px;
    color: #2959b8;
    border-radius: 15px;
    border: 2px solid #2958b7;
    font-family: 'Proxima Nova Lt';

}
.titleblogpost {
    position: relative;
    top: 30%;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    padding: 10px 0px;
    font-family: 'Poppins';
    font-size: 1.5rem;
}
.catagorytitlee{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

@media screen and (max-width:625px) {
    .titleblogpost {
        position: relative;
        top: 30%;
        color: white;
        font-weight: 600;
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        padding: 10px 0px;
        font-family: 'Poppins';
        font-size: 1.5rem;
    }
    .imagedives {
        position: relative;
        width: 100%;
        overflow: hidden;
        background-color: #2959b8fc;
        /* color: white; */
        text-align: center;
        margin-bottom: 10px;
    }
    .titlediv {
        width: 92%;
        margin: 0 auto;
        height: 82px;
        background-color: rgb(44 91 185 / 10%);
        border-radius: 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .catagoryspan {
        background-color: #2959b8;
        padding: 10px 15px;
        color: #ffffff;
        border-radius: 15px;
        border: 2px solid #2958b7;
        font-family: 'Poppins';
    }
    .blog-img-div {
        width: 90%;
        height: 30%;
        margin: auto;
        text-align: center;
        margin-top: 26px;
        margin-bottom: 18px;
    }
    .textblogs {
        width: 88%;
        margin: 0 auto;
        font-family: 'Poppins';
    }
}